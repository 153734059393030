import { FormGroup, ValidationErrors } from '@angular/forms';
import { IcrValidators, isEmptyOrWhitespace } from 'src/app/shared/validators/icr.validators';

function validateCusip(form: FormGroup) {
    const cusip = form.get('cusip');

    if (isEmptyOrWhitespace(cusip.value)) {
        cusip.setErrors(IcrValidators.requiredNoWhitespace(cusip));
    }else{
        cusip.setErrors(null);
    }
}

function validateQuantity(form) {
    const quantity = form.get('quantity');
    if (isEmptyOrWhitespace(quantity.value)) {
        quantity.setErrors(IcrValidators.requiredNoWhitespace(quantity));
    }
}

export function dynamicHoldingValidator(form: FormGroup): ValidationErrors | null {

    const editableControls = Object.keys(form.controls).map(x => form.get(x));

    const allFieldsEmpty = editableControls.every(control => {
        return isEmptyOrWhitespace(control.value)
    });

    if (allFieldsEmpty) {
        editableControls.forEach(control => control.setErrors(null));
        form.markAsPristine();
    } else {
        validateCusip(form);

        // 'required' validation is performed here due to bug
        // other validators do work when added to individual field
        validateQuantity(form);
    }

    return null;
}
