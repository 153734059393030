import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageBoxService } from './../shared/messages/message-box.service';

@Injectable({
    providedIn: 'root',
})
export class HttpErrorResponseHandler {
    constructor(private messageBoxService: MessageBoxService) {
    }

    handleError(
        response: HttpErrorResponse,
        options: {
            notFoundErrorMessageId: string;
            genericErrorMessageId: string;
        },
    ): void {
        return this.messageBoxService.error(
            response.status === 404
                ? options.notFoundErrorMessageId
                : options.genericErrorMessageId,
        );
    }
}
