import { NgModule } from '@angular/core';
import { UserProfileComponent } from './user-profile.component';
import { AccountFormComponent } from './account-form/account-form.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { PasswordChangeFormComponent } from './password-change/password-change-form/password-change-form.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { UserProfileRoutingModule } from './user-profile-routing.module';

@NgModule({
    declarations: [
        UserProfileComponent,
        NavigationComponent,
        AccountFormComponent,
        PasswordChangeComponent,
        PasswordChangeFormComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        UserProfileRoutingModule,
    ],
})
export class UserProfileModule {}
