import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable, of } from 'rxjs';
import { Model } from 'src/app/models/model.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelService } from '../../../model.service';
import { ModelCategory } from '../../../model-category.enum';
import { ModelStateManagementService } from '../../model-state-management.service';
import { ModelAuthorizationService } from 'src/app/model-pages/model-authorization.service';
import { HttpErrorResponseHandler } from 'src/app/core/http-error-response.handler';
import { ModelType } from 'src/app/core/model-type';
import { ModelSleeveViewModel } from 'src/app/models/model-sleeve.model';
import { catchError, switchMap } from 'rxjs/operators';
import { FileSaverService } from 'ngx-filesaver';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { UserService } from 'src/app/admin/user.service'
import { FirmType } from 'src/app/core/firm-type';
import { AuthorizationService } from 'src/app/core/auth/authorization.service';
import { Roles } from 'src/app/core/roles';

@Component({
    selector: 'app-model-detail-holdings',
    templateUrl: './model-detail-holdings.component.html',
    styleUrls: ['./model-detail-holdings.component.scss'],
})
export class ModelDetailHoldingsComponent implements OnInit, OnDestroy {
    model: Model;
    ModelCategory = ModelCategory;
    ModelType = ModelType;
    modelSleeveModels$: Observable<ModelSleeveViewModel[]>;
    id: string;
    isICRAdmin: boolean;

    userFirmType: FirmType;
    private subscription = new Subscription();

    constructor(
        private modelService: ModelService,
        private authorizationService: AuthorizationService,
        private userService: UserService,
        private messageBoxService: MessageBoxService,
        private fileSaverService: FileSaverService,
        private router: Router,
        private route: ActivatedRoute,
        private modelAuthorizationService: ModelAuthorizationService,
        private activatedRoute: ActivatedRoute,
        private stateManagementService: ModelStateManagementService,
        private httpErrorResponseHandler: HttpErrorResponseHandler,
    ) { }

    ngOnInit() {
        this.stateManagementService.turnOnViewMode();
        this.loadHoldings();
        this.isICRAdmin = false;

        if(this.authorizationService.roles.find(r => r === Roles.ICRAdmin)){
            this.isICRAdmin = true;
        }

        this.subscription.add(
            this.userService.getCurrentUserContext().subscribe(
                (context) => {
                    this.userFirmType = context.firmType;
                },
            ),
        );
    }

    reloadHoldings() {
        window.location.reload();
    }

    loadHoldings() {
        this.subscription.add(
            this.activatedRoute.parent.params.pipe(
                switchMap((params) => {
                    this.id = params['id'];
                    return this.modelService.getModel(this.id);
                }))
                .subscribe(
                    (model) => {
                        this.model = model;

                        if (model.type === ModelType.ModelOfModels) {
                            this.modelSleeveModels$ = this.modelService.getSleeveModelsForModel(model.id).pipe(
                                catchError((error: HttpErrorResponse) => {
                                    this.showModelNotFoundError(error);
                                    return of(null);
                                }));
                        }
                    },
                    (error: HttpErrorResponse) => {
                        this.showModelNotFoundError(error);
                    },
                ),
        );
    }

    isModelHoldingsDownloadable(): boolean {

        if(this.isICRAdmin) {
            return true;
        }

        return this.userFirmType === FirmType.AssetManager &&
            this.modelAuthorizationService.hasAssetManagerRole();
    }

    exportHoldings() {
        this.subscription.add(
            this.modelService.exportModelHoldings(this.model.id).subscribe(
                file => {
                    this.fileSaverService.save(file, 'ModelHoldings-'+ this.model.id + '.txt');
                },
                () => this.messageBoxService.error('model-holdings-file-download-failed'),
            ),
        );
    }

    isModelEditable(): boolean {
        if (this.model.type === ModelType.ModelOfModels) {
            return this.model.isCreatedByCurrentUserFirm;
        }
        return this.modelAuthorizationService.isModelEditable(this.model);
    }

    get editButtonLabel() {
        return this.model.type === ModelType.Holdings
            ? 'Edit Holdings'
            : 'Edit Sleeves';
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private showModelNotFoundError(error: HttpErrorResponse): void {
        this.httpErrorResponseHandler.handleError(error, {
            notFoundErrorMessageId: 'model-not-found',
            genericErrorMessageId: 'model-details-fetch-failed',
        });
    }
}
