import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModelDetailsViewModel, ModelStatistics } from 'src/app/models/model.model';
import { ActivatedRoute } from '@angular/router';
import { ModelService } from '../../../model.service';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { ModelCategory } from '../../../model-category.enum';
import { AppPermissions } from 'src/app/core/permissions';
import { MODEL_CATEGORIES } from 'src/app/core/constants';
import { MODEL_TYPES, ModelType } from 'src/app/core/model-type';
import { MODEL_CLASSES_ALL } from 'src/app/core/model-style-class';
import { ModelStateManagementService } from '../../model-state-management.service';
import { ModelAuthorizationService } from 'src/app/model-pages/model-authorization.service';
import { getLabel } from 'src/app/helpers/label-helper';
import { MODEL_REGIONS } from 'src/app/core/model-region';
import { HttpErrorResponseHandler } from 'src/app/core/http-error-response.handler';
import { ModelResearchService } from './model-research.service';
import { ModelResearchItem } from 'src/app/models/model-research-item.model';
import { ResearchCategory } from 'src/app/core/research-category';

@Component({
    selector: 'app-details-tab-view',
    templateUrl: './details-tab-view.component.html',
    styleUrls: ['./details-tab-view.component.scss', '../../model-detail.component.scss'],
})
export class DetailsTabViewComponent implements OnInit, OnDestroy {
    modelId: string;
    model: ModelDetailsViewModel;
    modelStatistics: ModelStatistics;
    ModelCategory = ModelCategory;
    AppPermissions = AppPermissions;
    factSheetResearch: ModelResearchItem;
    ModelType = ModelType;

    private _researches: ModelResearchItem[];

    get researches(): ModelResearchItem[] {
        return this._researches;
    }

    set researches(researches: ModelResearchItem[]) {
        this._researches = researches;
        this.factSheetResearch = this.researches.find(research => research.category === ResearchCategory.FactSheet);
    }

    private subscription = new Subscription();

    constructor(
        private modelService: ModelService,
        private modelAuthorizationService: ModelAuthorizationService,
        private activatedRoute: ActivatedRoute,
        private messageBoxService: MessageBoxService,
        private stateManagementService: ModelStateManagementService,
        private httpErrorResponseHandler: HttpErrorResponseHandler,
        private researchService: ModelResearchService,
    ) {
        this.stateManagementService.turnOnViewMode();
    }

    ngOnInit() {
        this.modelId = this.activatedRoute.parent.snapshot.params['id'];

        this.subscription.add(
            this.modelService.getModel(this.modelId).subscribe(
                model => {
                    this.model = model as ModelDetailsViewModel;
                    this.setModelLabels(this.model);
                },
                (error: HttpErrorResponse) =>
                    this.httpErrorResponseHandler.handleError(error, {
                        notFoundErrorMessageId: 'model-not-found',
                        genericErrorMessageId: 'model-details-fetch-failed',
                    }),
            ),
        );
        this.subscription.add(
            this.modelService.getModelStatistics(this.modelId).subscribe(
                statistics => {
                    this.modelStatistics = statistics;
                },
                () => this.messageBoxService.error('model-statistics-fetch-failed'),
            ),
        );

        this.getAllResearches();
    }

    isModelEditable(): boolean {
        return this.modelAuthorizationService.isModelEditable(this.model);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private setModelLabels(model: ModelDetailsViewModel) {
        model.benchmarkName = getLabel(model, 'benchmarkName', []);
        model.categoryLabel = getLabel(model, 'category', MODEL_CATEGORIES);
        model.typeLabel = getLabel(model, 'type', MODEL_TYPES);
        model.styleClassLabel = getLabel(model, 'styleClass', MODEL_CLASSES_ALL)
        model.managerFeeLabel = model.managerFee? `${model.managerFee} BPS` : '-';
        model.regionLabel = getLabel(model, 'region', MODEL_REGIONS);
        model.performanceBenchmark = model.performanceBenchmark? model.performanceBenchmark : '-';
    }

    getAllResearches() {
        this.subscription.add(
            this.researchService
                .getResearches(this.modelId)
                .subscribe(
                    researches => {
                        this.researches = researches;
                    },
                    () => this.messageBoxService.error('model-researches-fetch-failed'),
                ),
        );
    }

    updateResearchOnDelete(researchId: string) {
        this.researches = this.researches.filter(x => x.id !== researchId);
    }
}
