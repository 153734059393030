import { Component, OnInit, Input } from '@angular/core';
import { FixedIncomeDynamicHolding } from 'src/app/models/fixed-income-dynamic-holding.model';
import { ModelHoldingClass } from '../../model-holding-class.enum';
import { getClassLabel } from '../../model-holding-shared';

@Component({
    selector: 'app-fixed-income-table-view',
    templateUrl: './fixed-income-table-view.component.html',
    styleUrls: ['./fixed-income-table-view.component.scss', '../../model-holdings/column-sizes.view.scss'],
})
export class FixedIncomeTableViewComponent implements OnInit {
    @Input() holdings: FixedIncomeDynamicHolding[];
    @Input() fixedIncomeType: string;

    constructor() {}

    ngOnInit() {}

    getClassLabel(value: ModelHoldingClass) {
        return getClassLabel(value);
    }
}
