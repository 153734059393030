import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ModelStatus, MODEL_STATUSES } from 'src/app/core/model-status';
@Component({
    selector: 'app-status-indicator',
    templateUrl: './status-indicator.component.html',
    styleUrls: ['./status-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIndicatorComponent implements OnInit {
    constructor() {}

    ngOnInit() {}

    @Input() status: ModelStatus;

    getStatusClass(value: ModelStatus) {
        return '-' + value.toString().toLowerCase();
    }

    getStatusLabel(value: ModelStatus) {
        const modelStatus = MODEL_STATUSES.find(x => x.value === value.trim());
        if (modelStatus) {
            return modelStatus.label;
        }
        return value;
    }
}
