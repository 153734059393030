import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrl } from '../../core/constants';
import { Passwords } from './passwords';
import { ChangePasswordResponse } from './update-response';

@Injectable({
    providedIn: 'root',
})
export class ChangePasswordService {
    constructor(private http: HttpClient) {}

    public changePassword(passwords: Passwords): Observable<ChangePasswordResponse> {
        return this.http.put<ChangePasswordResponse>(
            `${apiUrl}/api/changePassword`,
            passwords,
        );
    }
}
