import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModelListFilterService } from '../model-list.filter.service';
import { ModelFilters } from '../model-list.filter.model';
import { filterLabel } from '../model-list.labels';
import { ModelCategory } from '../../model-category.enum';
import { ModelRegion } from 'src/app/core/model-region';
import { ModelStyleClass } from 'src/app/core/model-style-class';

class AssetClassFilter {
    label: string;
    assetClass: ModelCategory;
    region: ModelRegion;
    styleClass: ModelStyleClass;
}

@Component({
    selector: 'app-model-applied-filters',
    templateUrl: './model-applied-filters.component.html',
    styleUrls: ['./model-applied-filters.component.scss'],
})
export class ModelAppliedFiltersComponent implements OnInit, OnDestroy {
    filters = new ModelFilters();
    private subscription = new Subscription();

    constructor(private modelListFilterService: ModelListFilterService) {}

    ngOnInit() {
        this.subscription.add(
            this.modelListFilterService.filtersObservable.subscribe(filters => {
                this.filters = filters;
            }),
        );
    }

    get nameFilterLabel() {
        return `Firm Name contains '${this.filters.firmName}'`;
    }

    get assetClassFilters(): AssetClassFilter[] {
        const filters = [];

        if (this.filters.assetClassValue === undefined) {
            return filters;
        }

        if (this.filters.regionValue === undefined) {
            filters.push({
                label: this.getLabelAssetClass(this.filters.assetClassValue),
                assetClass: this.filters.assetClassValue,
            });
            return filters;
        }

        if (this.filters.styleClassValues === undefined || this.filters.styleClassValues.length === 0) {
            filters.push({
                label: this.getLabelRegion(this.filters.assetClassValue, this.filters.regionValue),
                assetClass: this.filters.assetClassValue,
                region: this.filters.regionValue,
            });
            return filters;
        }

        return this.filters.styleClassValues.map(sc => {
            return {
                label: this.getLabelStyleClass(this.filters.assetClassValue, this.filters.regionValue, sc),
                assetClass: this.filters.assetClassValue,
                region: this.filters.regionValue,
                styleClass: sc,
            };
        });
    }

    get alphaFilterLabel() {
        return `Alpha greater than ${this.filters.alphaValue.toFixed(2)}`;
    }

    get managerFeesFilterLabel() {
        return `Manager Fees less than ${this.filters.managerFeesValue} bps`;
    }

    get sharpeRatioFilterLabel() {
        return `Sharpe Ratio greater than ${this.filters.sharpeRatioValue.toFixed(4)}`;
    }

    get betaFilterLabel() {
        return this.getRangeFilterLabel('Beta', this.filters.betaFromValue, this.filters.betaToValue);
    }

    get rSquaredFilterLabel() {
        return this.getRangeFilterLabel('R Squared', this.filters.rSquaredFromValue, this.filters.rSquaredToValue);
    }

    clearNameFilter() {
        this.modelListFilterService.clearNameFilter();
    }

    clearAssetClassFilter(filter: AssetClassFilter): void {
        if (!filter.styleClass && !filter.region) {
            this.modelListFilterService.clearAssetClassFilter();
        } else if (!filter.styleClass) {
            this.modelListFilterService.clearRegionFilter();
        } else {
            this.modelListFilterService.clearStyleClassFilter(filter.styleClass);
        }
    }

    clearManagerFeesFilter() {
        this.modelListFilterService.filterByManagerFees(undefined);
    }

    clearAlphaFilter() {
        this.modelListFilterService.filterByAlpha(undefined);
    }

    clearSharpeRatioFilter() {
        this.modelListFilterService.filterBySharpeRatio(undefined);
    }

    clearBetaFilter() {
        this.modelListFilterService.clearBetaFilter();
    }

    clearRSquaredFilter() {
        this.modelListFilterService.clearRSquaredFilter();
    }

    private getRangeFilterLabel(field: string, from: number, to: number) {
        if (from !== undefined && to !== undefined) {
            return `${field} ${from} to ${to}`;
        } else if (to !== undefined) {
            return `${field} less than ${to}`;
        }

        return `${field} greater than ${from}`;
    }

    private getLabelAssetClass(assetClass: ModelCategory): string {
        return filterLabel.category(assetClass);
    }

    private getLabelRegion(assetClass: ModelCategory, region: ModelRegion): string {
        const label = this.getLabelAssetClass(assetClass);
        const labelRegion = filterLabel.region(region);
        return `${label} - ${labelRegion}`;
    }

    private getLabelStyleClass(assetClass: ModelCategory, region: ModelRegion, styleClass: ModelStyleClass): string {
        const label = this.getLabelRegion(assetClass, region);
        const labelStyleClass = filterLabel.styleClass(styleClass);

        return `${label} - ${labelStyleClass}`;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
