import { ModelRegion } from './model-region';
import { ModelCategory } from '../model-pages/model-category.enum';

export enum ModelStyleClass {
    Municipal = 'Municipal',
    Taxable = 'Taxable',
    LargeCapValue = 'LargeCapValue',
    LargeCapBlended = 'LargeCapBlended',
    LargeCapGrowth = 'LargeCapGrowth',
    MediumCapValue = 'MediumCapValue',
    MediumCapBlended = 'MediumCapBlended',
    MediumCapGrowth = 'MediumCapGrowth',
    SmallCapValue = 'SmallCapValue',
    SmallCapBlended = 'SmallCapBlended',
    SmallCapGrowth = 'SmallCapGrowth',
    Value = 'Value',
    Blended = 'Blended',
    Growth = 'Growth'
}

const EQUITY_DOMESTIC_CLASSES = [
    { label: 'Large Cap Value', value: ModelStyleClass.LargeCapValue },
    { label: 'Large Cap Blend', value: ModelStyleClass.LargeCapBlended },
    { label: 'Large Cap Growth', value: ModelStyleClass.LargeCapGrowth },
    { label: 'Medium Cap Value', value: ModelStyleClass.MediumCapValue },
    { label: 'Medium Cap Blend', value: ModelStyleClass.MediumCapBlended },
    { label: 'Medium Cap Growth', value: ModelStyleClass.MediumCapGrowth },
    { label: 'Small Cap Value', value: ModelStyleClass.SmallCapValue },
    { label: 'Small Cap Blend', value: ModelStyleClass.SmallCapBlended },
    { label: 'Small Cap Growth', value: ModelStyleClass.SmallCapGrowth },
];

const EQUITY_GLOBAL_INTERNATIONAL_CLASSES = [
    { label: 'Value', value: ModelStyleClass.Value },
    { label: 'Blend', value: ModelStyleClass.Blended },
    { label: 'Growth', value: ModelStyleClass.Growth },
];

const FIXED_INC_DOMESTIC_CLASSES = [
    { label: 'Municipal', value: ModelStyleClass.Municipal },
    { label: 'Taxable', value: ModelStyleClass.Taxable },
];

const FIXED_INC_GLOBAL_INTERNATIONAL_CLASSES = [
    { label: 'Taxable', value: ModelStyleClass.Taxable },
];

export const FIXED_INCOME_STYLE_CLASSES = {
    [ModelRegion.Domestic]: FIXED_INC_DOMESTIC_CLASSES,
    [ModelRegion.Global]: FIXED_INC_GLOBAL_INTERNATIONAL_CLASSES,
    [ModelRegion.International]: FIXED_INC_GLOBAL_INTERNATIONAL_CLASSES,
}

export const EQUITY_STYLE_CLASSES = {
    [ModelRegion.Domestic]: EQUITY_DOMESTIC_CLASSES,
    [ModelRegion.Global]: EQUITY_GLOBAL_INTERNATIONAL_CLASSES,
    [ModelRegion.International]: EQUITY_GLOBAL_INTERNATIONAL_CLASSES,
}

export const MODEL_CLASSES = {
    [ModelCategory.Equity]: EQUITY_STYLE_CLASSES,
    [ModelCategory.FixedIncome]: FIXED_INCOME_STYLE_CLASSES,
}

export const MODEL_CLASSES_ALL = [
    ...EQUITY_DOMESTIC_CLASSES,
    ...EQUITY_GLOBAL_INTERNATIONAL_CLASSES,
    ...FIXED_INC_DOMESTIC_CLASSES,
];
