import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserFormNotificationService {

    private formStatusSource = new BehaviorSubject(false);
    formStatusSubmitted = this.formStatusSource.asObservable();

    constructor() { }

    notifyOnFormSubmission() {
        this.formStatusSource.next(true);
    }
}
