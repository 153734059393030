import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingScreenService } from './loading-screen.service';
import { debounceTime } from 'rxjs/operators';
import { INTERCEPTED_REQUEST_LOADER_DEBOUNCE_TIME_IN_MS } from 'src/app/core/constants';

@Component({
    selector: 'app-loader',
    styleUrls: ['./loader.component.scss'],
    templateUrl: './loader.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit, OnDestroy  {
    loadingText = '';

    private loadingManually = false;
    private loadingFromInterceptor = false;
    private subscription = new Subscription();

    constructor(private loadingScreenService: LoadingScreenService,  private changeDetectorRef: ChangeDetectorRef) {  }

    ngOnInit() {
        this.subscribeToLoadingScreenService();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    get loading() {
        return this.loadingManually || this.loadingFromInterceptor;
    }

    private subscribeToLoadingScreenService(): void {
        this.subscription.add(this.loadingScreenService.manualLoadingStatusObservable.subscribe((value: boolean) => {
            this.loadingManually = value;
            this.changeDetectorRef.markForCheck();
        }));

        this.subscription.add(this.loadingScreenService.loadingStatusFromInterceptorObservable.pipe(
            debounceTime(INTERCEPTED_REQUEST_LOADER_DEBOUNCE_TIME_IN_MS),
        ).subscribe((value: boolean) => {
            this.loadingFromInterceptor = value;
            this.changeDetectorRef.markForCheck();
        }));

        this.subscription.add(this.loadingScreenService.loadingMessageObservable.subscribe((value: string) => {
            this.loadingText = value;
            this.changeDetectorRef.markForCheck();
        }));
    }

}
