import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { COUNTRIES, US_STATES, CONTACT_LABELS } from '../../core/constants';

@Component({
    selector: 'contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @Input() index: number = null;
    @Output() deleteContactClicked = new EventEmitter();

    contactLabels = CONTACT_LABELS;
    countries = COUNTRIES;
    states = US_STATES.map(state => ({
        value: state.abbreviation,
        label: state.name,
    }));

    constructor() {}

    ngOnInit() {}

    delete() {
        this.deleteContactClicked.emit();
    }

    get contactLabel() {
        return this.formGroup.get('contactLabel');
    }

    get firstName() {
        return this.formGroup.get('firstName');
    }

    get lastName() {
        return this.formGroup.get('lastName');
    }

    get id() {
        return this.formGroup.get('id');
    }
}
