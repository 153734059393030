import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-model-description-placeholder',
    templateUrl: './model-description-placeholder.component.html',
    styleUrls: ['./model-description-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelDescriptionPlaceholderComponent implements OnInit {
    @Input() title: string;
    @Input() content: string;
    @Input() id: string = null;

    constructor() {}

    ngOnInit() {}
}
