import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { CreateModelComponent } from './create-model/create-model.component';
import { UploadModelComponent } from './upload-model/upload-model.component';
import { ModelSubmittedComponent } from './model-submitted/model-submitted.component';
import { ModelHoldingsComponent } from './model-holdings/model-holdings.component';
import { ReviewModelComponent } from './review-model/review-model.component';
import { WarnOnLeaveGuard } from '../shared/guards/warn-on-leave.guard';
import { ModelListComponent } from './model-list/model-list.component';
import { AppPermissions } from '../core/permissions';
import { ModelDetailComponent } from './model-detail/model-detail.component';
import { DetailsTabViewComponent } from './model-detail/details-tab/view/details-tab-view.component';
import { ModelDetailHoldingsComponent } from './model-detail/holdings-tab/view/model-detail-holdings.component';
import { HistoryTabViewComponent } from './model-detail/history-tab/view/history-tab-view.component';
import { ModelPagesComponent } from './model-pages.component';
import { DetailsTabEditComponent } from './model-detail/details-tab/edit/details-tab-edit.component';
import { ModelDetailHoldingsEditComponent } from
    './model-detail/holdings-tab/edit/model-detail-holdings-edit.component';

const modelRoutes: Routes = [
    {
        path: 'models',
        component: ModelPagesComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                component: ModelListComponent,
                data: {
                    permission: AppPermissions.ViewModels,
                },
            },
            {
                path: 'create',
                component: CreateModelComponent,
                canDeactivate: [WarnOnLeaveGuard],
                data: {
                    permission: AppPermissions.CreateModel,
                },
            },
            {
                path: 'create/:id',
                component: CreateModelComponent,
                canDeactivate: [WarnOnLeaveGuard],
                data: {
                    permission: AppPermissions.CreateModel,
                },
            },
            {
                path: 'create/:id/upload',
                component: UploadModelComponent,
                data: {
                    permission: AppPermissions.CreateModel,
                },
            },
            {
                path: 'create/:id/holdings',
                component: ModelHoldingsComponent,
                canDeactivate: [WarnOnLeaveGuard],
                data: {
                    permission: AppPermissions.CreateModel,
                },
            },
            {
                path: 'create/:id/review',
                component: ReviewModelComponent,
                data: {
                    permission: AppPermissions.CreateModel,
                },
            },
            {
                path: 'submitted',
                component: ModelSubmittedComponent,
                data: {
                    permission: AppPermissions.CreateModel,
                },
            },
            {
                path: ':id',
                component: ModelDetailComponent,
                canActivateChild: [AuthGuard],
                data: {
                    permission: AppPermissions.ViewModels,
                },
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'details',
                    },
                    {
                        path: 'details',
                        component: DetailsTabViewComponent,
                        data: {
                            permission: AppPermissions.ViewModels,
                        },
                    },
                    {
                        path: 'details/edit',
                        component: DetailsTabEditComponent,
                        canDeactivate: [WarnOnLeaveGuard],
                        data: {
                            permission: AppPermissions.EditOwnFirmModel,
                        },
                    },
                    {
                        path: 'holdings',
                        component: ModelDetailHoldingsComponent,
                        data: {
                            permission: AppPermissions.ViewModels,
                        },
                    },
                    {
                        path: 'holdings/edit',
                        component: ModelDetailHoldingsEditComponent,
                        canDeactivate: [WarnOnLeaveGuard],
                        data: {
                            permission: AppPermissions.EditOwnFirmModel,
                        },
                    },
                    {
                        path: 'history',
                        component: HistoryTabViewComponent,
                        data: {
                            permission: AppPermissions.ViewModels,
                        },
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(modelRoutes)],
    exports: [RouterModule],
})
export class ModelRoutingModule { }
