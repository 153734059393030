import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ResearchUploadModalService }
    from 'src/app/shared/messages/research-upload-modal/research-upload-modal.service';
import { Subscription } from 'rxjs';
import { ModelResearchItem } from 'src/app/models/model-research-item.model';
import { ModelAuthorizationService } from '../../../../model-authorization.service';
import { RESEARCH_CATEGORIES, ResearchCategory } from 'src/app/core/research-category';
import { LoadingScreenService } from 'src/app/shared/loader/loading-screen.service';

@Component({
    selector: 'app-model-researches',
    templateUrl: './model-researches.component.html',
    styleUrls: ['./model-researches.component.scss'],
})
export class ModelResearchesComponent implements OnInit, OnDestroy {
    @Input() modelId: string;

    private _researches: ModelResearchItem[];

    @Input()
    set researches(researches: ModelResearchItem[]) {
        this._researches = researches;
        this.updateResearchCategories();
    }
    get researches(): ModelResearchItem[] {
        return this._researches;
    }

    @Output() refreshResearches = new EventEmitter();
    @Output() researchDeleted = new EventEmitter<string>();

    availableResearchCategories = RESEARCH_CATEGORIES;

    private subscription = new Subscription();

    constructor(
        private researchUpload: ResearchUploadModalService,
        private modelAuthorizationService: ModelAuthorizationService,
        private loadingScreenService: LoadingScreenService,
    ) { }

    ngOnInit() {
        this.subscribeToUploadResearchHandler();
    }

    showUploadResearchModal() {
        this.researchUpload.showModal(this.modelId, this.availableResearchCategories);
    }

    trackBy(_: number, item: ModelResearchItem) {
        return item.id;
    }

    canAddResearch(): boolean {
        return this.modelAuthorizationService.canAddResearch();
    }

    removeResearch(researchId: string) {
        this.researches = this.researches.filter(x => x.id !== researchId);
        this.researchDeleted.emit(researchId);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private subscribeToUploadResearchHandler() {
        this.subscription.add(
            this.researchUpload.uploadStatusObservable.subscribe(
                (result: boolean) => {
                    if (result === true) {
                        this.loadingScreenService.hideLoader();
                        this.refreshResearches.emit();
                    }
                },
            ),
        );
    }

    updateResearchCategories(): void {
        const factSheetExists = !this.researches ||
            this.researches.some(r => r.category === ResearchCategory.FactSheet);
        if (factSheetExists) {
            this.availableResearchCategories = RESEARCH_CATEGORIES.filter(
                c => c.value !== ResearchCategory.FactSheet,
            );
        } else {
            this.availableResearchCategories = RESEARCH_CATEGORIES;
        }
    }
}
