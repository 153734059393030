import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ModelListFilterService } from '../../model-list.filter.service';
import { FILTER_INPUT_DEBOUNCE } from '../model-filters.constants';
import { ModelSliderFilterBase } from '../model-slider-filter-base';

@Component({
    selector: 'app-model-filter-beta',
    templateUrl: './model-filter.beta.component.html',
    styleUrls: ['./model-filter.beta.component.scss'],
})
export class ModelFilterBetaComponent extends ModelSliderFilterBase implements OnInit, OnDestroy {
    DEFAULT_FROM_VALUE = -1;
    DEFAULT_TO_VALUE = 2;

    fromValue = this.DEFAULT_FROM_VALUE;
    toValue = this.DEFAULT_TO_VALUE;

    sliderOptions = {
        floor: this.DEFAULT_FROM_VALUE,
        ceil: this.DEFAULT_TO_VALUE,
        step: 0.1,
    };

    private subscription = new Subscription();

    constructor(private modelListFilterService: ModelListFilterService) {
        super();
    }

    ngOnInit() {
        this.subscription.add(
            this.modelListFilterService.filtersObservable.subscribe(filters => {
                this.setFromValue(filters.betaFrom, filters.betaFromValue);
                this.setToValue(filters.betaTo, filters.betaToValue);
            }),
        );

        this.subscription.add(
            this.valueFromChange
                .pipe(
                    debounceTime(FILTER_INPUT_DEBOUNCE),
                    distinctUntilChanged(),
                )
                .subscribe(value => {
                    this.modelListFilterService.filterByBetaFrom(value);
                }),
        );

        this.subscription.add(
            this.valueToChange
                .pipe(
                    debounceTime(FILTER_INPUT_DEBOUNCE),
                    distinctUntilChanged(),
                )
                .subscribe(value => {
                    this.modelListFilterService.filterByBetaTo(value);
                }),
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
