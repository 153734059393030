import { apiUrl } from './constants';

export function customUrlValidation(url: string) {
    return url.substr(0, apiUrl.length) === apiUrl;
}

export const round = (input: number, decimals: number): number => {
    const divisor = Math.pow(10, decimals);

    return Math.round(input * divisor) / divisor;
}
