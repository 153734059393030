import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AppPermissions } from 'src/app/core/permissions';
import { ModelListViewModel } from 'src/app/models/model.model';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { UserService } from 'src/app/admin/user.service';

import { ModelService } from '../../model.service';
import { ModelListFilterService } from '../model-list.filter.service';
import { ModelFilters } from '../model-list.filter.model';
import { filterLabel } from '../model-list.labels';
import { FILTER_INPUT_DEBOUNCE } from '../filters/model-filters.constants';
import { ColumnMode, DatatableComponent  } from '@swimlane/ngx-datatable';
import { merge, fromEvent, animationFrameScheduler } from 'rxjs';
import { startWith, observeOn } from 'rxjs/operators';

@Component({
    selector: 'app-model-table',
    templateUrl: './model-table.component.html',
    styleUrls: ['./model-table.component.scss'],
})
export class ModelTableComponent implements OnInit, OnDestroy {
    AppPermissions = AppPermissions;
    filterLabel = filterLabel;
    models: ModelListViewModel[];
    filters = new ModelFilters();
    filterNameUpdate = new Subject<string>();
    subscribedOnlyToggled = new Subject<boolean>();
    columnMode = ColumnMode;
    includeDeactivated = new Subject<boolean>();
    includeDraft = new Subject<boolean>();
    includeDeactivatedModel: string;
    includeDraftModel: string;
    icrRole: string;
    clientType: string;

    @ViewChild(DatatableComponent, { static: true })
    datatableComponent: DatatableComponent;

    private subscription = new Subscription();

    constructor(
        private modelService: ModelService,
        private messageBoxService: MessageBoxService,
        private route: ActivatedRoute,
        private modelListFilterService: ModelListFilterService,
        private changeDetectorRef: ChangeDetectorRef,
        private userService: UserService,
    ) {}

    ngOnInit() {
        const datatableBody =
            this.datatableComponent.element.getElementsByClassName('datatable-body').item(0) as HTMLElement;
        merge(
            fromEvent(window, 'resize'),
            fromEvent(window, 'orientationchange'),
        ).pipe(
            startWith(1),
            observeOn(animationFrameScheduler),
        ).subscribe(() => {
            if(datatableBody !== null){
                datatableBody.style.height = '25rem';
                datatableBody.style.overflowY = 'scroll';
            }
        });

        this.includeDeactivatedModel = 'false';
        this.includeDraftModel = 'false';

        this.subscription.add(
            this.userService.getCurrentUserContext().subscribe(
                (context) => {
                    this.clientType = context.clientType;
                    this.icrRole = context.roles[0];
                },
            ),
        );

        this.subscription.add(
            this.subscribedOnlyToggled
                .subscribe(value => {
                    this.modelListFilterService.filterSubscribedOnly(value.toString());
                }),
        );

        this.subscription.add(
            this.modelListFilterService.filtersObservable.subscribe(filters => {
                this.filters = filters;
                this.changeDetectorRef.detectChanges();
            }),
        );
        this.subscription.add(
            this.modelListFilterService.modelsObservable.subscribe(models => (this.models = models)),
        );
        this.loadModels();

        // Debounced filtering on Name
        this.subscription.add(
            this.filterNameUpdate
                .pipe(
                    debounceTime(FILTER_INPUT_DEBOUNCE),
                    distinctUntilChanged(),
                )
                .subscribe(value => {
                    this.modelListFilterService.filterByName(value);
                }),
        );


        this.subscription.add(
            this.includeDeactivated.subscribe(value => {
                this.includeDeactivatedModel= value.toString();
                this.loadModels();
            }),
        );

        this.subscription.add(
            this.includeDraft.subscribe(value => {
                this.includeDraftModel = value.toString();
                this.loadModels();
            }),
        );
    }

    loadModels() {
        this.subscription.add(
            this.modelService.getModels(this.includeDraftModel, this.includeDeactivatedModel).subscribe(
                models => {
                    this.modelListFilterService.setModels(models);
                    this.modelListFilterService.applyFiltersFromUrl(this.route.snapshot.queryParams);
                },
                () => this.messageBoxService.error('models-list-fetch-failed'),
            ),
        );
    }

    get allModelsCount(): number {
        return this.modelListFilterService.allModelsCount;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
