import { AuthConfig } from 'angular-oauth2-oidc';
import { indentityServiceUrl } from '../constants';

export const authConfig: AuthConfig = {

    // Url of the Identity Provider
    issuer: indentityServiceUrl,

    skipIssuerCheck: true,

    // URL of the SPA to redirect the user to after login
    redirectUri: `${window.location.origin}/main/index.html`,
    postLogoutRedirectUri: window.location.origin,

    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: 'js',

    dummyClientSecret: 'lame',

    // set the scope for the permissions the client should request
    scope: 'openid api1 offline_access profile',

    responseType: 'code',

    requireHttps: false,

    // turn off validation that discovery document endpoints start with the issuer url defined above
    strictDiscoveryDocumentValidation: false,
}

export const sessionConfig = {
    userIdleAfterSeconds: 270,
    userLogoutAfterIdleSeconds: 30,
    refreshTokenIntervalInSeconds: 120,
}
