import { Component } from '@angular/core';
import { Title }  from '@angular/platform-browser';
import { AuthenticationService } from './core/auth/authentication.service';
import { SessionTimeoutService } from './core/auth/session-timeout.service';

@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
})
export class AppComponent {
    title = 'icrp-web-app';

    public constructor(
        titleService: Title,
        authService: AuthenticationService,
        sessionTimeoutService: SessionTimeoutService) {

        titleService.setTitle('ICR Partners');
        authService.init();
        sessionTimeoutService.init();
    }
}
