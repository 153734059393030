import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

const nonNumericPattern = new RegExp(/[\D]|\./g);
const nonAlphaNumericPattern = new RegExp(/[^0-9A-Za-z]/g);
const noSpacePattern = new RegExp(/ /g);

@Directive({
    selector: '[appLimitCharset]',
})
export class LimitCharsetDirective implements OnInit {
    @Input('appLimitCharset') public appLimitCharset: 'numeric' | 'alphaNumeric' | 'noSpace';
    pattern: RegExp;

    constructor(private ngControl: NgControl) {
    }

    ngOnInit() {
        switch(this.appLimitCharset) {
            case 'numeric': {
                this.pattern = nonNumericPattern;
                break;
            }
            case 'alphaNumeric': {
                this.pattern = nonAlphaNumericPattern;
                break;
            }
            case 'noSpace': {
                this.pattern = noSpacePattern;
                break;
            }
            default: { this.pattern = new RegExp('') }
        }
    }

    @HostListener('input', ['$event.target.value']) onInput(value: string) {
        this.ngControl.control.patchValue(value.replace(this.pattern, ''));
    }
}
