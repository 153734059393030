import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModelListViewModel } from 'src/app/models/model.model';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { ModelService } from '../../model-pages/model.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-model-authorize-table',
    templateUrl: './model-authorize-table.component.html',
    styleUrls: ['./model-authorize-table.component.scss'],
})
export class ModelBatchAuthorizeTableComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();
    models: ModelListViewModel[];
    public firmId: string;

    datatableComponent: DatatableComponent;

    constructor(
        private messageBoxService: MessageBoxService,
        private activatedRoute: ActivatedRoute,
        private modelService: ModelService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.firmId = this.activatedRoute.snapshot.paramMap.get('firmId');
        this.loadPendingAuthorizedModels();
    }

    loadPendingAuthorizedModels() {
        this.subscription.add(
            this.modelService.getBatchTransferModelsByFirm(this.firmId).subscribe(
                models => {
                    this.models = models;
                },
                () => this.messageBoxService.error('models-list-fetch-failed'),
            ),
        );
    }

    onActivate(e) {
        if (e.type === 'click' && e.column.prop === 'name') {
            this.router.navigate([`./models/${e.row.id}`],
                { queryParamsHandling: 'merge' });
        } else if(e.type === 'click' && e.column.prop === 'authorizeDeAuthorizeLabel') {
            const modelId = e.row.id;
            const isAuthorize = e.row.authorizeDeAuthorizeLabel === 'Authorize';

            if(isAuthorize){
                this.subscription.add(
                    this.modelService.authorize(this.firmId, modelId).subscribe(
                        () => {
                            this.loadPendingAuthorizedModels();
                        },
                        () => {
                            this.messageBoxService.error('authorize-model-error');
                        },
                    ),
                );
            } else{
                this.subscription.add(
                    this.modelService.deauthorize(this.firmId, modelId).subscribe(
                        () => {
                            this.loadPendingAuthorizedModels();
                        },
                        () => {
                            this.messageBoxService.error('deauthorize-model-error');
                        },
                    ),
                );
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
