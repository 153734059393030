import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrl } from '../core/constants';
import { PortfolioManagementFirm } from '../models/firm.model';
import { PortfolioManagementFirmListViewModel } from 'src/app/models/firm.model';

@Injectable({
    providedIn: 'root',
})
export class PortfolioManagementFirmService {
    constructor(private http: HttpClient){}
    public createFirm(firm: PortfolioManagementFirm): Observable<void> {
        return this.http.post<void>(`${apiUrl}/api/v1/portfoliomanagementfirm`,
            firm);
    }

    public getFirms(): Observable<PortfolioManagementFirmListViewModel[]> {
        return this.http.get<PortfolioManagementFirmListViewModel[]>(`${apiUrl}/api/v1/portfoliomanagementfirm`);
    }
}
