import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PrimaryButtonComponent } from './buttons/primary-button/primary-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorComponent } from './messages/error-message/error.component';
import { CardComponent } from './layout/card/card.component';
import { SectionComponent } from './layout/section/section.component';
import { TextInputComponent } from './inputs/text-input/text-input.component';
import { NumberInputComponent } from './inputs/number-input/number-input.component';
import { MobilePhoneInputComponent } from './inputs/mobile-phone-input/mobile-phone-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectBoxComponent } from './inputs/select-box/select-box.component';
import { ButtonLinkComponent } from './buttons/button-link/button-link.component';
import { TextInputIconComponent } from './inputs/text-input-icon/text-input-icon.component';
import { HttpClientModule } from '@angular/common/http';
import { TextAreaComponent } from './inputs/text-area/text-area.component';
import { ConfirmDialogComponent } from './messages/confirm-dialog/confirm-dialog.component';
import { LimitCharsetDirective } from './inputs/limit-charset.directive';
import { CheckListComponent } from './lists/check-list/check-list.component';
import { SuccessComponent } from './messages/success/success.component';
import { RouterModule } from '@angular/router';
import { BackButtonComponent } from './buttons/back-button/back-button.component';
import { TextMaskModule } from 'angular2-text-mask';
import { PasswordInputComponent } from './inputs/password-input/password-input.component';
import { CenteredFormLayoutComponent } from './layout/centered-form-layout/centered-form-layout.component';
import { IconButtonComponent } from './buttons/icon-button/icon-button.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RadioSelectComponent } from './inputs/radio-select/radio-select.component';
import { MultiSelectBoxComponent } from './inputs/multi-select-box/multi-select-box.component';
import { LoaderComponent } from './loader/loader.component';
import { FocusFirstInvalidFieldDirective } from './directives/focus-first-invalid-field.directive';
import { PermittedDirective } from './guards/permitted.directive';
import { StatusLabelComponent } from './status/status-label/status-label.component';
import { StatusIndicatorComponent } from './status/status-indicator/status-indicator.component';
import { DeleteTrashcanComponent } from './buttons/delete-trashcan/delete-trashcan.component';
import { ResearchUploadModalComponent } from './messages/research-upload-modal/research-upload-modal.component';
import { PagedDataTableDirective } from './directives/paged-data-table-directive';
import { HoldingsTotalBarComponent } from './bars/holdings-total-bar/holdings-total-bar.component';
import { ToggleButtonComponent } from './buttons/toggle-button/toggle-button.component';
import { InputNumbersOnlyDirective } from './directives/input-numbers-only.directive';
import { SwitchButtonComponent } from './buttons/switch-button/switch-button.component';
import { DatePickerInputComponent } from './inputs/datetime-picker/datepicker.component';
import { ClickOutsideDirective } from './inputs/datetime-picker/clickOutside';

@NgModule({
    declarations: [
        PrimaryButtonComponent,
        ErrorComponent,
        CardComponent,
        SectionComponent,
        TextInputComponent,
        NumberInputComponent,
        MobilePhoneInputComponent,
        SelectBoxComponent,
        ButtonLinkComponent,
        TextInputIconComponent,
        TextAreaComponent,
        ConfirmDialogComponent,
        LimitCharsetDirective,
        CheckListComponent,
        SuccessComponent,
        ConfirmDialogComponent,
        BackButtonComponent,
        PasswordInputComponent,
        CenteredFormLayoutComponent,
        IconButtonComponent,
        RadioSelectComponent,
        MultiSelectBoxComponent,
        LoaderComponent,
        FocusFirstInvalidFieldDirective,
        PermittedDirective,
        StatusLabelComponent,
        StatusIndicatorComponent,
        DeleteTrashcanComponent,
        ResearchUploadModalComponent,
        PagedDataTableDirective,
        HoldingsTotalBarComponent,
        ToggleButtonComponent,
        InputNumbersOnlyDirective,
        SwitchButtonComponent,
        ClickOutsideDirective,
        DatePickerInputComponent,
    ],

    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        HttpClientModule,
        AngularSvgIconModule,
        RouterModule,
        TextMaskModule,
        NgbTooltipModule,
    ],
    exports: [
        NgbTooltipModule,
        PrimaryButtonComponent,
        ErrorComponent,
        CardComponent,
        SectionComponent,
        NumberInputComponent,
        TextInputComponent,
        MobilePhoneInputComponent,
        SelectBoxComponent,
        ButtonLinkComponent,
        TextInputIconComponent,
        TextAreaComponent,
        ConfirmDialogComponent,
        CheckListComponent,
        SuccessComponent,
        BackButtonComponent,
        PasswordInputComponent,
        CenteredFormLayoutComponent,
        IconButtonComponent,
        RadioSelectComponent,
        MultiSelectBoxComponent,
        LoaderComponent,
        FocusFirstInvalidFieldDirective,
        PermittedDirective,
        StatusLabelComponent,
        StatusIndicatorComponent,
        DeleteTrashcanComponent,
        ResearchUploadModalComponent,
        PagedDataTableDirective,
        HoldingsTotalBarComponent,
        ToggleButtonComponent,
        InputNumbersOnlyDirective,
        SwitchButtonComponent,
        ClickOutsideDirective,
        DatePickerInputComponent,
    ],
})
export class SharedModule { }
