import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'equity-dynamic-holding',
    styleUrls: ['./equity-dynamic-holding.component.scss', '../column-sizes.edit.scss'],
    templateUrl: './equity-dynamic-holding.component.html',
})
export class EquityDynamicHoldingComponent implements OnInit {

    @Input() positions = [];

    constructor() { }

    ngOnInit() {
        this.positions = ['B', 'H'];
    }

    @Input() holdingForm: FormGroup;
    @Input() index: number = null;
    @Output() onDelete = new EventEmitter();

    fieldHasError(key: string, errorType: string) {
        const field = this.holdingForm.get(key);

        if (field){
            return field.touched && field.hasError(errorType);
        }

        return false;
    }

    deleteSelf() {
        this.onDelete.emit();
    }
}
