import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SleeveModel } from 'src/app/models/model.model';
import { assetClassLabel } from '../sleeve-model.labels';

@Component({
    selector: 'app-model-sleeve-edit',
    templateUrl: 'model-sleeve-edit.component.html',
    styleUrls: ['./model-sleeve-edit.component.scss', '../../../model-holdings/column-sizes.edit.scss'],
})

export class ModelSleeveEditComponent implements OnInit{
    @Input() parentForm: FormGroup;
    @Input() availableModels: SleeveModel[];
    @Input() modelList: SleeveModel[];
    @Input() index: number = null;
    @Input() editWholeRow = true;

    @Output() sleeveChanged = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();

    firmName: string;
    assetClass: string;

    constructor() { }

    ngOnInit(): void {
        this.setReadOnlyValues();
    }

    onSleeveChanged() {
        this.sleeveChanged.emit();
        this.setReadOnlyValues();
    }

    fieldHasError(key: string, errorType: string) {
        const field = this.parentForm.get(key);

        if (field) {
            return field.touched && field.hasError(errorType);
        }

        return false;
    }

    get sleeveModelName() {
        const sleeveId = this.parentForm.get('sleeveModelId');
        const model = this.modelList.find(x => x.id === sleeveId.value);

        return model && model.name || '';
    }

    private setReadOnlyValues() {
        const sleeveId = this.parentForm.get('sleeveModelId');
        this.setFirmName(sleeveId && sleeveId.value);
        this.setAssetClass(sleeveId && sleeveId.value);
    }

    private setFirmName(modelId: string) {
        if (!modelId) {
            this.firmName = '';
        }
        const model = this.modelList.find(x => x.id === modelId);
        if (model) {
            this.firmName = model.firmName;
        }
    }

    private setAssetClass(modelId: string) {
        if (!modelId) {
            this.assetClass = '';
        }
        const model = this.modelList.find(x => x.id === modelId);
        if (model) {
            this.assetClass = assetClassLabel(model);
        }
    }
}
