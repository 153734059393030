import { Holding } from './holding.model';
import { ModelCategory } from '../model-pages/model-category.enum';
import { ModelStatus } from '../core/model-status';
import { ModelType } from '../core/model-type';
import { ModelStyleClass } from '../core/model-style-class';
import { ModelRegion } from '../core/model-region';

export interface CreateModelRequest {
    name: string;
    type: ModelType;
    benchmarkName: string;
    description: string;
    category: ModelCategory;
}

export class Model {
    id: string;
    name: string;
    type: ModelType;
    benchmarkName: string;
    performanceBenchmark: string;
    description: string;
    category: ModelCategory;
    status: ModelStatus;
    holdings: Holding[];
    firmName: string;
    statisticsId: string;
    isCreatedByCurrentUserFirm: boolean;
    isSubscribedByCurrentUser: boolean;
    createdByFirstName: string;
    createdByLastName: string;
    isResearched: boolean;
}

export class ModelDetailsViewModel extends Model {
    categoryLabel: string;
    typeLabel: string;
    styleClass: ModelStyleClass;
    styleClassLabel: string;
    region: ModelRegion;
    regionLabel: string;
    managerFee: number;
    managerFeeLabel: string;
}

export class ModelDetailsEditModel {
    id: string;
    name: string;
    benchmarkName: string;
    performanceBenchmark: string;
    statisticsId: string;
    managerFee: number;
    region: ModelRegion;
    styleClass: ModelStyleClass;
    description: string;
}

export class ModelDetailsCreateModel {
    id: string;
    name: string;
    type: ModelType;
    benchmarkName: string;
    description: string;
    category: ModelCategory;
}

export interface ModelListViewModel {
    id: string;
    name: string;
    type: ModelType;
    region: ModelRegion;
    benchmarkName: string;
    description: string;
    category: ModelCategory;
    status: ModelStatus;
    firmName: string;
    alpha: number;
    beta: number;
    sharpeRatio: number;
    rSquared: number;
    oneMonth: number;
    threeMonthTrailing: number;
    yearToDate: number;
    oneYearTrailing: number;
    threeYearTrailing: number;
    managerFee: number;
    styleClass: ModelStyleClass;
    lastUpdatedAt: Date;
    isSubscribed: boolean;
    isAccess: boolean;
    isResearched: boolean;
}

export interface SleeveModel {
    id: string;
    name: string;
    region: ModelRegion;
    category: ModelCategory;
    firmName: string;
    styleClass: ModelStyleClass;
}

export interface ModelStatistics {
    alpha: number;
    beta: number;
    sharpeRatio: number;
    rSquared: number;
    standardDeviation: number;
    maximumDrawdown: number;
    trackingError: number;
    infoRatio: number;
    effectiveFrom: string;
    oneMonth: number;
    threeMonthTrailing: number;
    yearToDate: number;
    oneYearTrailing: number;
    threeYearTrailing: number;
}

export class UpdateHoldingsModel {
    modelId: string;
    holdings: Holding[];
}

export class UpdateModelResearchModel {
    id: string;
    isResearched: string;
}
