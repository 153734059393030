// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLabel<T, K extends keyof T>(model: T, property: K, labels: {label: string;value: any}[]) {
    if (!model[property]) {
        return '-';
    }

    const label = labels.find(x => x.value === (model[property]));
    if (label) {
        return label.label;
    }

    return model[property];
}
