import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    FormArray,
    FormBuilder,
    FormGroup,
    Validators,
    NG_VALUE_ACCESSOR,
    NG_ASYNC_VALIDATORS,
    FormControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { IcrValidators } from 'src/app/shared/validators/icr.validators';
import { FIRM_TYPES, FirmType } from 'src/app/core/firm-type';
import { FIRM_BATCH_TRANSFER_TYPES } from 'src/app/core/firm-batch-transfer-type';
import { CLIENT_TYPES } from 'src/app/core/client-type';
import { US_STATES, COUNTRIES } from '../../core/constants';
import { contactFormValidator } from '../contact-form/contact-form.validator';
import { FirmFormNotificationService } from './firm-form-notification.service';
import { FirmFormValues } from './firm-form.models';
import { FirmService } from '../firm.service';
import { FirmValidators } from '../firm-name.validator';
import { map, filter, take } from 'rxjs/operators';

@Component({
    selector: 'app-firm-form',
    templateUrl: './firm-form.component.html',
    styleUrls: ['./firm-form.component.scss', '../admin.layout.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // https://angular.io/api/core/forwardRef - Allows to refer to references which are not yet defined.
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => FirmFormComponent),
            multi: true,
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => FirmFormComponent),
            multi: true,
        },
    ],
})
export class FirmFormComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() edit: string;

    form: FormGroup;
    firmTypes = [];
    clientTypes = CLIENT_TYPES.map(client => ({
        value: client.value,
        label: client.label,
    }));
    firmBatchTransferTypes = FIRM_BATCH_TRANSFER_TYPES.map(type => ({
        value: type.value,
        label: type.label,
    }));
    countries = COUNTRIES;
    states = US_STATES.map(state => ({
        value: state.abbreviation,
        label: state.name,
    }));

    private subscription = new Subscription();
    private originalName = '';

    get value(): FirmFormValues {
        return this.form.value;
    }

    set value(value: FirmFormValues) {
        if (value.contacts && value.contacts.length > 0) {
            value.contacts.forEach(() => {
                this.addContact();
            });
        }

        this.originalName = value.firmName;
        this.form.patchValue(value);
        this.onChange(value);
        this.onTouched();
    }

    get contacts(): FormArray {
        return this.form.get('contacts') as FormArray;
    }

    constructor(
        private fb: FormBuilder,
        private firmFormService: FirmFormNotificationService,
        private firmService: FirmService,
    ) {}

    isFirmTypeClient() {
        if (this.form.controls['firmType'].value === 'Client') {
            this.form.controls['firmBatchTransferType'].enable();
            return true;
        } else {
            this.form.controls['firmBatchTransferType'].disable();
            return false;
        }
    }

    ngOnInit() {
        this.firmTypes = FIRM_TYPES.filter(f => f.value !== FirmType.IcrPartners);

        this.form = this.fb.group(
            {
                firmName: [
                    '',
                    IcrValidators.requiredNoWhitespace,
                    (control) => FirmValidators.nameUnique(this.firmService, control, this.originalName),
                ],
                firmType: [null, Validators.required],
                firmBatchTransferType: [null, Validators.required],
                countryCode: null,
                firmAddress: '',
                firmAddress2: '',
                city: '',
                state: null,
                zipCode: ['', Validators.minLength(5)],
                businessPhoneCountryCode: '1',
                businessPhone: ['', Validators.minLength(12)],
                businessEmail: ['', Validators.email],
                website: '',
                contacts: this.fb.array([]),
                clientType: this.clientTypes[0].value,
            },
            {
                updateOn: 'blur',
            },
        );

        if (this.edit === '') {
            this.form.controls['firmType'].disable();
        }

        this.subscription.add(
            this.form.valueChanges.subscribe(value => {
                this.onChange(value);
                this.onTouched();
            }),
        );

        this.subscribeToParentOnSubmit();
    }

    onChange = (_: FirmFormValues) => {};
    onTouched = () => {};

    fieldHasError(key: string, errorType: string) {
        const field = this.form.get(key);
        if (field) {
            return field.touched && field.hasError(errorType);
        }

        return false;
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    writeValue(value) {
        if (value) {
            this.value = value;
        }

        if (value === null) {
            this.form.reset();
        }
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    validate(_: FormControl) {
        return this.form.statusChanges.pipe(
            filter(status => status !== 'PENDING'),
            take(1),
            map(() => {
                return this.form.valid ? null : { userInformation: { valid: false } };
            }))
    }

    addContact() {
        if (this.contacts.length >= 3) {
            return;
        }

        this.contacts.push(
            this.fb.group(
                {
                    id: null,
                    contactLabel: null,
                    firstName: '',
                    lastName: '',
                    businessEmail: ['', Validators.email],
                    phoneCountryCode: '1',
                    phone: ['', Validators.minLength(12)],
                    phoneExtension: '',
                    countryCode: null,
                    firmAddress: '',
                    firmAddress2: '',
                    city: '',
                    state: null,
                    zipCode: ['', Validators.minLength(5)],
                },
                {
                    updateOn: 'blur',
                    validators: [contactFormValidator],
                },
            ),
        );
    }

    deleteContact(index) {
        this.contacts.removeAt(index);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private subscribeToParentOnSubmit() {
        this.subscription.add(
            this.firmFormService.formStatusSubmitted.subscribe(() => {
                this.form.markAllAsTouched();
            }),
        );
        this.form.markAsUntouched();
    }
}
