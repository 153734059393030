import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'text-input-icon',
    templateUrl: './text-input-icon.component.html',
    styleUrls: ['./text-input-icon.component.scss'],
})
export class TextInputIconComponent {
    @Input() parentForm: FormGroup;
    @Input() label: string;
    @Input() placeholder = '';
    @Input() id: string;
    @Input() errorMessage: string;
    @Input() formValidationName: string;
    @Input() type: string;
    @Input() isNarrow: boolean;
    @Input() isDisabled: boolean;
    @Input() iconName: string;

    ngOnInit() {}

    getIconPath() {
        return `assets/icons/${this.iconName}.svg`;
    }
}
