import { Directive, Input, OnInit, HostBinding } from '@angular/core';
import { AppPermissions } from 'src/app/core/permissions';
import { AuthorizationService } from 'src/app/core/auth/authorization.service';

@Directive({
    selector: '[appPermitted]',
})
export class PermittedDirective implements OnInit {
    @Input('appPermitted') permission: AppPermissions;

    constructor(private authorizationService: AuthorizationService) { }

    @HostBinding('style.display') display: string;

    ngOnInit(): void {
        if (!this.authorizationService.isRolePermitted(this.permission)) {
            this.display = 'none';
        }
    }
}
