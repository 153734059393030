import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthenticationService } from './authentication.service';
import { filter } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { sessionConfig } from './auth.config';

@Injectable({ providedIn: 'root' })
export class SessionTimeoutService {

    constructor(
        private authService: AuthenticationService,
        private oauthService: OAuthService,
        private idle: Idle,
        private keepAlive: Keepalive) { }

    init() {
        this.idle.setIdle(sessionConfig.userIdleAfterSeconds);
        this.idle.setTimeout(sessionConfig.userLogoutAfterIdleSeconds);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe(() => {
            this.idle.watch();
        });

        this.idle.onIdleStart.subscribe(() => {
            // TODO: [ICR-52] show session timeout modal with 30 seconds countdown
        });

        this.idle.onTimeout.subscribe(() => {
            this.oauthService.logOut();
        });

        this.keepAlive.interval(sessionConfig.refreshTokenIntervalInSeconds);
        this.keepAlive.onPing.subscribe(() => this.oauthService.refreshToken());

        this.authService.isDoneLoading$
            .pipe(filter(loaded => loaded))
            .subscribe(() => {
                if (this.authService.isLoggedIn()) {
                    this.idle.watch();
                } else {
                    this.idle.stop();
                }
            });
    }
}
