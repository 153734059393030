import { UserContext } from './../user-context';
import { Observable, of, Subscription, ReplaySubject } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { Injectable, OnDestroy } from '@angular/core';
import { BasicIdentity } from './basic-identity';
import { UserService } from 'src/app/admin/user.service';
import { tap, publishReplay, refCount, filter } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
    private userContextCacheKey = 'icrp_user_context';
    private userContextObservable: Observable<UserContext>;
    private subscription: Subscription = new Subscription();

    private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

    constructor(
        private oauthService: OAuthService,
        private userService: UserService,
        private router: Router) {

        this.oauthService.events
            .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
            .subscribe(_ => this.navigateToLogin());
    }

    init() {
        this.oauthService.loadDiscoveryDocumentAndTryLogin()
            .then(() => {
                this.isDoneLoadingSubject$.next(true);

                if (this.oauthService.hasValidAccessToken() && this.oauthService.state ) {
                    this.router.navigateByUrl(decodeURIComponent(this.oauthService.state));
                }
            })
            .catch(() => this.isDoneLoadingSubject$.next(true));

        this.userContextObservable = this.userService
            .getCurrentUserContext()
            .pipe(
                tap(data => sessionStorage.setItem(this.userContextCacheKey, JSON.stringify(data))),
                publishReplay(1),
                refCount(),
            );

        this.subscription.add(this.oauthService.events
            .pipe(filter(e => ['token_received', 'logout'].includes(e.type)))
            .subscribe(() => sessionStorage.removeItem(this.userContextCacheKey)));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    get name(): string {
        const claims = this.oauthService.getIdentityClaims() as BasicIdentity;
        if (!claims) {
            return null;
        }

        return claims.given_name;
    }

    get userContext(): Observable<UserContext> {
        const userContextFromSession = JSON.parse(sessionStorage.getItem(this.userContextCacheKey)) as UserContext

        return userContextFromSession
            ? of(userContextFromSession)
            : this.userContextObservable;
    }

    isLoggedIn(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    private navigateToLogin() {
        this.oauthService.initLoginFlow(this.router.url);
    }
}
