import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { SleeveModel } from 'src/app/models/model.model';
import { FormArray, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ModelService } from '../../model.service';
import { Subscription } from 'rxjs';
import { PERCENTAGE_MASK } from '../../model-holding-shared';
import { modelSleevesValidator } from './model-sleeves.validator';
import { SleeveHolding } from 'src/app/models/sleeve-holding.model';

@Component({
    selector: 'app-model-sleeves',
    templateUrl: './model-sleeves.component.html',
    styleUrls: ['./model-sleeves.component.scss', '../column-sizes.edit.scss'],
})

export class ModelSleevesComponent implements OnInit {
    @Input() sleeves: SleeveHolding[];
    @Input() sleevesForm: FormArray;

    public readonly INITIAL_ROWS_COUNT = 10;
    public readonly MAX_ROW_LIMIT = 30;

    percentageMask = PERCENTAGE_MASK;

    availableModels: SleeveModel[];
    modelList: SleeveModel[];

    get sleeveRows() {
        return this.sleevesForm.controls;
    }

    private subscription = new Subscription()

    constructor(
        private modelService: ModelService,
        private fb: FormBuilder,
        private changeDetector: ChangeDetectorRef) { }

    ngOnInit() {
        this.subscription.add(this.modelService.getSleeveModels()
            .subscribe((models) => {
                this.modelList = models;
                this.availableModels = models;
                this.initializeSleeves();
            }));
    }

    initializeSleeves() {
        const rowsCount = Math.max(this.sleeves.length, this.INITIAL_ROWS_COUNT);
        for (let i = 0; i < rowsCount; i++) {
            this.sleevesForm.push(this.getSleeveForm(this.sleeves[i]));
        }

        if (this.sleeves.length) {
            this.changeDetector.detectChanges();
            this.updateAvailableModels();
        }
    }

    deleteRow(index: number) {
        this.sleevesForm.markAsDirty();
        this.sleevesForm.removeAt(index);
        this.updateAvailableModels();
    }

    addNewRow() {
        this.sleevesForm.push(this.getSleeveForm());
    }

    updateAvailableModels() {
        const selectedModels: string[] = this.sleeveRows
            .map(x => x.get('sleeveModelId'))
            .filter(x => x && x.value)
            .map(x => x.value);

        this.availableModels = [...this.modelList.filter(x => !selectedModels.includes(x.id))];
    }

    private getSleeveForm(sleeve?: SleeveHolding): FormGroup {
        const { id, sleeveModelId, quantity } = sleeve || { id: null, sleeveModelId: null, quantity: null };

        return this.fb.group(
            {
                id: id,
                sleeveModelId: [sleeveModelId, Validators.required],
                quantity: [quantity, [Validators.max(100), Validators.min(0.0001)]],
            },
            {
                updateOn: 'change',
                validators: modelSleevesValidator,
            },
        )
    }
}
