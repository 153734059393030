import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Model } from 'src/app/models/model.model';

@Injectable({
    providedIn: 'root',
})
export class ModelStateManagementService {

    private modelStatusSource = new Subject<Model>();
    modelDetailsUpdated = this.modelStatusSource.asObservable();

    private modelInEditModeSource = new Subject<boolean>();
    modelInEditModeUpdated = this.modelInEditModeSource.asObservable();

    constructor() { }

    updateModelDetails(model: Model) {
        this.modelStatusSource.next(model);
    }

    turnOnViewMode() {
        this.modelInEditModeSource.next(false);
    }

    turnOnEditMode() {
        this.modelInEditModeSource.next(true);
    }
}
