import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingScreenService } from './loading-screen.service';


@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {

    // URLs for which the loading screen should not be enabled
    skipUrls = [
        '/connect/token',
        '/api/v1/firm/exists',
        '/api/v1/models/exists',
    ];

    constructor(private loadingScreenService: LoadingScreenService) {  }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let displayLoadingScreen = true;

        for (const url of this.skipUrls) {
            if (new RegExp(url).test(request.url)) {
                displayLoadingScreen = false;
                break;
            }
        }

        if (displayLoadingScreen) {
            this.loadingScreenService.showLoaderForHttpRequest();

            return next.handle(request).pipe(
                finalize(() => {
                    this.loadingScreenService.hideLoader();
                }),
            )
        } else {
            return next.handle(request);
        }
    };
}
