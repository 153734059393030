import { Component, OnInit, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { WarnOnLeaveFormComponent } from 'src/app/shared/warn-on-leave/warn-on-leave-form.component';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { Observable, Subscription, of, combineLatest } from 'rxjs';
import { Firm } from 'src/app/models/firm.model';
import { UserService } from '../user.service';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FirmService } from '../firm.service';
import { UserFormNotificationService } from '../user-form/user-form-notification.service';
import { catchError, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ResetFormService } from 'src/app/shared/guards/form-reset.service';
import { ConfirmDialogService } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.service';
import { LoadingScreenService } from 'src/app/shared/loader/loading-screen.service';

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['../admin.layout.scss'],
})

export class EditUserComponent extends WarnOnLeaveFormComponent implements OnInit, OnDestroy {
    @ViewChild('form', { static: false }) form: NgForm;

    userForm: FormGroup;

    firmId: string;
    userId: string;
    lockUserId = 'lockUserId';
    unLockUserId = 'unLockUserId';
    firmObservable: Observable<Firm>;
    error: string;

    userCanBeDeactivated = false;
    isUserAccountLocked = false;

    private subscription = new Subscription();

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private messageBoxService: MessageBoxService,
        private router: Router,
        private route: ActivatedRoute,
        private firmService: FirmService,
        private userFormService: UserFormNotificationService,
        private ngZone: NgZone,
        private resetFormService: ResetFormService,
        private confirmDialog: ConfirmDialogService,
        private loadingScreenService: LoadingScreenService,
    ) {
        super();
    }

    ngOnInit() {
        this.userForm = this.fb.group({
            userInformation: [{}],
        });

        this.firmId = this.route.snapshot.paramMap.get('firmId');

        this.firmObservable = this.firmService.getFirm(this.firmId).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 404) {
                    this.ngZone.run(() => this.router.navigate(['admin/firms']));
                    setTimeout(() => {
                        this.messageBoxService.error('firm-not-exists-error');
                    }, 0);
                }
                return of(null);
            }),
        );

        this.loadUserData();

        this.subscription.add(
            this.resetFormService.shouldResetSelfObservable.subscribe(
                () => this.userForm.markAsPristine(),
            ),
        );
    }

    loadUserData() {
        this.userId = this.route.snapshot.paramMap.get('userId');

        // user currently being edited
        const user$ = this.userService.getUser(this.firmId, this.userId);
        // user currently logged in
        const currentUser$ = this.userService.getCurrentUserContext();

        // combining both calls, since we need info from both of them
        // to make the decision, whether user can be deactivated
        this.subscription.add(
            combineLatest(
                user$,
                currentUser$,
                (user, currentUser) => ({ user, currentUser }),
            ).subscribe(({ user, currentUser }) => {

                this.userForm.controls.userInformation.setValue(user);
                this.userForm.markAsPristine();

                if (user.active
                    && currentUser.userId !== this.userId) {
                    this.userCanBeDeactivated = true;
                    this.isUserAccountLocked = user.isLockedOut;
                }
            }),
        );
    }

    async openDeactivateUserModal() {
        const confirmed = await this.confirmDialog.confirm(this.userId).toPromise();

        if (confirmed) {
            this.loadingScreenService.showLoader('Deactivating user...');
            this.subscription.add(
                this.userService
                    .deactivateUser(this.userId, this.firmId)
                    .pipe(finalize(() => this.loadingScreenService.hideLoader()))
                    .subscribe(
                        () => {
                            this.userCanBeDeactivated = false;
                            this.messageBoxService.success('admin-deactivate-user-success');
                        },
                        () => this.messageBoxService.error('admin-deactivate-user-error'),
                    ),
            );
        }
    }

    async openUnlockUserModal(){
        const confirmed = await this.confirmDialog.confirm(this.unLockUserId).toPromise();

        if (confirmed) {
            this.loadingScreenService.showLoader('Unlock user...');
            this.subscription.add(
                this.userService
                    .unLockUser(this.firmId, this.userId)
                    .pipe(finalize(() => this.loadingScreenService.hideLoader()))
                    .subscribe(
                        () => {
                            this.isUserAccountLocked = false;
                            this.messageBoxService.success('admin-unlock-user-success');
                        },
                        () => this.messageBoxService.error('admin-unlock-user-error'),
                    ),
            );
        }
    }

    async openLockUserModal(){
        const confirmed = await this.confirmDialog.confirm(this.lockUserId).toPromise();

        if (confirmed) {
            this.loadingScreenService.showLoader('Lock user...');
            this.subscription.add(
                this.userService
                    .lockUser(this.firmId, this.userId)
                    .pipe(finalize(() => this.loadingScreenService.hideLoader()))
                    .subscribe(
                        () => {
                            this.isUserAccountLocked = true;
                            this.messageBoxService.success('admin-lock-user-success');
                        },
                        () => this.messageBoxService.error('admin-lock-user-error'),
                    ),
            );
        }
    }

    onSubmit() {
        this.userForm.markAllAsTouched();
        this.userFormService.notifyOnFormSubmission();

        if (this.userForm.valid && !this.userForm.dirty) {
            this.ngZone.run(() => this.router.navigate(['../../'], { relativeTo: this.route }));
            this.messageBoxService.success('admin-edit-user-without-changes-success');
        }

        if (this.userForm.valid && this.userForm.dirty) {
            this.subscription.add(
                this.userService
                    .editUser(
                        {
                            ...this.userForm.value.userInformation,
                            id: this.userId,
                        },
                        this.firmId,
                        this.userId,
                    ).subscribe(
                        () => {
                            this.userForm.markAsPristine();
                            this.ngZone.run(() => this.router.navigate(['../../'], { relativeTo: this.route }));
                            this.messageBoxService.success('admin-edit-user-success');
                        },
                        response => {
                            if (typeof response.error === 'object') {
                                this.error = null;
                            } else {
                                this.error = response.error;
                            }
                            this.messageBoxService.error('edit-user-error');
                        },
                    ),
            );
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
