import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ResearchType } from 'src/app/core/research-type';
import { RESEARCH_CATEGORIES } from 'src/app/core/research-category';
import { ResearchUploadModal, ResearchUploadModalService } from './research-upload-modal.service';
import { Subscription } from 'rxjs';
import { ModelResearchService }
    from 'src/app/model-pages/model-detail/details-tab/view/model-research.service';
import { ModelResearch } from 'src/app/models/model-research.model';
import { MessageBoxService } from '../message-box.service';
import { LoadingScreenService } from '../../loader/loading-screen.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-research-upload-modal',
    templateUrl: './research-upload-modal.component.html',
    styleUrls: ['./research-upload-modal.component.scss'],
})
export class ResearchUploadModalComponent implements OnInit, OnDestroy, ResearchUploadModal {
    @Input() modelId: string;
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

    isOpen = false;
    modelResearchForm: FormGroup;

    readonly availableResearchTypes = undefined;//RESEARCH_TYPES;
    availableResearchCategories = RESEARCH_CATEGORIES;

    private subscription = new Subscription();

    constructor(
        private fb: FormBuilder,
        private modalService: ResearchUploadModalService,
        private modelResearchService: ModelResearchService,
        private messageBoxService: MessageBoxService,
        private loadingScreenService: LoadingScreenService,
    ) { }

    ngOnInit() {
        if (!this.modelId) {
            return;
        }

        this.modalService.add(this);

        this.modelResearchForm = this.fb.group({
            category: [null, [Validators.required]],
            type: [ResearchType.Document, Validators.required],
        });
    }

    cancelAction() {
        this.isOpen = false;
        this.modalService.fileUploadDeclined();
    }

    showUploadResearchModal(researchCategories: { label: string; value: string }[]): void {
        this.availableResearchCategories = researchCategories;

        this.modelResearchForm.patchValue({ category: null, type: ResearchType.Document });
        this.modelResearchForm.markAsUntouched({ onlySelf: false });
        this.isOpen = true;
    }

    openFileSelectDialog(): void {
        this.modelResearchForm.markAllAsTouched();

        if (this.modelResearchForm.valid && this.modelResearchForm.dirty) {
            this.isOpen = false;
            this.fileInput.nativeElement.click();
        }
    }

    uploadResearchFile(event): void {
        const fileList: FileList = event.target.files;
        const fileToUpload = fileList[0];
        if (this.modelResearchService.fileExceedsSizeLimit(fileToUpload)) {
            this.messageBoxService.error('model-research-file-too-large-error');
            return;
        }

        const modelResearch: ModelResearch = this.modelResearchForm.value;
        modelResearch.modelId = this.modelId;
        this.loadingScreenService.showLoader('Uploading research...');

        this.modelResearchService.addNewResearch(modelResearch, fileToUpload)
            .pipe(finalize(() => this.loadingScreenService.hideLoader()))
            .subscribe(
                () => {
                    this.messageBoxService.success('model-research-upload-success');
                    this.modalService.fileUploadSuccessful();
                },
                (errResponse) => {
                    if (errResponse && errResponse.error === 'File exceeds size limit.') {
                        this.messageBoxService.error('model-research-file-too-large-error');
                    } else {
                        this.messageBoxService.error('model-research-upload-error');
                    }
                    this.modalService.fileUploadDeclined();
                },
            );
    }

    ngOnDestroy(): void {
        this.modalService.remove(this);
        this.subscription.unsubscribe();
    }
}
