import { Component, OnInit, Input } from '@angular/core';
import { CheckItem } from './check-item';

@Component({
    selector: 'check-list',
    templateUrl: './check-list.component.html',
    styleUrls: ['./check-list.component.scss'],
})
export class CheckListComponent implements OnInit {
    constructor() {}

    ngOnInit() {}

    @Input() list: CheckItem[] = [];
}
