import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelService } from '../model.service';
import { Model } from 'src/app/models/model.model';
import { MODEL_CATEGORIES } from 'src/app/core/constants';
import { Subscription } from 'rxjs';
import { ModelHoldingsDataService } from '../model-holdings/model-holdings.data.service';
import { UploadModelHoldingsService } from './upload-model-holdings.service';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { ModelType } from 'src/app/core/model-type';
import { ModelStatus } from 'src/app/core/model-status';

@Component({
    selector: 'app-upload-model',
    templateUrl: './upload-model.component.html',
    styleUrls: ['./upload-model.component.scss', '../model-pages.layout.scss'],
})
export class UploadModelComponent implements OnInit, OnDestroy {
    model: Model;

    private modelId: string;
    private subscription = new Subscription();
    errorText: string;

    constructor(
        private modelService: ModelService,
        private router: Router,
        private route: ActivatedRoute,
        private modelHoldingsDataService: ModelHoldingsDataService,
        private uploadModelHoldingsService: UploadModelHoldingsService,
        private messageBoxService: MessageBoxService,
    ) {}

    ngOnInit() {
        this.modelId = this.route.snapshot.paramMap.get('id');

        this.subscription.add(this.modelService.getModel(this.modelId)
            .subscribe(model => {
                if (model.status !== ModelStatus.Draft) {
                    setTimeout(() => this.messageBoxService.open('forbidden-route'));
                    this.router.navigate(['models']);
                    return;
                }

                if (model.type === ModelType.ModelOfModels) {
                    this.navigateToHoldings();
                }
                this.model = model;

            }));
    }

    navigateToHoldings() {
        this.router.navigate([`/models/create/${this.modelId}/holdings`], { queryParamsHandling: 'merge' });
    }

    enterHoldingsManually() {
        this.modelHoldingsDataService.clearUploadedHoldings();
        this.navigateToHoldings();
    }

    fullUploadHoldings() {
        this.navigateToHoldings();
    }

    partialHoldingsUpload() {
        this.onUploadError('Upload Error');
    }

    onFileUpload($event) {
        this.subscription.add(
            this.uploadModelHoldingsService.uploadFile(this.model.category, $event.target.files[0]).subscribe(error => {
                if (error) {
                    this.onUploadError(error);
                } else {
                    this.navigateToHoldings();
                }
            }),
        );
    }

    private onUploadError(errorText: string) {
        this.errorText = errorText;
        this.messageBoxService.error('upload-error');
    }

    get modelCategoryLabel() {
        const category = MODEL_CATEGORIES.find(x => x.value === this.model.category);
        if (category) {
            return category.label;
        }

        return '';
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
