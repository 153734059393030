import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelService } from '../../../model.service';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { ModelDetailsViewModel, ModelDetailsEditModel } from 'src/app/models/model.model';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { IcrValidators } from 'src/app/shared/validators/icr.validators';
import { WarnOnLeaveFormComponent } from 'src/app/shared/warn-on-leave/warn-on-leave-form.component';
import { ModelStateManagementService } from '../../model-state-management.service';
import { ModelCategory } from '../../../model-category.enum';
import { MODEL_CATEGORIES } from 'src/app/core/constants';
import { MODEL_TYPES, ModelType } from 'src/app/core/model-type';
import { MODEL_REGIONS, ModelRegion } from 'src/app/core/model-region';
import { getLabel } from 'src/app/helpers/label-helper';
import { Roles } from 'src/app/core/roles';
import { MODEL_CLASSES } from 'src/app/core/model-style-class';
import { AppPermissions } from 'src/app/core/permissions';
import { AuthorizationService } from 'src/app/core/auth/authorization.service';
import { HttpErrorResponseHandler } from 'src/app/core/http-error-response.handler';
import { ResetFormService } from 'src/app/shared/guards/form-reset.service';
import { ModelValidators } from 'src/app/model-pages/model-name.validator';

@Component({
    selector: 'app-details-tab-edit',
    templateUrl: './details-tab-edit.component.html',
    styleUrls: ['./details-tab-edit.component.scss'],
})
export class DetailsTabEditComponent extends WarnOnLeaveFormComponent implements OnInit, OnDestroy {
    @ViewChild('form', { static: false }) form: NgForm;

    ModelCategory = ModelCategory;

    modelId: string;
    model: ModelDetailsViewModel;
    detailsForm: FormGroup;
    styleClasses = [];
    regions = MODEL_REGIONS;
    ModelType = ModelType;

    private subscription = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private modelService: ModelService,
        private messageBoxService: MessageBoxService,
        private fb: FormBuilder,
        private stateManagementService: ModelStateManagementService,
        private authorizationService: AuthorizationService,
        private httpErrorResponseHandler: HttpErrorResponseHandler,
        private resetFormService: ResetFormService,
    ) {
        super();
        this.stateManagementService.turnOnEditMode();
    }

    ngOnInit() {
        this.modelId = this.activatedRoute.parent.snapshot.params['id'];

        this.subscription.add(
            this.modelService.getModel(this.modelId).subscribe(
                model => {
                    this.model = model as ModelDetailsViewModel;
                    delete this.model.holdings;
                    this.setModelLabels(this.model);

                    this.buildDetailsForm();
                    this.detailsForm.patchValue(this.model);

                    if (this.model.type === ModelType.Holdings) {
                        this.enableRegionAndStyleClass();
                        this.updateStyleClassItems(this.model.category, this.model.region);
                    }
                },
                (error: HttpErrorResponse) =>
                    this.httpErrorResponseHandler.handleError(error, {
                        notFoundErrorMessageId: 'model-not-found',
                        genericErrorMessageId: 'model-details-fetch-failed',
                    }),
            ),
        );

        this.subscription.add(
            this.resetFormService.shouldResetSelfObservable.subscribe(
                () => this.detailsForm.reset(),
            ),
        );
    }

    buildDetailsForm() {
        if (this.model.type === ModelType.ModelOfModels) {
            this.detailsForm = this.fb.group(
                {
                    name: [
                        '',
                        IcrValidators.requiredNoWhitespace,
                        (control) => ModelValidators.nameUnique(this.modelService, control, this.model.name),
                    ],
                    benchmarkName: '',
                    description: '',
                },
                {
                    updateOn: 'blur',
                },
            );
        } else {
            this.detailsForm = this.fb.group(
                {
                    name: [
                        '',
                        IcrValidators.requiredNoWhitespace,
                        (control) => ModelValidators.nameUnique(this.modelService, control, this.model.name),
                    ],
                    benchmarkName: '',
                    performanceBenchmark:  [{ value: '', disabled: !this.canEditPerformanceBenchmark() }],
                    description: '',
                    statisticsId: '',
                    managerFee: [{ value: '', disabled: !this.canEditManagerFee() }],
                    region: [{ value: null, disabled: true }],
                    styleClass: [{ value: null, disabled: true }],
                },
                {
                    updateOn: 'blur',
                },
            );
        }
    }

    canEditStatisticsId() {
        return this.authorizationService.isRolePermitted(AppPermissions.EditStatisticsId);
    }

    saveDetailsEdits() {
        if (!this.detailsForm.valid) {
            this.detailsForm.markAllAsTouched();
            return;
        }

        this.model = {
            ...this.model,
            ...this.detailsForm.value,
        };

        this.subscription.add(
            this.modelService.updateModelDetails(this.modelDetailEditModel).subscribe(
                () => {
                    this.detailsForm.markAsPristine();
                    this.router.navigate(['../'], { relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
                    this.messageBoxService.success('model-details-save-success');
                    this.stateManagementService.updateModelDetails(this.model);
                },
                () => this.messageBoxService.error('model-details-save-failed'),
            ),
        );
    }

    fieldHasError(key: string, errorType: string) {
        const field = this.detailsForm.get(key);
        if (field) {
            return field.touched && field.hasError(errorType);
        }

        return false;
    }

    canEditRegionAndStyleClass() {
        return this.model.category !== ModelCategory.Other && this.authorizationService.roles.includes(Roles.ICRAdmin);
    }

    canEditManagerFee() {
        return this.authorizationService.roles.includes(Roles.ICRAdmin);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private canEditPerformanceBenchmark() {
        return this.authorizationService.isRolePermitted(AppPermissions.EditPerformanceBenchmark);
    }

    private enableRegionAndStyleClass() {
        if (!this.canEditRegionAndStyleClass()) {
            return;
        }

        const regionControl = this.detailsForm.get('region');
        const styleClassControl = this.detailsForm.get('styleClass');

        regionControl.enable();
        styleClassControl.enable();

        regionControl.valueChanges.subscribe((region: ModelRegion) => {
            this.updateStyleClassItems(this.model.category, region);
            styleClassControl.setValue(null);
        });
    }

    private setModelLabels(model: ModelDetailsViewModel) {
        model.categoryLabel = getLabel(model, 'category', MODEL_CATEGORIES);
        model.typeLabel = getLabel(model, 'type', MODEL_TYPES);
    }

    private updateStyleClassItems(category: ModelCategory, region: ModelRegion) {
        if (category === ModelCategory.Other) {
            return;
        }

        this.styleClasses = MODEL_CLASSES[category][region] || [];
    }

    get modelDetailEditModel(): ModelDetailsEditModel {
        const modelDetailsEditModel = new ModelDetailsEditModel();

        modelDetailsEditModel.id = this.model.id;
        modelDetailsEditModel.name = this.model.name.trim();
        modelDetailsEditModel.benchmarkName = this.model.benchmarkName;
        modelDetailsEditModel.performanceBenchmark = this.model.performanceBenchmark;
        modelDetailsEditModel.statisticsId = this.model.statisticsId;
        modelDetailsEditModel.managerFee = this.model.managerFee;
        modelDetailsEditModel.region = this.model.region;
        modelDetailsEditModel.styleClass = this.model.styleClass;
        modelDetailsEditModel.description = this.model.description;

        return modelDetailsEditModel;
    }
}
