import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserProfileComponent } from './user-profile.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { AccountFormComponent } from './account-form/account-form.component';
import { WarnOnLeaveGuard } from '../shared/guards/warn-on-leave.guard';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { AppPermissions } from '../core/permissions';

const userProfileRoutes: Routes = [
    {
        path: 'profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'account-details',
            },
            {
                path: 'account-details',
                component: AccountFormComponent,
                canDeactivate: [WarnOnLeaveGuard],
                data: {
                    permission: AppPermissions.ViewProfile,
                },
            },
            {
                path: 'security', component: PasswordChangeComponent,
                data: {
                    permission: AppPermissions.ViewProfile,
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(userProfileRoutes)],
    exports: [RouterModule],
})
export class UserProfileRoutingModule {}
