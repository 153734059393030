import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { addDashesToPhone } from 'src/app/shared/phoneFormatter';
import { AuthorizationService } from 'src/app/core/auth/authorization.service';
import { Roles, USER_ROLES } from 'src/app/core/roles';
import { User } from 'src/app/models/user.model';
import { UserService } from '../user.service';
import { FirmService } from '../firm.service';
import { AppPermissions } from 'src/app/core/permissions';

@Component({
    selector: 'app-list-users',
    templateUrl: './list-users.component.html',
    styleUrls: ['./list-users.component.scss'],
})
export class ListUsersComponent implements OnInit, OnDestroy {
    AppPermissions = AppPermissions;

    firmId: string;
    firmName: string;
    users: User[];
    backButtonRoute: string;
    canCreateUser = false;
    isTwoFactorAuthEnabled: boolean;
    isICRAdmin: boolean;

    private subscriptions: Subscription[] = [];

    constructor(
        private userService: UserService,
        private messageBoxService: MessageBoxService,
        private route: ActivatedRoute,
        private authorizationService: AuthorizationService,
        private firmService: FirmService,
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.firmId = params['firmId'];
            this.isICRAdmin = false;

            if(this.authorizationService.roles.find(r => r === Roles.ICRAdmin)){
                this.isICRAdmin = true;
            }

            this.backButtonRoute = this.isICRAdmin ? '../' : undefined;
            this.getUsers();
            this.check2FAuthIsEnabled();
        })
    }

    check2FAuthIsEnabled(){
        this.subscriptions.push(
            this.firmService.getFirm(this.firmId).subscribe(
                response => {
                    this.isTwoFactorAuthEnabled = response.isTwoFactorAuthEnabled;
                },
                () => {
                    this.isTwoFactorAuthEnabled = false;
                },
            ),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    getTwoFactorAuthLabel(){
        if(this.isTwoFactorAuthEnabled) {
            return 'Disable 2F';
        }

        return 'Enable 2F';
    }

    enableTwoFactorAuth() {
        this.subscriptions.push(
            this.userService.enable2F(this.firmId).subscribe(
                () => {
                    this.isTwoFactorAuthEnabled = true;
                    this.messageBoxService.success('enable2F-success');
                },
                () => {
                    this.isTwoFactorAuthEnabled = false;
                    this.messageBoxService.error('enable2F-fail');
                },
            ),
        );
    }

    disableTwoFactorAuth() {
        this.subscriptions.push(
            this.userService.disable2F(this.firmId).subscribe(
                () => {
                    this.isTwoFactorAuthEnabled = false;
                    this.messageBoxService.success('disable2F-success');
                },
                () => {
                    this.isTwoFactorAuthEnabled = true;
                    this.messageBoxService.error('disable2F-fail');
                },
            ),
        );
    }

    getUsers() {
        this.subscriptions.push(
            this.userService.getUsers(this.firmId).subscribe(
                userList => {
                    this.users = userList.users;
                    this.firmName = userList.firmName;
                    this.users.forEach(user => {
                        user.cellPhone = this.getFullPhone(user);
                    });

                    this.canCreateUser = true;
                },
                () => {
                    this.canCreateUser = false;
                    this.messageBoxService.error('user-list-error');
                },
            ),
        );
    }

    getFullPhone(user: User) {
        let fullPhone = addDashesToPhone(user.cellPhone);

        if (user.cellPhoneCountryCode && user.cellPhoneCountryCode.length > 0) {
            fullPhone = `+${user.workPhoneCountryCode} ${fullPhone}`;
        }

        return fullPhone;
    }

    getUserRolesAsString(roles: string[]) {
        if (!roles || roles.length === 0) {
            return '';
        }

        const roleNames = roles.map(
            role => USER_ROLES.find(r => r.value === role).label,
        );

        return roleNames.join(', ');
    }

    getStatusClass (status: string) {
        return '-' + status.toLowerCase();
    }
}
