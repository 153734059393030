import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { permissions } from 'src/app/permissions.config';
import { AppPermissions } from 'src/app/core/permissions';
import { Roles } from 'src/app/core/roles';
import { BasicIdentity } from './basic-identity';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService {
    constructor(private oauthService: OAuthService) { }

    get roles(): Roles[] {
        const claims = this.oauthService.getIdentityClaims() as BasicIdentity;
        if (!claims) {
            return null;
        }
        return (claims.role instanceof Array ? claims.role : [claims.role]) as Roles[];
    }

    isRolePermitted(permission: AppPermissions): boolean {
        const permissionRoles = permissions.find(permissionConfig => permissionConfig.permission === permission).roles;
        if (permissionRoles === '*') {
            return true;
        }

        const roles = this.roles;
        if (!roles) {
            return false;
        }

        return permissionRoles.some(role => roles.includes(role));
    }
}
