import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { apiUrl } from '../core/constants';
import { Firm } from '../models/firm.model';
import { FirmListItemViewModel } from 'src/app/models/firm.model';
import { map } from 'rxjs/operators';
import { removeSymbolsFromCountryCode, removeSymbolsFromPhone, addDashesToPhone } from '../shared/phoneFormatter';

@Injectable({
    providedIn: 'root',
})
export class FirmService {
    constructor(private http: HttpClient) {}

    public getFirm(firmId: string): Observable<Firm> {
        return this.http.get<Firm>(`${apiUrl}/api/v1/firm/${firmId}`).pipe(
            map(response => {
                response.businessPhone = addDashesToPhone(response.businessPhone);

                response.contacts.forEach(item => {
                    item.phone = addDashesToPhone(item.phone);
                });

                return response;
            }),
        );
    }

    public createFirm(firm: Firm): Observable<void> {
        return this.http.post<void>(`${apiUrl}/api/v1/firm`, this.phoneFieldRemoveSymbols(firm));
    }

    public saveFirm(firmId: string, firm: Firm): Observable<void> {
        return this.http.put<void>(`${apiUrl}/api/v1/firm/${firmId}`, this.phoneFieldRemoveSymbols(firm));
    }

    public getFirms(): Observable<FirmListItemViewModel[]> {
        return this.http.get<FirmListItemViewModel[]>(`${apiUrl}/api/v1/firm`);
    }

    public exists(firmName: string): Observable<boolean> {
        if (!firmName) {
            return of(false);
        }

        const params = new HttpParams({
            fromObject: {
                firmName: firmName,
            },
        })

        return this.http.get<boolean>(`${apiUrl}/api/v1/firm/exists`, { params: params });
    }

    private phoneFieldRemoveSymbols(firm: Firm): Firm {
        return {
            ...firm,
            businessPhoneCountryCode: removeSymbolsFromCountryCode(firm.businessPhoneCountryCode),
            businessPhone: removeSymbolsFromPhone(firm.businessPhone),
            contacts: firm.contacts.map(item => ({
                ...item,
                phoneCountryCode: removeSymbolsFromCountryCode(item.phoneCountryCode),
                phone: removeSymbolsFromPhone(item.phone),
            })),
        };
    }
}
