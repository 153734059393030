import { AbstractControl, ValidationErrors } from '@angular/forms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmptyOrWhitespace(value: any): boolean {
    return value === null || value.toString().trim().length === 0;
}

export class IcrValidators {
    static requiredNoWhitespace(control: AbstractControl): ValidationErrors | null {
        return isEmptyOrWhitespace(control.value) ? { 'required': true } : null;
    }

    static validNumber(control: AbstractControl): ValidationErrors | null {
        return Number(control.value) ? null : { 'validNumber': true };
    }
}
