import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { AppPermissions } from 'src/app/core/permissions';
import { ModelType } from 'src/app/core/model-type';

@Component({
    selector: 'app-model-details-navigation',
    templateUrl: './model-details-navigation.component.html',
    styleUrls: ['./model-details-navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelDetailsNavigationComponent implements OnInit {
    AppPermissions = AppPermissions;

    @Input() modelId: string;
    @Input() modelEditable: boolean;
    @Input() canViewHistory: boolean;
    @Input() modelType: ModelType;

    constructor() {}

    ngOnInit() {}

    getSecondTabTitle() {
        return this.modelType === ModelType.ModelOfModels
            ? 'Model Sleeves'
            : 'Model Holdings';
    }
}
