import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirmService } from './firm.service';

export class FirmValidators {
    static nameUnique(
        firmService: FirmService,
        { value }: AbstractControl,
        originalName: string): Observable<ValidationErrors | null> {

        const normalizedValue = (value as string).toLowerCase().trim();
        const normalizedOriginalName = originalName && originalName.toLowerCase().trim();

        if (normalizedOriginalName === normalizedValue) {
            return of(null);
        }

        return firmService.exists(normalizedValue).pipe(
            map((exists: boolean) => {
                if (exists) {
                    return {
                        alreadyExists: true,
                    };
                }
                return null;
            }),
        );
    }
}
