import { AppPermissions } from './core/permissions';
import { Roles } from './core/roles';

class RolePermission {
    permission: AppPermissions;
    roles: Roles[] | '*';
}

declare type RolePermissions = RolePermission[];

export const permissions: RolePermissions = [
    {
        permission: AppPermissions.ViewProfile,
        roles: '*',
    },
    {
        permission: AppPermissions.CreateUser,
        roles: [
            Roles.ICRAdmin,
            Roles.FirmAdmin,
        ],
    },
    {
        permission: AppPermissions.EditUser,
        roles: [
            Roles.ICRAdmin,
            Roles.FirmAdmin,
        ],
    },
    {
        permission: AppPermissions.CrudFirm,
        roles: [
            Roles.ICRAdmin,
        ],
    },
    {
        permission: AppPermissions.ViewFirms,
        roles: [
            Roles.ICRAdmin,
        ],
    },
    {
        permission: AppPermissions.ViewFirmDetails,
        roles: [Roles.ICRAdmin, Roles.FirmAdmin],
    },
    {
        permission: AppPermissions.ViewModels,
        roles: [
            Roles.ICRAdmin,
            Roles.AssetManager,
            Roles.FirmAdmin,
            Roles.SeniorPortfolioManager,
            Roles.Compliance,
            Roles.PortfolioManager,
            Roles.Research,
        ],
    },
    {
        permission: AppPermissions.CreateModel,
        roles: [
            Roles.AssetManager,
            Roles.SeniorPortfolioManager,
            Roles.PortfolioManager,
            Roles.FirmAdmin,
            Roles.ICRAdmin,
        ],
    },
    {
        permission: AppPermissions.EditAnyFirmModel,
        roles: [
            Roles.ICRAdmin,
        ],
    },
    {
        permission: AppPermissions.EditOwnFirmModel,
        roles: [
            Roles.PortfolioManager,
            Roles.SeniorPortfolioManager,
            Roles.AssetManager,
            Roles.FirmAdmin,
            Roles.ICRAdmin,
        ],
    },
    {
        permission: AppPermissions.AuthorizeModel,
        roles: [
            Roles.Compliance,
        ],
    },
    {
        permission: AppPermissions.ApproveModel,
        roles: [
            Roles.ICRAdmin,
        ],
    },
    {
        permission: AppPermissions.EditStatisticsId,
        roles: [
            Roles.ICRAdmin,
        ],
    },
    {
        permission: AppPermissions.DeleteResearchFile,
        roles: [
            Roles.ICRAdmin,
        ],
    },
    {
        permission: AppPermissions.UploadStatistics,
        roles: [
            Roles.ICRAdmin,
        ],
    },
    {
        permission: AppPermissions.UploadHoldings,
        roles: [
            Roles.ICRAdmin,
            Roles.AssetManager,
        ],
    },
    {
        permission: AppPermissions.EditPerformanceBenchmark,
        roles: [
            Roles.ICRAdmin,
        ],
    },
    {
        permission: AppPermissions.CreateModelOfModels,
        roles: [
            Roles.PortfolioManager,
            Roles.SeniorPortfolioManager,
            Roles.FirmAdmin,
        ],
    },
];
