import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, FormControl }
    from '@angular/forms';
import { IcrValidators } from 'src/app/shared/validators/icr.validators';
import { Observable, Subscription } from 'rxjs';
import { Firm } from 'src/app/models/firm.model';
import { UserFormNotificationService } from './user-form-notification.service';

export interface UserFormValues {
    firstName: string;
    lastName: string;
    workPhone: number;
    workPhoneCountryCode: string;
    workPhoneExtension: number;
    cellPhone: number;
    cellPhoneCountryCode: string;
    email: string;
    roles: string[];
}

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // https://angular.io/api/core/forwardRef - Allows to refer to references which are not yet defined.
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => UserFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => UserFormComponent),
            multi: true,
        },
    ],
})

export class UserFormComponent implements OnInit, ControlValueAccessor {

    @Input() firmObservable: Observable<Firm>;

    form: FormGroup;

    private subscription = new Subscription();

    get value(): UserFormValues {
        return this.form.value;
    }

    set value(value: UserFormValues) {
        this.form.patchValue(value);
        this.onChange(value);
        this.onTouched();
    }

    constructor(
        private fb: FormBuilder,
        private userFormService: UserFormNotificationService,
    ) {  }

    ngOnInit() {
        this.form = this.fb.group(
            {
                firstName: ['', IcrValidators.requiredNoWhitespace],
                lastName: ['', IcrValidators.requiredNoWhitespace],
                workPhone: '',
                workPhoneCountryCode: '1',
                workPhoneExtension: '',
                cellPhone: ['', [Validators.required, Validators.minLength(12)]],
                cellPhoneCountryCode: '1',
                email: ['', [Validators.email, Validators.required]],
                roles: [null, Validators.required],
            },
            {
                updateOn: 'blur',
            },
        );

        this.subscription.add(
            this.form.valueChanges.subscribe(value => {
                this.onChange(value);
                this.onTouched();
            }),
        );

        this.subscribeToParentOnSubmit();
    }

    subscribeToParentOnSubmit() {
        this.subscription.add(
            this.userFormService.formStatusSubmitted.subscribe(
                () => {
                    this.form.markAllAsTouched();
                },
            ),
        );
        this.form.markAsUntouched();
    }

    onChange = (_: UserFormValues) => {};
    onTouched = () => {};

    fieldHasError(key: string, errorType: string) {
        const field = this.form.get(key);
        if (field) {
            return field.touched && field.hasError(errorType);
        }

        return false;
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    writeValue(value) {
        if (value) {
            this.value = value;
        }

        if (value === null) {
            this.form.reset();
        }
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { userInformation: { valid: false } };
    }
}
