import { SleeveModel } from 'src/app/models/model.model';
import { MODEL_CATEGORIES } from 'src/app/core/constants';
import { MODEL_REGIONS } from 'src/app/core/model-region';
import { MODEL_CLASSES_ALL } from 'src/app/core/model-style-class';

function getCategoryLabel(value: string) {
    const category = value && MODEL_CATEGORIES.find(x => x.value === value);
    return category && category.label || value;
}

function getRegionLabel(value: string) {
    const region = value && MODEL_REGIONS.find(x => x.value === value);
    return region && region.label || value;
}

function getClassLabel(value: string) {
    const modelClass = value && MODEL_CLASSES_ALL.find(x => x.value === value);
    return modelClass && modelClass.label || value;
}

export function assetClassLabel(model: SleeveModel) {
    return [
        getCategoryLabel(model.category),
        getRegionLabel(model.region),
        getClassLabel(model.styleClass),
    ]
        .filter(t => t)
        .join(', ')
}
