import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ModelResearchFile } from 'src/app/models/model-research-file.model';
import { AppPermissions } from 'src/app/core/permissions';

@Component({
    selector: 'app-research-item-file',
    templateUrl: './research-item-file.component.html',
    styleUrls: ['./research-item-file.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResearchItemFileComponent implements OnInit {
    @Input() file: ModelResearchFile;
    @Output() download = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();

    AppPermissions = AppPermissions;

    constructor() { }

    ngOnInit() {

    }

    downloadFile() {
        this.download.emit();
    }

    deleteFile() {
        this.delete.emit();
    }
}
