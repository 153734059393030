export enum FirmType {
    AssetManager = 'AssetManager',
    Client = 'Client',
    IcrPartners = 'IcrPartners',
}

export const FIRM_TYPES = [
    { label: 'Asset Manager', value: FirmType.AssetManager },
    { label: 'Client', value: FirmType.Client },
    { label: 'ICR Partners', value: FirmType.IcrPartners },
];
