import { UserContext } from './../core/user-context';
import { FirmLogo } from '../core/firmLogo';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProfileService } from '../user-profile/profile.service';
import { AuthenticationService } from '../core/auth/authentication.service';
import { AuthorizationService } from '../core/auth/authorization.service';
import { AppPermissions } from '../core/permissions';
import { Subscription } from 'rxjs';
import { Roles } from '../core/roles';
import { filter } from 'rxjs/operators';
import { UserService } from '../admin/user.service';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    AppPermissions = AppPermissions;

    isLoggedIn = false;
    firstName = 'N/A';
    adminRoute: string;
    adminRoutePermission: string;
    isLoaded = false;
    headerLogo: string;
    firmLogo: FirmLogo;
    localStorage: Storage;
    private subscription = new Subscription();
    genericLogo = 'assets/icons/icr.svg';

    constructor(
        private authorizationService: AuthorizationService,
        private authenticationService: AuthenticationService,
        private profileService: ProfileService,
        private userService: UserService,
    ) { }

    ngOnInit() {
        this.subscription.add(this.profileService.profileUpdated.subscribe((profile) => {
            this.firstName = profile.firstName;
        }));

        this.subscription.add(
            this.authenticationService.isDoneLoading$
                .pipe(filter(isDone => isDone))
                .subscribe(() => {
                    this.isLoggedIn = this.authenticationService.isLoggedIn();
                    this.firstName = this.authenticationService.name;
                    this.authenticationService.userContext.subscribe((userContext: UserContext) => {
                        const localStorageData = JSON.parse(localStorage.getItem('firmLogo'));
                        if(localStorageData){
                            if(localStorageData.firmId === userContext.firmId){
                                this.headerLogo = localStorageData.logoPath;
                            }else{
                                this.headerLogo = this.genericLogo;
                            }
                        }else{
                            this.userService.getFirmLogoByFirmId(userContext.userId, userContext.firmId)
                                .subscribe(firmLogos => {
                                    this.firmLogo = firmLogos;
                                    localStorage.setItem('firmLogo', JSON.stringify(firmLogos));
                                    if(this.firmLogo){
                                        this.headerLogo = this.firmLogo.logoPath;
                                    }else{
                                        this.headerLogo = this.genericLogo;
                                    }
                                })
                        }
                    });


                    if (this.isLoggedIn) {
                        const superAdmin = this.authorizationService.roles
                        && this.authorizationService.roles.includes(Roles.ICRAdmin);

                        this.adminRoutePermission = superAdmin ? AppPermissions.CrudFirm
                            : AppPermissions.ViewFirmDetails;

                        if (superAdmin) {
                            this.adminRoute = '/admin/firms';
                            this.isLoaded = true;
                        } else {
                            this.subscription.add(
                                this.authenticationService.userContext.subscribe((userContext: UserContext) => {
                                    this.adminRoute = `/admin/firms/${userContext.firmId}`;
                                    this.isLoaded = true;
                                }));
                        }
                    }
                }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
