export enum ModelStatus {
    Draft = 'Draft',
    Approved = 'Approved',
    Authorized = 'Authorized',
    Internal = 'Internal',
    PendingApproval = 'PendingApproval',
    Deactivated = 'Deactivated',
    PendingAuthorization = 'PendingAuthorization',
}

export const MODEL_STATUSES = [
    { value: ModelStatus.Approved, label: 'Approved' },
    { value: ModelStatus.PendingApproval, label: 'Pending Approval' },
    { value: ModelStatus.Authorized, label: 'Authorized' },
    { value: ModelStatus.Internal, label: 'Internal' },
    { value: ModelStatus.Draft, label: 'Draft' },
    { value: ModelStatus.Deactivated, label: 'Deactivated' },
    { value: ModelStatus.Draft, label: 'Draft' },
    { value: ModelStatus.Deactivated, label: 'Deactivated' },
    { value: ModelStatus.PendingAuthorization, label: 'Pending Authorization' },
]
