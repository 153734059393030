import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const LOADING_MESSAGE = 'Loading...';

@Injectable({
    providedIn: 'root',
})
export class LoadingScreenService {

    private activeRequests = 0;

    private manualLoadingStatus = new BehaviorSubject(false);
    manualLoadingStatusObservable = this.manualLoadingStatus.asObservable();

    private loadingStatusFromInterceptor = new BehaviorSubject(false);
    loadingStatusFromInterceptorObservable = this.loadingStatusFromInterceptor.asObservable();

    private loadingMessage = new BehaviorSubject(LOADING_MESSAGE);
    loadingMessageObservable = this.loadingMessage.asObservable();

    showLoader(message: string) {
        if (this.activeRequests === 0) {
            this.loadingMessage.next(message || LOADING_MESSAGE);
            this.manualLoadingStatus.next(true);
        }

        this.activeRequests += 1;
    }

    // showLoader() for intercepted requests
    showLoaderForHttpRequest() {
        if (this.activeRequests === 0) {
            if (this.loadingMessage.getValue() !== LOADING_MESSAGE) {
                this.loadingMessage.next(LOADING_MESSAGE);
            }
            this.loadingStatusFromInterceptor.next(true);
        }

        this.activeRequests += 1;
    }

    hideLoader() {
        if (this.activeRequests > 0) {
            this.activeRequests -= 1;
        }

        if (this.activeRequests === 0) {
            this.manualLoadingStatus.next(false);
            this.loadingStatusFromInterceptor.next(false);
        }
    }
}
