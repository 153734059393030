import { Subject } from 'rxjs';
import { Options, ChangeContext, PointerType } from 'ng5-slider';

export class ModelSliderFilterBase {
    DEFAULT_FROM_VALUE: number;
    DEFAULT_TO_VALUE: number;

    from: string;
    to: string;
    fromValue: number;
    toValue: number;
    valueFromChange = new Subject<string>();
    valueToChange = new Subject<string>();
    sliderOptions: Options;

    public sliderChange(changeContext: ChangeContext): void {
        if (changeContext.pointerType === PointerType.Min) {
            const filterValue =
                changeContext.value === this.sliderOptions.floor ? undefined : changeContext.value.toFixed(1);
            this.valueFromChange.next(filterValue);
        } else if (changeContext.pointerType === PointerType.Max) {
            const filterValue =
                changeContext.highValue === this.sliderOptions.ceil ? undefined : changeContext.highValue.toFixed(1);
            this.valueToChange.next(filterValue);
        }
    }

    protected setFromValue(from: string, fromValue: number) {
        this.from = from;
        this.adjustSliderFloor(fromValue);
        this.fromValue = fromValue ? fromValue : this.sliderOptions.floor;
    }

    protected setToValue(to: string, toValue: number) {
        this.to = to;
        this.adjustSliderCeil(toValue);
        this.toValue = toValue ? toValue : this.sliderOptions.ceil;
    }

    private adjustSliderFloor(newFromValue: number): void {
        if (newFromValue === undefined) {
            this.applyNewSliderFloor(this.DEFAULT_FROM_VALUE);
            return;
        }

        if (newFromValue && newFromValue < this.sliderOptions.floor) {
            this.applyNewSliderFloor(newFromValue);
        }
    }

    private adjustSliderCeil(newToValue: number): void {
        if (newToValue === undefined) {
            this.applyNewSliderCeil(this.DEFAULT_TO_VALUE);
            return;
        }

        if (newToValue && newToValue > this.sliderOptions.ceil) {
            this.applyNewSliderCeil(newToValue);
        }
    }

    private applyNewSliderFloor(newFloor: number) {
        this.sliderOptions = { ...this.sliderOptions, floor: newFloor };
    }

    private applyNewSliderCeil(newCeil: number) {
        this.sliderOptions = { ...this.sliderOptions, ceil: newCeil };
    }
}
