import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CreateModelComponent } from './create-model/create-model.component';
import { ModelRoutingModule } from './model-routing.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadModelComponent } from './upload-model/upload-model.component';
import {
    EquityDynamicHoldingComponent,
} from './model-holdings/equity-dynamic-holding/equity-dynamic-holding.component';
import { ModelHoldingsComponent } from './model-holdings/model-holdings.component';
import { ModelSubmittedComponent } from './model-submitted/model-submitted.component';
import { PercentageBarComponent } from './shared-components/percentage-bar/percentage-bar.component';
import { ReviewModelComponent } from './review-model/review-model.component';
import {
    EquityDynamicReviewComponent,
} from './review-model/equity-dynamic-review/equity-dynamic-review.component';
import {
    FixedIncomeDynamicReviewComponent,
} from './review-model/fixed-income-dynamic-review/fixed-income-dynamic-review.component';
import {
    FixedIncomeDynamicHoldingComponent,
} from './model-holdings/fixed-income-dynamic-holding/fixed-income-dynamic-holding.component';
import { ModelListComponent } from './model-list/model-list.component';
import { ModelTableComponent } from './model-list/table/model-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModelDetailComponent } from './model-detail/model-detail.component';
import { ModelDetailsNavigationComponent }
    from './model-detail/navigation/model-details-navigation.component';
import { DetailsTabViewComponent } from './model-detail/details-tab/view/details-tab-view.component';
import { ModelDetailHoldingsComponent } from './model-detail/holdings-tab/view/model-detail-holdings.component';
import { HistoryTabViewComponent } from './model-detail/history-tab/view/history-tab-view.component';
import { ModelPagesComponent } from './model-pages.component';
import { DetailsTabEditComponent } from './model-detail/details-tab/edit/details-tab-edit.component';
import { ModelDescriptionPlaceholderComponent } from
    './shared-components/model-description-placeholder/model-description-placeholder.component';
import { ModelDetailHoldingsEditComponent } from
    './model-detail/holdings-tab/edit/model-detail-holdings-edit.component';
import { EquityDynamicHoldingQtyEditComponent }
    from './model-detail/holdings-tab/edit/equity-dynamic-qty-edit/equity-dynamic-holding-qty-edit.component';
import { FixedDynamicHoldingQtyEditComponent }
    from './model-detail/holdings-tab/edit/fixed-dynamic-qty-edit/fixed-dynamic-holding-qty-edit.component';
import { OtherBlendedHoldingQtyEditComponent }
    from './model-detail/holdings-tab/edit/other-blended-holding-qty-edit/other-blended-holding-qty-edit.component';
import { ModelStatisticPlaceholderComponent } from
    './shared-components/model-statistic-placeholder/model-statistic-placeholder.component';
import { ModelDescriptionComponent } from
    './model-detail/details-tab/view/model-description/model-description.component';
import { ModelStatisticsComponent } from './model-detail/details-tab/view/model-statistics/model-statistics.component';
import { ModelResearchesComponent } from './model-detail/details-tab/view/model-researches/model-researches.component';
import { OtherBlendedHoldingsComponent }
    from './model-holdings/other-blended-holdings/other-blended-holdings.component';
import { EquityTableViewComponent } from './shared-components/equity-table-view/equity-table-view.component';
import { FixedIncomeTableViewComponent }
    from './shared-components/fixed-income-table-view/fixed-income-table-view.component';
import { OtherHoldingsTableViewComponent }
    from './shared-components/other-holdings-table-view/other-holdings-table-view.component';
import { ResearchItemComponent }
    from './model-detail/details-tab/view/model-researches/research-item/research-item.component';
import { NgxFilesizeModule } from 'ngx-filesize';
import { FilterLabelComponent } from './model-list/filter-label/filter-label.component';
import { ModelAppliedFiltersComponent } from './model-list/applied-filters/model-applied-filters.component';
import { ModelFiltersComponent } from './model-list/filter-bar/model-filters.component';
import { ModelFilterAssetClassComponent } from './model-list/filters/asset-class/model-filter.asset-class.component';
import { ModelFilterManagerFeeComponent } from './model-list/filters/manager-fee/model-filter.manager-fee.component';
import { ModelFilterSharpeRatioComponent } from './model-list/filters/sharpe-ratio/model-filter.sharpe-ratio.component';
import { ModelFilterAlphaComponent } from './model-list/filters/alpha/model-filter.alpha.component';
import { ModelFilterRSquaredComponent } from './model-list/filters/rsquared/model-filter.rsquared.component';
import { ModelFilterBetaComponent } from './model-list/filters/beta/model-filter.beta.component';
import { ModelFilterStyleClassComponent } from './model-list/filters/style-class/model-filter.style-class.component';
import { ModelFilterStyleClassMultiComponent }
    from './model-list/filters/style-class-multi/model-filter.style-class-multi.component';
import { ModelFilterDropdownComponent } from './model-list/filters/dropdown/model-filter.dropdown.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { ResearchItemFileComponent }
    from './model-detail/details-tab/view/model-researches/research-item/file/research-item-file.component';
import { UploadModelHoldingsButtonComponent }
    from './shared-components/upload-model-holdings-button/upload-model-holdings-button.component';
import { UploadModelStatisticsButtonComponent }
    from '../model-pages/model-list/table/upload-model-statistics-button/upload-model-statistics-button.component';
import { FileSaverModule } from 'ngx-filesaver';
import { Papa } from 'ngx-papaparse';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng5SliderModule } from 'ng5-slider';
import { TextMaskModule } from 'angular2-text-mask';
import { ModelSleevesComponent } from './model-holdings/model-sleeves/model-sleeves.component';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { ModelOfModelsDescriptionComponent }
    from './model-detail/details-tab/view/model-of-models-description/model-of-models-description.component';
import { ModelSleeveEditComponent }
    from './shared-components/sleeve-models/model-sleeve-edit/model-sleeve-edit.component';
import { SleeveModelsTableViewComponent }
    from './shared-components/sleeve-models/sleeve-models-table-view/sleeve-models-table-view.component';
import { SleeveModelsReviewComponent }
    from './review-model/sleeve-models-review/sleeve-models-review.component';
import { ModelSleevesQtyEditComponent }
    from './model-detail/holdings-tab/edit/model-sleeves-qty-edit/model-sleeves-qty-edit.component';

@NgModule({
    declarations: [
        ModelPagesComponent,
        CreateModelComponent,
        UploadModelComponent,
        ModelSubmittedComponent,
        EquityDynamicHoldingComponent,
        ModelHoldingsComponent,
        PercentageBarComponent,
        ReviewModelComponent,
        EquityDynamicReviewComponent,
        FixedIncomeDynamicReviewComponent,
        FixedIncomeDynamicHoldingComponent,
        ModelListComponent,
        ModelTableComponent,
        ModelDetailComponent,
        ModelDetailsNavigationComponent,
        DetailsTabViewComponent,
        ModelDetailHoldingsComponent,
        HistoryTabViewComponent,
        DetailsTabEditComponent,
        ModelDescriptionPlaceholderComponent,
        ModelDetailHoldingsEditComponent,
        EquityDynamicHoldingQtyEditComponent,
        FixedDynamicHoldingQtyEditComponent,
        OtherBlendedHoldingQtyEditComponent,
        ModelStatisticPlaceholderComponent,
        ModelDescriptionComponent,
        ModelStatisticsComponent,
        ModelResearchesComponent,
        OtherBlendedHoldingsComponent,
        EquityTableViewComponent,
        FixedIncomeTableViewComponent,
        OtherHoldingsTableViewComponent,
        ResearchItemComponent,
        FilterLabelComponent,
        ModelAppliedFiltersComponent,
        ModelFiltersComponent,
        ResearchItemFileComponent,
        UploadModelStatisticsButtonComponent,
        UploadModelHoldingsButtonComponent,
        ModelFilterAssetClassComponent,
        ModelFilterStyleClassComponent,
        ModelFilterStyleClassMultiComponent,
        ModelFilterManagerFeeComponent,
        ModelFilterSharpeRatioComponent,
        ModelFilterAlphaComponent,
        ModelFilterRSquaredComponent,
        ModelFilterBetaComponent,
        ModelFilterDropdownComponent,
        ModelSleevesComponent,
        ModelSleeveEditComponent,
        ModelOfModelsDescriptionComponent,
        SleeveModelsReviewComponent,
        SleeveModelsTableViewComponent,
        ModelSleevesQtyEditComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        ModelRoutingModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        AngularSvgIconModule,
        NgxDatatableModule,
        NgxFilesizeModule,
        ClickOutsideModule,
        FileSaverModule,
        NgSelectModule,
        NgOptionHighlightModule,
        Ng5SliderModule,
        TextMaskModule,
    ],
    providers: [Papa],
})
export class ModelPagesModule { }
