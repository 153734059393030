import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-select-box',
    templateUrl: './select-box.component.html',
    styleUrls: ['./select-box.component.scss'],
})
export class SelectBoxComponent implements OnInit {
    ngOnInit() {
    }

    selectItems: { value: string; label: string }[] = [];
    @Input() parentForm: FormGroup;
    @Input() label: string = null;
    @Input() formValidationName: string;
    @Input() allowDeselect = true;
    @Input() id: string = null;

    @Input() set items(value: { value: string; label: string }[]) {
        if (this.allowDeselect) {
            this.selectItems = [{ value: null, label: 'Select' }].concat(value);
        } else {
            this.selectItems = value;
        }
    };
}
