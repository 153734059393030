import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Output,
    EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs';
import * as filesize from 'filesize';
import { ModelResearchItem } from 'src/app/models/model-research-item.model';
import { ResearchType } from 'src/app/core/research-type';
import { RESEARCH_CATEGORIES } from 'src/app/core/research-category';
import { getLabel } from 'src/app/helpers/label-helper';
import { ModelResearchService } from '../../model-research.service';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { ModelAuthorizationService } from '../../../../../model-authorization.service';
import { ModelResearchFile } from 'src/app/models/model-research-file.model';
import { LoadingScreenService } from 'src/app/shared/loader/loading-screen.service';
import { FileSaverService } from 'ngx-filesaver';
import { finalize, filter } from 'rxjs/operators';
import { ResearchStateManagementService } from './research-state-management-service';

@Component({
    selector: 'app-research-item',
    templateUrl: './research-item.component.html',
    styleUrls: ['./research-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResearchItemComponent implements OnInit, OnDestroy {
    @Input() research: ModelResearchItem;
    @Output() lastFileDeleted = new EventEmitter();

    ResearchType = ResearchType;
    filesize = filesize;

    expanded = false;
    files: ModelResearchFile[] = [];

    private subscription = new Subscription();

    constructor(
        private modelResearchService: ModelResearchService,
        private messageBoxService: MessageBoxService,
        private changeDetectorRef: ChangeDetectorRef,
        private modelAuthorizationService: ModelAuthorizationService,
        private loadingScreenService: LoadingScreenService,
        private fileSaverService: FileSaverService,
        private researchStateManagement: ResearchStateManagementService,
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.researchStateManagement.researchUpdated
                .pipe(filter(research => research.id === this.research.id))
                .subscribe(research => {
                    this.research = research;
                    this.changeDetectorRef.markForCheck();
                }),
        );
        this.subscription.add(
            this.researchStateManagement.filesUpdated
                .pipe(filter(files => files && files.length !== 0 &&
                    files[0].modelResearchId === this.research.id))
                .subscribe(files => {
                    if (!this.expanded) {
                        return;
                    }

                    this.files = files;
                    this.changeDetectorRef.markForCheck();
                }),
        );

        this.subscription.add(
            this.researchStateManagement.filesNeedRefresh
                .pipe(filter(researchId => researchId === this.research.id))
                .subscribe(() => {
                    if (this.expanded) {
                        this.getFiles();
                    }
                }),
        );
    }

    getCategoryLabel(): string {
        return getLabel(this.research, 'category', RESEARCH_CATEGORIES);
    }

    uploadResearchFile(event): void {
        const fileList: FileList = event.target.files;
        const fileToUpload = fileList[0];
        if (this.modelResearchService.fileExceedsSizeLimit(fileToUpload)) {
            this.messageBoxService.error('model-research-file-too-large-error');
            return;
        }

        this.loadingScreenService.showLoader('Uploading research...');
        this.subscription.add(
            this.modelResearchService
                .addResearchFile(this.research.modelId, this.research.id, fileToUpload)
                .pipe(finalize(() => this.loadingScreenService.hideLoader()))
                .subscribe(
                    addedFile => {
                        this.messageBoxService.success('model-research-upload-success');
                        this.research.latestFile = addedFile;
                        this.researchStateManagement.researchDetailsUpdated(this.research);
                        if (this.expanded) {
                            this.files.unshift(addedFile);
                            this.researchStateManagement.researchFilesUpdated(this.files);
                        } else {
                            this.researchStateManagement.researchFilesNeedRefresh(this.research.id);
                        }
                        this.changeDetectorRef.markForCheck();
                    },
                    errResponse => {
                        if (errResponse && errResponse.error === 'File exceeds size limit.') {
                            this.messageBoxService.error('model-research-file-too-large-error');
                        } else {
                            this.messageBoxService.error('model-research-upload-error');
                        }
                    },
                ),
        );
    }

    expand() {
        this.getFiles();
    }

    collapse() {
        this.expanded = false;
    }

    downloadResearchFile(fileId: string, filename: string) {
        this.subscription.add(
            this.modelResearchService.downloadFile(this.research.modelId, this.research.id, fileId).subscribe(
                file => {
                    this.fileSaverService.save(file, filename);
                },
                () => this.messageBoxService.error('model-research-file-download-failed'),
            ),
        );
    }

    deleteResearchFile(fileId: string) {
        this.subscription.add(
            this.modelResearchService.deleteFile(this.research.modelId, this.research.id, fileId).subscribe(
                () => {
                    this.files = this.files.filter(x => x.id !== fileId);
                    if (this.files.length < 1) {
                        this.lastFileDeleted.emit();
                    } else if (this.research.latestFile.id === fileId) {
                        this.research.latestFile = this.files[0];
                        this.researchStateManagement.researchDetailsUpdated(this.research);
                    }
                    this.researchStateManagement.researchFilesUpdated(this.files);

                    this.changeDetectorRef.markForCheck();

                    this.messageBoxService.success('model-research-file-deleted');
                },
                () => this.messageBoxService.error('model-research-file-delete-error'),
            ),
        );
    }

    canAddResearch(): boolean {
        return this.modelAuthorizationService.canAddResearch();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // improves performance of *ngFor
    trackFilesBy(_: number, item: ModelResearchFile) {
        return item.id;
    }

    private getFiles() {
        this.subscription.add(
            this.modelResearchService.getFiles(this.research.modelId, this.research.id).subscribe(
                files => {
                    this.files = files;
                    this.expanded = true;
                    this.changeDetectorRef.markForCheck();
                },
                () => this.messageBoxService.error('model-research-files-fetch-failed'),
            ),
        );
    }
}
