import { Injectable } from '@angular/core';

export interface MessageBox {
    open: () => void;
    close: () => void;
    id: string;
}

@Injectable({
    providedIn: 'root',
})
export class MessageBoxService {
    private modals: MessageBox[] = [];

    private getModalIndex(id: string) {
        return this.modals.findIndex(modal => modal.id === id);
    }

    add(modal: MessageBox) {
        this.modals.push(modal);
    }

    remove(id: string) {
        this.modals.splice(this.getModalIndex(id), 1);
    }

    open(id: string, closeAfterMilliSeconds: number = null) {
        this.modals.forEach(modal => {
            if (modal.id === id) {
                modal.open();
                if (closeAfterMilliSeconds) {
                    this.close(id, closeAfterMilliSeconds);
                }
            } else {
                modal.close();
            }
        });
    }

    success(id) {
        return this.open(id, 3000);
    }

    error(id) {
        return this.open(id);
    }

    private close(id: string, timeoutMilliseconds = 0) {
        setTimeout(() => {
            const modalIndex = this.getModalIndex(id);
            if (modalIndex !== -1) {
                this.modals[modalIndex].close();
            }
        }, timeoutMilliseconds);
    }
}
