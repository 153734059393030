import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ModelStatistics } from 'src/app/models/model.model';
import { ModelDetailsViewModel } from 'src/app/models/model.model';

@Component({
    selector: 'app-model-statistics',
    templateUrl: './model-statistics.component.html',
    styleUrls: ['./model-statistics.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelStatisticsComponent implements OnInit {

    @Input() modelStatistics: ModelStatistics;
    @Input() model: ModelDetailsViewModel;

    constructor() { }

    ngOnInit() {
    }

}
