import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export class RegexpValidations {
    private static hasExpression(regexp: string, controlValue: string) {
        const value: string = controlValue;
        if (value === undefined || value === null) {
            return false;
        }
        return new RegExp(regexp).test(value);
    }

    static singleDigit(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (this.hasExpression('[0-9]', control.value as string)) {
                return null;
            }

            return {
                singleDigit: true,
            };
        };
    }

    static singleLetter(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (this.hasExpression('[a-zA-Z]', control.value as string)) {
                return null;
            }

            return {
                singleLetter: true,
            };
        };
    }

    static singleSpecialChar(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (
                this.hasExpression(
                    '[ !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]',
                    control.value as string,
                )
            ) {
                return null;
            }

            return {
                singleSpecialChar: true,
            };
        };
    }

    static moreThan2IdenticalChars(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (this.hasExpression('(.)\\1\\1', control.value as string)) {
                return {
                    moreThan2IdenticalChars: true,
                };
            }

            return null;
        };
    }
}
