import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ModelStatus, MODEL_STATUSES } from 'src/app/core/model-status';

@Component({
    selector: 'app-status-label',
    templateUrl: './status-label.component.html',
    styleUrls: ['./status-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusLabelComponent implements OnInit {
    constructor() {}

    ngOnInit() {}

    @Input() status: ModelStatus;

    getStatusLabel(value: ModelStatus) {
        const modelStatus = MODEL_STATUSES.find(x => x.value === value.trim());
        if (modelStatus) {
            return modelStatus.label;
        }
        return value;
    }

    getStatusClass (value: ModelStatus) {
        return '-' + value.toString().toLowerCase();
    }
}
