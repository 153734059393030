import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MODEL_HOLDING_CLASSES } from 'src/app/core/constants';

@Component({
    selector: 'app-fixed-income-dynamic-holding',
    templateUrl: './fixed-income-dynamic-holding.component.html',
    styleUrls: ['./fixed-income-dynamic-holding.component.scss', '../column-sizes.edit.scss'],
})
export class FixedIncomeDynamicHoldingComponent implements OnInit {

    @Input() positions = [];
    @Input() holdingForm: FormGroup;
    @Input() index: number = null;
    @Output() onDelete = new EventEmitter();

    modelClasses = MODEL_HOLDING_CLASSES;


    constructor() { }

    ngOnInit() {
        this.positions = ['B', 'H'];
    }

    fieldHasError(key: string, errorType: string) {
        const field = this.holdingForm.get(key);

        if (field) {
            return field.touched && field.hasError(errorType);
        }

        return false;
    }

    deleteSelf() {
        this.onDelete.emit();
    }
}
