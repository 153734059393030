import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ColumnMode, DatatableComponent  } from '@swimlane/ngx-datatable';
import { merge, fromEvent, animationFrameScheduler } from 'rxjs';
import { startWith, observeOn } from 'rxjs/operators';

import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { MODEL_STATUSES } from 'src/app/core/model-status';
import { MODEL_CATEGORIES } from 'src/app/core/constants';
import { MODEL_CLASSES_ALL } from 'src/app/core/model-style-class';
import { MODEL_REGIONS } from 'src/app/core/model-region';
import {
    ModelHistory,
    ModelHistoryItem,
    ModelHistoryFieldInfo,
    ModelHistoryValue,
    ModelHistoryViewEntry,
} from 'src/app/models/model.history';
import { ModelHistoryService } from '../model-history.service';
import { getClassLabel } from '../../../model-holding-shared';
import { RESEARCH_TYPES } from 'src/app/core/research-type';
import { RESEARCH_CATEGORIES } from 'src/app/core/research-category';
import * as filesize from 'filesize'
import { MODEL_TYPES } from 'src/app/core/model-type';

@Component({
    selector: 'app-history-tab-view',
    templateUrl: './history-tab-view.component.html',
    styleUrls: ['./history-tab-view.component.scss'],
})
export class HistoryTabViewComponent implements OnInit, OnDestroy {
    modelId: string;
    history = [];
    loadingIndicator = true;
    reorderable = true;
    ColumnMode = ColumnMode;

    @ViewChild(DatatableComponent, { static: true })
    datatableComponent: DatatableComponent;

    private subscription = new Subscription();

    fromDate: Date = new Date();
    thruDate: Date = new Date();

    settings = {
        bigBanner: true,
        timePicker: false,
        format: 'MM-dd-yyyy',
        defaultOpen: true,
    }

    constructor(
        private historyService: ModelHistoryService,
        private route: ActivatedRoute,
        private messageBoxService: MessageBoxService,
    ) {}

    ngOnInit() {
        const datatableBody =
            this.datatableComponent.element.getElementsByClassName('datatable-body').item(0) as HTMLElement;
        merge(
            fromEvent(window, 'resize'),
            fromEvent(window, 'orientationchange'),
        ).pipe(
            startWith(1),
            observeOn(animationFrameScheduler),
        ).subscribe(() => {
            if(datatableBody !== null){
                datatableBody.style.height = '25rem';
                datatableBody.style.overflowY = 'scroll';
            }
        });

        this.modelId = this.route.parent.snapshot.params['id'];
        this.getHistoryEntries(this.modelId,
            new Date().toLocaleDateString(),
            new Date().toLocaleDateString());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    search() {
        const tempFromDate = new Date(this.fromDate).toLocaleDateString();
        const tempThruDate = new Date(this.thruDate).toLocaleDateString();

        this.getHistoryEntries(this.modelId,
            tempFromDate,
            tempThruDate);
    }

    getHistoryEntries(modelId: string, fromDate: any, thruDate: any): void {
        this.loadingIndicator = true;

        this.subscription.add(
            this.historyService.getHistory(modelId, fromDate, thruDate).subscribe(
                history => {
                    this.history = history.reduce((accumulator, currentValue) => {
                        currentValue.items.forEach(item => {
                            accumulator.push(this.toViewEntry(currentValue, item));
                        });

                        return accumulator;
                    }, []);

                    this.loadingIndicator = false;
                },
                () => {
                    this.messageBoxService.error('user-list-error');
                },
            ),
        );
    }

    private toViewEntry(history: ModelHistory, historyItem: ModelHistoryItem): ModelHistoryViewEntry {
        const fieldInfo = this.toFieldInfo(historyItem.field);

        return {
            date: new Date(`${history.date}Z`),
            action: this.toAction(historyItem.field, history.action),
            user: history.user,
            oldValue: this.transformFieldValue(historyItem.field, historyItem.oldValue),
            newValue: this.transformFieldValue(historyItem.field, historyItem.newValue),
            section: fieldInfo.section,
            field: fieldInfo.field,
        };
    }

    private toAction(field: string, action: string): string {
        switch (field) {
            case 'Research': {
                return action === 'Create' ? 'Add' : 'Delete';
            }
            default: {
                return action;
            }
        }
    }

    private toFieldInfo(field: string): ModelHistoryFieldInfo {
        switch (field) {
            case 'Name': {
                return { section: 'Details', field: 'Name' };
            }
            case 'Description': {
                return { section: 'Details', field: 'Description' };
            }
            case 'Type': {
                return { section: 'Details', field: 'Model Type' };
            }
            case 'Status': {
                return { section: 'Details', field: 'Status' };
            }
            case 'AssetClass': {
                return { section: 'Details', field: 'Asset Class' };
            }
            case 'Region': {
                return { section: 'Details', field: 'Region' };
            }
            case 'StyleClass': {
                return { section: 'Details', field: 'Style Class' };
            }
            case 'BenchmarkName': {
                return { section: 'Details', field: 'Manager Benchmark' };
            }
            case 'PerformanceBenchmark': {
                return { section: 'Details', field: 'Performance Benchmark' };
            }
            case 'ManagerFee': {
                return { section: 'Details', field: 'Manager Fee' };
            }
            case 'StatisticsId': {
                return { section: 'Details', field: 'Statistics ID' };
            }
            case 'Holding-Equity': {
                return { section: 'Holdings', field: '' };
            }
            case 'Holding-FixedIncome': {
                return { section: 'Holdings', field: '' };
            }
            case 'Sleeve': {
                return { section: 'Sleeves', field: '' };
            }
            case 'Research': {
                return { section: 'Research', field: '' };
            }
            case 'EffectiveFrom': {
                return { section: 'Statistics', field: 'Effective Date' };
            }
            case 'Alpha': {
                return { section: 'Statistics', field: 'Alpha' };
            }
            case 'Beta': {
                return { section: 'Statistics', field: 'Beta' };
            }
            case 'SharpeRatio': {
                return { section: 'Statistics', field: 'Sharpe Ratio' };
            }
            case 'RSquared': {
                return { section: 'Statistics', field: 'R Squared' };
            }
            case 'StandardDeviation': {
                return { section: 'Statistics', field: 'Standard Deviation' };
            }
            case 'MaximumDrawdown': {
                return { section: 'Statistics', field: 'Maximum Drawdown' };
            }
            case 'TrackingError': {
                return { section: 'Statistics', field: 'Tracking Error' };
            }
            case 'InfoRatio': {
                return { section: 'Statistics', field: 'Info Ratio' };
            }
            default: {
                return { section: '', field: '' };
            }
        }
    }

    private transformFieldValue(field: string, dto: ModelHistoryValue): string {
        if (!dto || (!dto.value && !dto.values)) {
            return '';
        }

        switch (field) {
            case 'Status': {
                return this.getStatusLabel(dto.value);
            }
            case 'AssetClass': {
                return this.getAssetClassLabel(dto.value);
            }
            case 'Region': {
                return this.getRegionLabel(dto.value);
            }
            case 'StyleClass': {
                return this.getStyleClassLabel(dto.value);
            }
            case 'EffectiveFrom': {
                return dto.value ? formatDate(new Date(dto.value), 'MMMM y', 'en-US') : '';
            }
            case 'Holding-Equity': {
                return this.getEquityHolding(dto.values);
            }
            case 'Holding-FixedIncome': {
                return this.getFixedIncomeHolding(dto.values);
            }
            case 'Research': {
                return this.getResearch(dto.values);
            }
            case 'Sleeve': {
                return this.getSleeve(dto.values);
            }
            case 'Type': {
                return this.getTypeLabel(dto.value);
            }
            default: {
                return dto.value;
            }
        }
    }

    private getStatusLabel(value: string) {
        const status = MODEL_STATUSES.find(status => status.value.toString() === value);
        return (status && status.label) || value;
    }

    private getAssetClassLabel(value: string) {
        const category = MODEL_CATEGORIES.find(category => category.value === value);
        return (category && category.label) || value;
    }

    private getStyleClassLabel(value: string) {
        const styleClass = MODEL_CLASSES_ALL.find(x => x.value.toString() === value);
        return (styleClass && styleClass.label) || value;
    }

    private getRegionLabel(value: string) {
        const region = MODEL_REGIONS.find(x => x.value.toString() === value);
        return (region && region.label) || value;
    }

    private getHoldingClass(value) {
        return getClassLabel(value);
    }

    private getEquityHolding(values: string[]): string {
        if (!values) {
            return undefined;
        }

        if (values.length !== 7) {
            return ''; //error
        }
        values[4] = `${values[4]}%`;

        return values.filter(p => p).join('  |  ');
    }

    private getFixedIncomeHolding(values: string[]): string {
        if (!values) {
            return undefined;
        }

        if (values.length !== 7) {
            return ''; //error
        }
        values[1] = this.getHoldingClass(values[1]);
        values[4] = `${values[4]}%`;

        return values.filter(p => p).join('  |  ');
    }

    private getResearch(values: string[]): string {
        if (!values) {
            return undefined;
        }

        if (values.length !== 4) {
            return ''; //error
        }
        values[0] = this.getResearchTypeLabel(values[0]);
        values[1] = this.getResearchCategoryLabel(values[1]);
        values[3] = filesize(Number(values[3]), { round: 1 });

        return values.filter(p => p).join('  |  ');
    }

    private getSleeve(values: string[]): string {
        if (!values) {
            return undefined;
        }

        if (values.length !== 2) {
            return ''; //error
        }
        values[1] = `${values[1]}%`;

        return values.filter(p => p).join('  |  ');
    }

    private getResearchTypeLabel(value) {
        // Do not display Research Type for now, as we only have Document type yet
        return undefined;

        const researchType = RESEARCH_TYPES.find(x => x.value === value);
        if (researchType) {
            return researchType.label
        }

        return value;
    }

    private getResearchCategoryLabel(value) {
        const researchCategory = RESEARCH_CATEGORIES.find(x => x.value === value);
        if (researchCategory) {
            return researchCategory.label
        }

        return value;
    }

    private getTypeLabel(value: string) {
        const type = MODEL_TYPES.find(type => type.value.toString() === value);
        return (type && type.label) || value;
    }
}
