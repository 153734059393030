import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ResetFormService {

    private shouldResetSelf = new Subject<boolean>();
    shouldResetSelfObservable = this.shouldResetSelf.asObservable();

    constructor() { }

    resetDirtyForms() {
        this.shouldResetSelf.next(true);
    }
}
