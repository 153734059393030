import { Component, OnInit, Input } from '@angular/core';
import { EquityDynamicHolding } from 'src/app/models/equity-dynamic-holding.model';

@Component({
    selector: 'app-equity-table-view',
    templateUrl: './equity-table-view.component.html',
    styleUrls: ['./equity-table-view.component.scss', '../../model-holdings/column-sizes.view.scss'],
})
export class EquityTableViewComponent implements OnInit {
    @Input() holdings: EquityDynamicHolding[];
    @Input() equityType: string;

    constructor() {}

    ngOnInit() {}
}
