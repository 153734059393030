import { FormGroup, ValidationErrors } from '@angular/forms';
import { IcrValidators, isEmptyOrWhitespace } from 'src/app/shared/validators/icr.validators';

export function contactFormValidator(form: FormGroup): ValidationErrors | null {
    const editableControls = Object.keys(form.controls)
        .filter(x => x !== 'phoneCountryCode')
        .map(x => form.get(x))
        .filter(x => !x.disabled);

    const allFieldsEmpty = editableControls.every(control => {
        return isEmptyOrWhitespace(control.value)
    });

    if (allFieldsEmpty) {
        editableControls.forEach(control => control.setErrors(null));
    } else {
        const firstName = form.get('firstName');
        const lastName = form.get('lastName');
        const contactLabel = form.get('contactLabel');

        firstName.setErrors(IcrValidators.requiredNoWhitespace(firstName));
        lastName.setErrors(IcrValidators.requiredNoWhitespace(lastName));
        contactLabel.setErrors(IcrValidators.requiredNoWhitespace(contactLabel));
    }

    return null;
}
