import { Model } from 'src/app/models/model.model';
import { MODEL_CATEGORIES } from 'src/app/core/constants';
import { Component, OnInit, Input } from '@angular/core';
import { ModelCategory } from 'src/app/model-pages/model-category.enum';
import { FormArray } from '@angular/forms';
import { EquityDynamicHoldingFormGroup, FixedIncomeDynamicHoldingFormGroup }
    from 'src/app/model-pages/model-detail/holding-form-group';
import { sumQuantity } from 'src/app/model-pages/holdings-quantity.helper';

@Component({
    selector: 'app-other-blended-holding-qty-edit',
    templateUrl: './other-blended-holding-qty-edit.component.html',
    styleUrls: ['../../../../model-holdings/column-sizes.edit.scss', '../model-detail-holdings-edit.component.scss'],
})
export class OtherBlendedHoldingQtyEditComponent implements OnInit {
    @Input() public model: Model;
    @Input() public holdings: FormArray;
    @Input() public canEditWholeRow: (index: number) => boolean;
    @Input() public removeHolding: (i: number) => void;
    @Input() public addNewHolding: (modelCategory: ModelCategory) => void;

    public ModelCategory = ModelCategory;

    public equityCategoryLabel = MODEL_CATEGORIES.find(x => x.value === ModelCategory.Equity).label;
    public fixedIncomeCategoryLabel = MODEL_CATEGORIES.find(x => x.value === ModelCategory.FixedIncome).label;

    constructor() { }

    public get equityHoldings(): EquityDynamicHoldingFormGroup[] {
        return this.holdings
            ? this.holdings.controls
                .filter(h => h instanceof EquityDynamicHoldingFormGroup) as EquityDynamicHoldingFormGroup[]
            : [];
    }

    public get fixedIncomeHoldings(): FixedIncomeDynamicHoldingFormGroup[] {
        return this.holdings
            ? this.holdings.controls
                .filter(h => h instanceof FixedIncomeDynamicHoldingFormGroup) as FixedIncomeDynamicHoldingFormGroup[]
            : [];
    }

    public getHoldingsIndex(
        holdingControl: EquityDynamicHoldingFormGroup | FixedIncomeDynamicHoldingFormGroup,
    ): number {
        return this.holdings.controls.indexOf(holdingControl);
    }

    public getTotalQuantityPercentage(
        formGroups: EquityDynamicHoldingFormGroup[] | FixedIncomeDynamicHoldingFormGroup[],
    ) {
        return sumQuantity(formGroups.map(x => x && x.value))
    }

    ngOnInit() {
    }
}
