import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { round } from 'src/app/core/utils';

@Component({
    selector: 'app-percentage-bar',
    templateUrl: './percentage-bar.component.html',
    styleUrls: ['./percentage-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PercentageBarComponent implements OnInit {
    @Input() quantity: number;
    @Input() limit: number;

    constructor() { }

    ngOnInit() {

    }

    get overTheLimit(): boolean {
        return this.quantity > this.limit;
    }

    get diff(): number {
        return round(this.limit - this.quantity, 4);
    }

    get absolutDifference(): number {
        return Math.abs(this.diff);
    }

    get differenceGuidance(): string {
        return (this.diff > 0) ? 'to complete' : 'over budget';
    }
}
