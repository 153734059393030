import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrl, RESEARCH_FILE_SIZE_LIMIT_IN_BYTES } from 'src/app/core/constants';
import { ModelResearch } from 'src/app/models/model-research.model';
import { ModelResearchItem } from 'src/app/models/model-research-item.model';
import { ModelResearchFile } from 'src/app/models/model-research-file.model';

@Injectable({
    providedIn: 'root',
})
export class ModelResearchService {
    constructor(private http: HttpClient) { }

    public fileExceedsSizeLimit(file: File): boolean {
        return file.size > RESEARCH_FILE_SIZE_LIMIT_IN_BYTES;
    }

    public addNewResearch(research: ModelResearch, file: File): Observable<void> {
        const formData: FormData = new FormData();
        formData.append('research', JSON.stringify(research));
        formData.append('formFile', file);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return this.http.post<void>(
            `${apiUrl}/api/v1/models/${research.modelId}/researches/`,
            formData,
            { headers },
        );
    }

    public addResearchFile(modelId: string, researchId: string, file: File): Observable<ModelResearchFile> {
        const formData: FormData = new FormData();
        formData.append('formFile', file);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return this.http.post<ModelResearchFile>(
            `${apiUrl}/api/v1/models/${modelId}/researches/${researchId}/files`,
            formData,
            { headers },
        );
    }

    public getResearches(modelId: string): Observable<ModelResearchItem[]> {
        return this.http.get<ModelResearchItem[]>(`${apiUrl}/api/v1/models/${modelId}/researches`);
    }

    public getFiles(modelId: string, researchId: string): Observable<ModelResearchFile[]> {
        return this.http.get<ModelResearchFile[]>(`${apiUrl}/api/v1/models/${modelId}/researches/${researchId}/files`);
    }

    public deleteFile(modelId: string, researchId: string, fileId: string): Observable<void> {
        return this.http.delete<void>(`${apiUrl}/api/v1/models/${modelId}/researches/${researchId}/files/${fileId}`);
    }

    public downloadFile(modelId: string, researchId: string, fileId: string): Observable<Blob> {
        return this.http.get<Blob>(
            `${apiUrl}/api/v1/models/${modelId}/researches/${researchId}/files/${fileId}`,
            { responseType: 'blob' as 'json' },
        );
    }
}
