export enum AppPermissions {
    ViewProfile = 'ViewProfile',
    CreateUser = 'CreateUser',
    EditUser = 'EditUser',
    CrudFirm = 'CrudFirm',
    ViewModels = 'ViewModels',
    CreateModel = 'CreateModel',
    CreateModelOfModels = 'CreateModelOfModels',
    EditAnyFirmModel = 'EditAnyFirmModel',
    EditOwnFirmModel = 'EditOwnFirmModel',
    ApproveModel = 'ApproveModel',
    AuthorizeModel = 'AuthorizeModel',
    ViewFirms = 'ViewFirms',
    ViewFirmDetails = 'ViewFirmDetails',
    EditStatisticsId = 'EditStatisticsId',
    DeleteResearchFile = 'DeleteResearchFile',
    UploadStatistics = 'UploadStatistics',
    UploadHoldings = 'UploadHoldings',
    EditPerformanceBenchmark = 'EditPerformanceBenchmark',
}
