import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-delete-trashcan',
    templateUrl: './delete-trashcan.component.html',
    styleUrls: ['./delete-trashcan.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteTrashcanComponent implements OnInit {

    @Input() warningMessage = 'Delete row?';
    @Output() deleteConfirmed = new EventEmitter();

    constructor() {  }

    ngOnInit() {  }

    onConfirmed() {
        this.deleteConfirmed.emit();
    }
}
