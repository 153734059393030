import { NgForm } from '@angular/forms';
import { WarnOnLeaveComponent } from './warn-on-leave.component';

export abstract class WarnOnLeaveFormComponent extends WarnOnLeaveComponent {
    abstract get form(): NgForm;

    shouldWarn(): boolean {
        return this.form.dirty;
    }
}
