import { MODEL_CATEGORIES } from 'src/app/core/constants';
import { ModelRegion, MODEL_REGIONS } from 'src/app/core/model-region';
import { ModelStyleClass, MODEL_CLASSES_ALL } from 'src/app/core/model-style-class';
import { ModelCategory } from '../model-category.enum';
import { ModelType, MODEL_TYPES } from 'src/app/core/model-type';

export const filterLabel = {
    category(value: ModelCategory) {
        const category = MODEL_CATEGORIES.find(category => category.value === value);
        return (category && category.label) || value;
    },
    styleClass(value: ModelStyleClass) {
        const styleClass = MODEL_CLASSES_ALL.find(x => x.value === value);
        return (styleClass && styleClass.label) || value;
    },
    region(value: ModelRegion) {
        const region = MODEL_REGIONS.find(x => x.value === value);
        return (region && region.label) || value;
    },
    type(value: ModelType) {
        const type = MODEL_TYPES.find(x => x.value === value);
        return type && type.label || value;
    },
};
