import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ModelHoldingCategory } from 'src/app/model-pages/model-holding-category-enum';
import { Holding } from 'src/app/models/holding.model';
import { EquityDynamicHolding } from 'src/app/models/equity-dynamic-holding.model';
import { FixedIncomeDynamicHolding } from 'src/app/models/fixed-income-dynamic-holding.model';
import { sumQuantity } from '../../holdings-quantity.helper';

@Component({
    selector: 'app-other-holdings-table-view',
    templateUrl: './other-holdings-table-view.component.html',
    styleUrls: ['./other-holdings-table-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherHoldingsTableViewComponent implements OnInit {

    @Input() holdings: Holding[];
    equityHoldings: EquityDynamicHolding[];
    fixedIncomeHoldings: FixedIncomeDynamicHolding[];

    constructor() { }

    ngOnInit() {
        this.equityHoldings = this.holdings
            .filter(h => h.category === ModelHoldingCategory.Equity) as EquityDynamicHolding[];
        this.fixedIncomeHoldings = this.holdings
            .filter(h => h.category === ModelHoldingCategory.FixedIncome) as FixedIncomeDynamicHolding[];
    }

    getTotalQuantityPercentage(holdings: Holding[]) {
        return sumQuantity(holdings);
    }
}
