import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormArray, AbstractControl, NgForm, FormGroup } from '@angular/forms';
import { ModelService } from '../model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Model } from 'src/app/models/model.model';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { Holding } from 'src/app/models/holding.model';
import { WarnOnLeaveFormComponent } from 'src/app/shared/warn-on-leave/warn-on-leave-form.component';
import { ModelCategory } from '../model-category.enum';
import { MODEL_CATEGORIES } from 'src/app/core/constants';
import { Subscription } from 'rxjs';
import { ModelHoldingsDataService } from './model-holdings.data.service';
import { EquityDynamicHoldingFormGroup, FixedIncomeDynamicHoldingFormGroup } from '../model-detail/holding-form-group';
import { sumQuantity } from '../holdings-quantity.helper';
import { ResetFormService } from 'src/app/shared/guards/form-reset.service';
import { ModelType } from 'src/app/core/model-type';

@Component({
    selector: 'app-model-holdings',
    templateUrl: './model-holdings.component.html',
    styleUrls: ['./model-holdings.component.scss', '../model-pages.layout.scss', './column-sizes.edit.scss'],
})
export class ModelHoldingsComponent extends WarnOnLeaveFormComponent implements OnInit, OnDestroy {
    @ViewChild('form', { static: false }) form: NgForm;

    holdings: FormArray;
    INITIAL_ROW_COUNT = 15;
    uploadedHoldings: FormArray;
    modelId: string;
    ModelCategory = ModelCategory;
    ModelType = ModelType;
    model: Model;

    backRoute: string;

    private subscription = new Subscription();

    constructor(
        private formBuilder: FormBuilder,
        private modelService: ModelService,
        private route: ActivatedRoute,
        private router: Router,
        private messageBoxService: MessageBoxService,
        private modelHoldingsDataService: ModelHoldingsDataService,
        private resetFormService: ResetFormService,
    ) {
        super();
    }

    ngOnInit() {
        this.modelId = this.route.snapshot.params['id'];
        this.holdings = this.formBuilder.array([]);

        this.subscription.add(
            this.modelService.getModel(this.modelId).subscribe(
                (model: Model) => {
                    this.backRoute = this.getBackRoute(model.type);
                    this.model = model;
                    if (model.type === ModelType.Holdings) {
                        this.initializeHoldings();
                    }

                },
                () => this.messageBoxService.error('holding-fetch-failed'),
            ),
        );

        this.subscription.add(
            this.resetFormService.shouldResetSelfObservable.subscribe(
                () => this.holdings.reset(),
            ),
        );
    }

    removeHolding(i: number) {
        this.holdings.markAsDirty();
        this.holdings.removeAt(i);
    }

    getHoldingForm(holding?: Holding) {
        return this.modelHoldingsDataService.getHoldingForm(this.model.category, holding);
    }

    submitModelHoldings() {
        const nonEmptyHoldings = this.nonEmptyHoldings;

        if (!this.validateModelHoldings(nonEmptyHoldings)) {
            return;
        }

        const holdings = nonEmptyHoldings.map((holdingControl: FormGroup) => {
            let category: ModelCategory = null;

            if (holdingControl instanceof EquityDynamicHoldingFormGroup) {
                category = ModelCategory.Equity;
            } else if (holdingControl instanceof FixedIncomeDynamicHoldingFormGroup) {
                category = ModelCategory.FixedIncome;
            }

            return {
                ...holdingControl.value,
                modelId: this.modelId,
                category,
            }
        });
        const model = {
            modelId: this.model.id,
            holdings,
        };

        this.subscription.add(
            this.modelService.updateModelHoldings(model).subscribe(
                () => {
                    // need to mark to prevent "warn on leave" modal
                    this.holdings.markAsPristine();
                    this.modelHoldingsDataService.clearUploadedHoldings();

                    this.router.navigate(['../review'], { relativeTo: this.route, queryParamsHandling: 'merge' });
                },
                () => this.messageBoxService.error('holding-save-failed'),
            ),
        );
    }

    get totalQuantity() {
        return sumQuantity(this.holdings.value);
    }

    get modelCategoryLabel() {
        const category = MODEL_CATEGORIES.find(x => x.value === this.model.category);
        if (category) {
            return category.label;
        }

        return '';
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private getBackRoute(type: ModelType) {
        if (type === ModelType.ModelOfModels) {
            return '..';
        }
        return '../upload'
    }

    private initializeHoldings() {
        this.model.holdings.forEach(holding => this.holdings.push(this.getHoldingForm(holding)));

        this.subscription.add(
            this.modelHoldingsDataService.uploadedHoldings.subscribe(uploadedHoldings => {
                if (uploadedHoldings.uploaded) {
                    this.uploadedHoldings = uploadedHoldings.holdings;
                } else {
                    this.uploadedHoldings = null;
                }

                if (this.uploadedHoldings) {
                    this.holdings = this.uploadedHoldings;
                    this.holdings.controls.forEach(holdingControl => {
                        holdingControl.markAsDirty();
                        holdingControl.markAllAsTouched();
                    });
                } else {
                    if (this.model.category !== ModelCategory.Other) {
                        for (let i = this.holdings.length; i < this.INITIAL_ROW_COUNT; i++) {
                            this.holdings.push(this.getHoldingForm());
                        }
                    }
                }
            }),
        );
    }

    private get nonEmptyHoldings() {
        return this.holdings.controls.filter(
            holding => !holding.pristine || !holding.valid || (holding.value && holding.value.id),
        );
    }

    private validateModelHoldings(nonEmptyHoldings: AbstractControl[]): boolean {
        nonEmptyHoldings.forEach(holding => holding.markAllAsTouched());

        if (this.totalQuantity !== 100) {
            this.messageBoxService.error('holding-sum-invalid');
            return false;
        }

        if (nonEmptyHoldings.some(holding => !holding.valid)) {
            return false;
        }

        return true;
    }
}
