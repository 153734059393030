export enum Roles {
    PortfolioManager = 'PortfolioManager',
    SeniorPortfolioManager = 'SeniorPortfolioManager',
    Compliance = 'Compliance',
    AssetManager = 'AssetManager',
    FirmAdmin = 'FirmAdmin',
    Research = 'Research',
    ICRAdmin = 'ICRAdmin',
}

export const USER_ROLES = [
    { label: 'Portfolio Manager', value: Roles.PortfolioManager },
    { label: 'Sr. Portfolio Manager', value: Roles.SeniorPortfolioManager },
    { label: 'Compliance', value: Roles.Compliance },
    { label: 'Asset Manager', value: Roles.AssetManager },
    { label: 'Firm Admin', value: Roles.FirmAdmin },
    { label: 'Research', value: Roles.Research },
    { label: 'ICR Admin', value: Roles.ICRAdmin },
];
