import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UnauthorizedErrorInterceptor implements HttpInterceptor {
    constructor(private oauthService: OAuthService, private router: Router) {

    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            catchError((response: HttpResponse<any>) => {
                if (response && response.status === 401) {
                    this.initLoginFlow(this.router.url);
                }
                if (response && response.status === 403) {
                    this.initLoginFlow();
                }

                return throwError(response);
            }),
        );
    }

    private initLoginFlow(state?: string): void {
        const encodedUri = encodeURIComponent(state);
        this.oauthService.initLoginFlow(encodedUri);
    }
}
