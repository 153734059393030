import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, Observable } from 'rxjs';

import { ModelHoldingsDataService } from '../model-holdings/model-holdings.data.service';
import { UploadModelHoldingsConverterService } from './upload-model-holdings-converter.service';
import { ModelCategory } from '../model-category.enum';
import { CSVRecord } from './csv-record';
import { Papa, ParseResult, ParseConfig } from 'ngx-papaparse';

const CSV_HEADER_COLUMNS = ['issuerName', 'assetClass', 'class', 'ticker', 'cusip', 'quantity'];
const ASSET_CLASS_NAME_TO_ENUM = {
    EQUITY: ModelCategory.Equity,
    'FIXED INCOME': ModelCategory.FixedIncome,
};
const MAX_LENGTH_CUSIP = 9;
const MAX_LENGTH_TICKER = 10;
const MAX_LENGTH_ISSUER = 100;
const ALLOWED_CSV_SIZE_IN_KB = 100;

@Injectable({
    providedIn: 'root',
})
export class UploadModelHoldingsService {
    constructor(
        private formBuilder: FormBuilder,
        private modelHoldingsDataService: ModelHoldingsDataService,
        private converterService: UploadModelHoldingsConverterService,
        private papa: Papa,
    ) {}

    uploadFile(modelAssetClass: ModelCategory, file: File): Observable<string> {
        const subject = new Subject<string>();

        function completeUploadFile(error: string) {
            subject.next(error);
            subject.complete();
        }

        const error = this.validateFileSize(file.size);
        if (error) {
            setTimeout(() => completeUploadFile(error));
            return subject.asObservable();
        }

        const parseOptions: ParseConfig = {
            complete: results => {
                completeUploadFile(this.onParseComplete(modelAssetClass, results));
            },
            error: () => {
                completeUploadFile('Error is occured while reading file!');
            },
            header: true,
            skipEmptyLines: true,
        };

        this.papa.parse(file, parseOptions);

        return subject.asObservable();
    }

    private onParseComplete(modelAssetClass: ModelCategory, results: ParseResult): string {
        const validationError = this.validateUploadFields(results.meta.fields);
        if (validationError) {
            return validationError;
        }

        if (results.errors && results.errors.length) {
            return results.errors[0].message;
        }

        const processingError = this.processUploadData(modelAssetClass, results.data);
        if (processingError) {
            return processingError;
        }
    }

    private validateFileSize(fileSize: number): string {
        if (fileSize / 1024 > ALLOWED_CSV_SIZE_IN_KB) {
            return `Selected file is bigger than maximum allowed ${ALLOWED_CSV_SIZE_IN_KB}KB`;
        }

        return undefined;
    }

    private validateUploadFields(fields: string[]): string {
        for (let i = 0; i < CSV_HEADER_COLUMNS.length; i++) {
            if (!fields || !fields[i] || fields[i].toUpperCase() !== CSV_HEADER_COLUMNS[i].toUpperCase()) {
                return 'Selected file heading row does not match expected format';
            }
        }

        return undefined;
    }

    private processUploadData(modelAssetClass: ModelCategory, records: CSVRecord[]): string {
        for (const csvRecord of records) {
            const record = this.normalizeCsvRecord(csvRecord);

            const error = this.validAssetClass(this.holdingAssetClassToEnum(record.assetClass), modelAssetClass);
            if (error) {
                return error;
            }
        }

        const holdings = this.formBuilder.array([]);
        for (const csvRecord of records) {
            const assetClass = this.holdingAssetClassToEnum(csvRecord.assetClass)
            const holding = this.converterService.csvRecordToFormGroup(assetClass, csvRecord);
            holdings.push(holding);
        }
        this.modelHoldingsDataService.storeUploadedHoldings(holdings);

        return undefined;
    }

    private validAssetClass(recordAssetClass: ModelCategory, modelAssetClass: ModelCategory): string {
        if (!recordAssetClass || recordAssetClass.length === 0) {
            return 'Selected file contains holding(s) of unspecified asset class';
        }

        if (modelAssetClass !== ModelCategory.Other && modelAssetClass !== recordAssetClass) {
            return 'Selected file contains holding(s) of asset class that is not suitable for this model';
        }

        return undefined;
    }

    private normalizeCsvRecord(record: CSVRecord): CSVRecord {
        record.cusip = record.cusip && record.cusip.trim()
        if (record.cusip && record.cusip.length > MAX_LENGTH_CUSIP) {
            record.cusip = '';
        }

        record.ticker = record.ticker && record.ticker.trim();
        if (record.ticker && record.ticker.length > MAX_LENGTH_TICKER) {
            record.ticker = '';
        }

        record.issuerName = record.issuerName && record.issuerName.trim();
        if (record.issuerName && record.issuerName.length > MAX_LENGTH_ISSUER) {
            record.issuerName = '';
        }

        record.quantity = this.normalizeQuantity(record.quantity);

        return record;
    }

    private normalizeQuantity(quantityValue) {
        let quantity = Number(quantityValue);
        if (!quantity || Number.isNaN(quantity)) {
            return 0;
        }

        // Limit Quantity precision to 4 digits
        quantity = Number(quantity.toFixed(4));

        // Quantity must be in range between 0 and 100
        if (quantity > 100 || quantity < 0) {
            return 0;
        }

        return quantity;
    }

    private holdingAssetClassToEnum(assetClass: string): ModelCategory {
        return ASSET_CLASS_NAME_TO_ENUM[assetClass ? assetClass.trim().toUpperCase() : ''];
    }
}
