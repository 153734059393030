import { Directive, HostListener, Input } from '@angular/core';
import { FormControl, AbstractControl, NgForm, FormGroup, FormArray } from '@angular/forms';

@Directive({
    selector: '[focusFirstInvalidField]',
})
export class FocusFirstInvalidFieldDirective {
    @Input() formGroup: NgForm;

    constructor() { }

    @HostListener('submit', ['$event'])
    public onSubmit(event: Event): void {
        if (this.formGroup.invalid) {
            event.preventDefault();

            const invalidControl = this.findInvalidFormControl(Object.keys(this.formGroup.controls)
                .map(key => this.formGroup.controls[key]));

            if (invalidControl) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const invalidElem: any = invalidControl;
                if (invalidElem.nativeElement)  {
                    invalidElem.nativeElement.focus();
                }
            }
        }
    }

    private findInvalidFormControl(controls: AbstractControl[]): AbstractControl {
        const invalidControls = controls.filter(ctl => ctl.invalid && ctl.touched);

        for (const control of invalidControls) {
            if (control instanceof FormControl) {
                return control;
            } else if (control instanceof FormGroup) {
                return this.findInvalidFormControl(Object.keys(control.controls).map(key => control.get(key)));
            } else if (control instanceof FormArray) {
                return this.findInvalidFormControl(control.controls);
            }
        }

        return null;
    }
}
