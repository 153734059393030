import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { MODEL_CATEGORIES } from '../../core/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { ModelService } from '../model.service';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { Model, ModelDetailsCreateModel } from 'src/app/models/model.model';
import { WarnOnLeaveFormComponent } from 'src/app/shared/warn-on-leave/warn-on-leave-form.component';
import { IcrValidators } from 'src/app/shared/validators/icr.validators';
import { MODEL_TYPES, ModelType } from 'src/app/core/model-type';
import { Subscription } from 'rxjs';
import { ResetFormService } from 'src/app/shared/guards/form-reset.service';
import { ModelAuthorizationService } from '../model-authorization.service';
import { ModelValidators } from '../model-name.validator';

@Component({
    selector: 'app-create-model',
    templateUrl: './create-model.component.html',
    styleUrls: ['./create-model.component.scss'],
})
export class CreateModelComponent extends WarnOnLeaveFormComponent implements OnInit, OnDestroy {
    @ViewChild('form', { static: false }) form: NgForm;

    createModelForm: FormGroup;
    modelId: string;

    readonly availableModelTypes = MODEL_TYPES;
    readonly availableModelCategories = MODEL_CATEGORIES;

    private subscription = new Subscription();
    private originalName = '';

    constructor(
        private fb: FormBuilder,
        private modelService: ModelService,
        private router: Router,
        private route: ActivatedRoute,
        private messageBoxService: MessageBoxService,
        private resetFormService: ResetFormService,
        private modelAuthorizationService: ModelAuthorizationService,
    ) {
        super();
    }

    async ngOnInit() {
        const modelId = this.route.snapshot.params['id'];

        this.createModelForm = this.fb.group({
            name: [
                '',
                IcrValidators.requiredNoWhitespace,
                (control) => ModelValidators.nameUnique(this.modelService, control, this.originalName),
            ],
            type: ModelType.Holdings,
            benchmarkName: '',
            description: '',
            category: [null, [Validators.required]],
        });

        if (modelId) {
            this.subscription.add(
                this.modelService.getModel(modelId).subscribe(
                    async(model: Model) => {
                        this.modelId = model.id;
                        this.originalName = model.name;
                        this.createModelForm.patchValue(model);

                        if (model.holdings.length > 0) {
                            this.createModelForm.controls.category.disable();
                            this.createModelForm.controls.type.disable();
                        } else {
                            await this.setModelTypeSelectorAvailability();
                            this.enableOrDisableAssetClass(model.type);
                        }

                        this.createModelForm.markAsTouched();

                    },
                    () => this.messageBoxService.error('model-fetch-failed'),
                ),
            );
        } else {
            await this.setModelTypeSelectorAvailability();
        }

        this.subscription.add(
            this.resetFormService.shouldResetSelfObservable.subscribe(() => this.createModelForm.reset()),
        );
    }

    onSubmit() {
        if (this.createModelForm.valid) {
            if (this.modelId) {
                this.subscription.add(
                    this.modelService.updateModelDetailsInCreationFlow(this.modelDetailsCreateModel).subscribe(
                        () => {
                        // need to mark to prevent "warn on leave" modal
                            this.createModelForm.markAsPristine();
                            this.navigateToNextStep(this.modelId);
                        }),
                );
            } else {
                this.subscription.add(
                    this.modelService.createModel(
                        {
                            ...this.createModelForm.getRawValue(),
                            name: this.createModelForm.controls.name.value.trim(),
                        })
                        .subscribe(
                            (modelId: string) => {
                                // need to mark to prevent "warn on leave" modal
                                this.createModelForm.markAsPristine();
                                this.navigateToNextStep(modelId);
                            },
                            () => this.messageBoxService.error('model-creation-error'),
                        ),
                );
            }
        } else {
            this.createModelForm.markAllAsTouched();
        }
    }

    get modelDetailsCreateModel(): ModelDetailsCreateModel {
        const modelForm = this.createModelForm.value;

        return {
            id: this.modelId,
            name: modelForm.name.trim(),
            type: this.createModelForm.getRawValue().type,
            benchmarkName: modelForm.benchmarkName,
            description: modelForm.description,
            category: this.createModelForm.getRawValue().category,
        }
    }

    get modelName() {
        return this.createModelForm.controls.name;
    }

    get modelCategory() {
        return this.createModelForm.controls.category;
    }

    private async setModelTypeSelectorAvailability(): Promise<void> {
        const modelTypeSelector = this.createModelForm.controls.type;

        if (await this.modelAuthorizationService.canCreateModelOfModels()) {
            this.subscribeAssetClassToModelType();
        } else {
            modelTypeSelector.disable();
        }
    }

    private subscribeAssetClassToModelType(): void {
        this.subscription.add(
            this.createModelForm.controls.type
                .valueChanges
                .subscribe((type) => (this.enableOrDisableAssetClass(type))),
        );
    }

    private navigateToNextStep(modelId: string) {
        history.pushState(null, null, `models/create/${modelId}`);
        if (this.createModelForm.controls.type.value === ModelType.Holdings) {
            this.router.navigate([`models/create/${modelId}/upload`], {
                queryParamsHandling: 'merge',
            });
        } else {
            this.router.navigate([`models/create/${modelId}/holdings`], {
                queryParamsHandling: 'merge',
            });
        }
    }

    private enableOrDisableAssetClass(modelType: ModelType) {
        const assetClass = this.createModelForm.controls.category;

        if (modelType === ModelType.ModelOfModels) {
            assetClass.reset();
            assetClass.disable();
        } else {
            assetClass.enable();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
