import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-mobile-phone-input',
    templateUrl: './mobile-phone-input.component.html',
    styleUrls: ['./mobile-phone-input.component.scss'],
})
export class MobilePhoneInputComponent implements OnInit {
    isFocused: boolean;

    public phoneNumberMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public phoneCCMask = ['+', /\d/, /\d/];

    constructor() {}

    ngOnInit() {
        this.isFocused = false;
    }

    @Input() parentForm: FormGroup;
    @Input() label: string;
    @Input() placeholder = '';
    @Input() id: string;
    @Input() errorMessage: string;
    @Input() formValidationName: string;
    @Input() formValidationNameCC: string;
    @Input() type: string;
    @Input() isNarrow: boolean;
    @Input() isDisabled: boolean;
    @Input() limitCharset = null;

    onFocus() {
        this.isFocused = true;
    }

    onUnfocus() {
        this.isFocused = false;
    }
}
