import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import _ from 'lodash';

import { ModelListViewModel } from 'src/app/models/model.model';
import { ModelFilters, FILTER_ASSET_CLASS, FILTER_STYLE_CLASS } from './model-list.filter.model';
import { ModelCategory } from '../model-category.enum';
import { ModelStyleClass } from 'src/app/core/model-style-class';

const FILTER_PARAM_ASSET_CLASS = 'assetClass';
const FILTER_PARAM_REGION = 'region';
const FILTER_PARAM_STYLE_CLASS = 'style';
const FILTER_PARAM_FEES = 'fees';
const FILTER_PARAM_ALPHA = 'alpha';
const FILTER_PARAM_SHARPE_RATIO = 'sharpeRatio';
const FILTER_PARAM_NAME = 'firmName';
const FILTER_PARAM_BETA_FROM = 'betaFrom';
const FILTER_PARAM_BETA_TO = 'betaTo';
const FILTER_PARAM_RSQUARED_FROM = 'rFrom';
const FILTER_PARAM_RSQUARED_TO = 'rTo';
const FILTER_PARAM_SUBSCRIBED_ONLY = 'subscribed';

@Injectable({
    providedIn: 'root',
})
export class ModelListFilterService {
    private filtersSubject = new Subject<ModelFilters>();
    filtersObservable = this.filtersSubject.asObservable();
    private modelsSubject = new Subject<ModelListViewModel[]>();
    modelsObservable = this.modelsSubject.asObservable();

    get allModelsCount(): number {
        return this.allModels ? this.allModels.length : 0;
    }

    private allModels: ModelListViewModel[] = [];
    private filters = new ModelFilters();
    private assetClasses = _.invert(FILTER_ASSET_CLASS);
    private styleClasses = _.invert(FILTER_STYLE_CLASS);

    constructor(private route: ActivatedRoute, private router: Router) {}

    setModels(models: ModelListViewModel[]) {
        this.allModels = models;
    }

    filterModels(): ModelListViewModel[] {
        if (!this.allModels || this.allModels.length === 0) {
            return [];
        }

        let models = this.allModels;

        if (this.filters.firmName) {
            models = models.filter(m => m.firmName.toUpperCase().includes(this.filters.firmName.toUpperCase()));
        }

        if (this.filters.managerFeesValue !== undefined) {
            models = models.filter(m => m.managerFee <= this.filters.managerFeesValue);
        }

        if (this.filters.alphaValue !== undefined) {
            models = models.filter(m => m.alpha >= this.filters.alphaValue);
        }

        if (this.filters.sharpeRatioValue !== undefined) {
            models = models.filter(m => m.sharpeRatio >= this.filters.sharpeRatioValue);
        }

        if (this.filters.betaFromValue !== undefined) {
            models = models.filter(m => m.beta >= this.filters.betaFromValue);
        }

        if (this.filters.betaToValue !== undefined) {
            models = models.filter(m => m.beta <= this.filters.betaToValue);
        }

        if (this.filters.rSquaredFromValue !== undefined) {
            models = models.filter(m => m.rSquared >= this.filters.rSquaredFromValue);
        }

        if (this.filters.rSquaredToValue !== undefined) {
            models = models.filter(m => m.rSquared <= this.filters.rSquaredToValue);
        }

        if (this.filters.assetClassValue !== undefined) {
            models = models.filter(m => m.category === this.filters.assetClassValue);
        }

        if (this.filters.regionValue !== undefined) {
            models = models.filter(m => m.region === this.filters.regionValue);
        }

        if (this.filters.styleClassValues !== undefined && this.filters.styleClassValues.length > 0) {
            models = models.filter(m => this.filters.styleClassValues.includes(m.styleClass));
        }

        if (this.filters.subscribedOnly) {
            models = models.filter(m => m.isSubscribed);
        }

        return models;
    }

    filterByName(value: string) {
        this.filters.setName(value);
        this.addFilterToUrl(FILTER_PARAM_NAME, encodeURI(value));
    }

    filterByAssetClass(value: string) {
        this.filters.setAssetClass(value);

        this.router.navigate([], {
            queryParams: {
                page: undefined, //reset paging
                [FILTER_PARAM_ASSET_CLASS]: !this.filters.assetClass ? null : this.filters.assetClass,
                [FILTER_PARAM_REGION]: !this.filters.region ? null : this.filters.region,
                [FILTER_PARAM_STYLE_CLASS]: !this.filters.styleClasses ? null : this.filters.styleClasses,
            },
            queryParamsHandling: 'merge',
        });

        this.filtersSubject.next(this.filters);
    }

    filterByAssetClassEquity(deselect = false) {
        this.filterByAssetClass(deselect ? undefined : this.assetClasses[ModelCategory.Equity]);
    }

    filterByAssetClassFixedIncome(deselect = false) {
        this.filterByAssetClass(deselect ? undefined : this.assetClasses[ModelCategory.FixedIncome]);
    }

    filterByAssetClassOther(deselect = false) {
        this.filterByAssetClass(deselect ? undefined : this.assetClasses[ModelCategory.Other]);
    }

    filterByRegion(value: string) {
        this.filters.setRegion(value);

        this.router.navigate([], {
            queryParams: {
                page: undefined, //reset paging
                [FILTER_PARAM_REGION]: !this.filters.region ? null : this.filters.region,
                [FILTER_PARAM_STYLE_CLASS]: !this.filters.styleClasses ? null : this.filters.styleClasses,
            },
            queryParamsHandling: 'merge',
        });

        this.filtersSubject.next(this.filters);
    }

    filterByStyleClass(value: string) {
        this.filters.setStyleClass(value);
        this.addFilterToUrl(FILTER_PARAM_STYLE_CLASS, !value ? null : value);
    }

    toggleFilterByStyleClass(styleClass: ModelStyleClass) {
        let styleClasses: ModelStyleClass[];
        if (this.filters.styleClassValues && this.filters.styleClassValues.includes(styleClass)) {
            styleClasses = this.removeStyleClass(styleClass);
        } else {
            styleClasses = this.addStyleClass(styleClass);
        }

        this.applyStyleClasses(styleClasses);
    }

    filterByManagerFees(value: string) {
        this.filters.setManagerFees(value);
        this.addFilterToUrl(FILTER_PARAM_FEES, !value ? null : value);
    }

    filterByAlpha(value: string) {
        this.filters.setAlpha(value);
        this.addFilterToUrl(FILTER_PARAM_ALPHA, !value ? null : value);
    }

    filterBySharpeRatio(value: string) {
        this.filters.setSharpeRatio(value);
        this.addFilterToUrl(FILTER_PARAM_SHARPE_RATIO, !value ? null : value);
    }

    filterByBetaFrom(value: string) {
        this.filters.setBetaFrom(value);
        this.addFilterToUrl(FILTER_PARAM_BETA_FROM, !value ? null : value);
    }

    filterByBetaTo(value: string) {
        this.filters.setBetaTo(value);
        this.addFilterToUrl(FILTER_PARAM_BETA_TO, !value ? null : value);
    }

    filterByRSquaredFrom(value: string) {
        this.filters.setRSquaredFrom(value);
        this.addFilterToUrl(FILTER_PARAM_RSQUARED_FROM, !value ? null : value);
    }

    filterByRSquaredTo(value: string) {
        this.filters.setRSquaredTo(value);
        this.addFilterToUrl(FILTER_PARAM_RSQUARED_TO, !value ? null : value);
    }

    filterSubscribedOnly(value: string) {
        this.filters.setSubscribedOnly(value);
        this.addFilterToUrl(FILTER_PARAM_SUBSCRIBED_ONLY, value === 'true'? 'true' : null)
    }

    clearNameFilter() {
        this.router.navigate([], {
            queryParams: {
                page: undefined, //reset paging
                [FILTER_PARAM_NAME]: null,
            },
            queryParamsHandling: 'merge',
        });
    }

    clearAssetClassFilter() {
        this.router.navigate([], {
            queryParams: {
                page: undefined, //reset paging
                [FILTER_PARAM_ASSET_CLASS]: null,
                [FILTER_PARAM_REGION]: null,
                [FILTER_PARAM_STYLE_CLASS]: null,
            },
            queryParamsHandling: 'merge',
        });
    }

    clearRegionFilter() {
        this.router.navigate([], {
            queryParams: {
                page: undefined, //reset paging
                [FILTER_PARAM_REGION]: null,
                [FILTER_PARAM_STYLE_CLASS]: null,
            },
            queryParamsHandling: 'merge',
        });
    }

    clearStyleClassFilter(styleClass: ModelStyleClass) {
        const styleClasses = this.removeStyleClass(styleClass);
        this.applyStyleClasses(styleClasses);
    }

    clearRSquaredFilter() {
        this.router.navigate([], {
            queryParams: {
                page: undefined, //reset paging
                [FILTER_PARAM_RSQUARED_FROM]: null,
                [FILTER_PARAM_RSQUARED_TO]: null,
            },
            queryParamsHandling: 'merge',
        });
    }

    clearAllFilters() {
        this.router.navigate([], {
            queryParams: {
                page: undefined, //reset paging
                [FILTER_PARAM_NAME]: null,
                [FILTER_PARAM_ASSET_CLASS]: null,
                [FILTER_PARAM_REGION]: null,
                [FILTER_PARAM_STYLE_CLASS]: null,
                [FILTER_PARAM_FEES]: null,
                [FILTER_PARAM_ALPHA]: null,
                [FILTER_PARAM_SHARPE_RATIO]: null,
                [FILTER_PARAM_BETA_FROM]: null,
                [FILTER_PARAM_BETA_TO]: null,
                [FILTER_PARAM_RSQUARED_FROM]: null,
                [FILTER_PARAM_RSQUARED_TO]: null,
                [FILTER_PARAM_SUBSCRIBED_ONLY]: null,
            },
            queryParamsHandling: 'merge',
        });
    }

    clearBetaFilter() {
        this.router.navigate([], {
            queryParams: {
                page: undefined, //reset paging
                [FILTER_PARAM_BETA_FROM]: null,
                [FILTER_PARAM_BETA_TO]: null,
            },
            queryParamsHandling: 'merge',
        });
    }

    applyFiltersFromUrl(params: Params) {
        this.filters.setName(params[FILTER_PARAM_NAME]);
        this.filters.setManagerFees(params[FILTER_PARAM_FEES]);
        this.filters.setAlpha(params[FILTER_PARAM_ALPHA]);
        this.filters.setSharpeRatio(params[FILTER_PARAM_SHARPE_RATIO]);
        this.filters.setBetaFrom(params[FILTER_PARAM_BETA_FROM]);
        this.filters.setBetaTo(params[FILTER_PARAM_BETA_TO]);
        this.filters.setRSquaredFrom(params[FILTER_PARAM_RSQUARED_FROM]);
        this.filters.setRSquaredTo(params[FILTER_PARAM_RSQUARED_TO]);
        this.filters.setAssetClass(params[FILTER_PARAM_ASSET_CLASS]);
        this.filters.setRegion(params[FILTER_PARAM_REGION]);
        this.filters.setStyleClass(params[FILTER_PARAM_STYLE_CLASS]);
        this.filters.setSubscribedOnly(params[FILTER_PARAM_SUBSCRIBED_ONLY]);

        this.modelsSubject.next(this.filterModels());
        this.filtersSubject.next(this.filters);
    }

    private addStyleClass(styleClass: ModelStyleClass): ModelStyleClass[] {
        if (!this.filters.styleClassValues) {
            return [styleClass];
        }
        if (!this.filters.styleClassValues.includes(styleClass)) {
            return [styleClass].concat(this.filters.styleClassValues);
        }

        return this.filters.styleClassValues;
    }

    private removeStyleClass(styleClass: ModelStyleClass): ModelStyleClass[] {
        if (this.filters.styleClassValues && this.filters.styleClassValues.includes(styleClass)) {
            return this.filters.styleClassValues.filter(sc => sc !== styleClass);
        }

        return this.filters.styleClassValues;
    }

    private applyStyleClasses(styleClasses: ModelStyleClass[]) {
        const styleClassesAsString =
            styleClasses.length > 0 ? styleClasses.map(sc => this.styleClasses[sc]).join(',') : undefined;
        this.filterByStyleClass(styleClassesAsString);
    }

    private addFilterToUrl(paramName: string, newFilter: string) {
        const existingParam = this.route.snapshot.queryParams[paramName];

        this.router.navigate([], {
            queryParams: {
                page: undefined, //reset paging
                [paramName]: existingParam && !newFilter ? null : newFilter,
            },
            queryParamsHandling: 'merge',
        });

        this.filtersSubject.next(this.filters);
    }
}
