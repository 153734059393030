import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClickOutsideModule } from 'ng-click-outside';
import {
    OAuthModule,
    OAuthModuleConfig,
    ValidationHandler,
    JwksValidationHandler,
    AuthConfig,
} from 'angular-oauth2-oidc';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { customUrlValidation } from './core/utils';
import { AdminModule } from './admin/admin.module';
import { UserProfileModule } from './user-profile/user-profile.module';
import { LogoutDropdownComponent } from './header/logout-dropdown/logout-dropdown.component';
import { ModelPagesModule } from './model-pages/model-pages.module';
import { UnauthorizedErrorInterceptor } from 'src/app/core/auth/unauthorized-interceptor';
import { LoadingScreenInterceptor } from './shared/loader/loading.interceptor';
import { authConfig } from './core/auth/auth.config';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        LogoutDropdownComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        OAuthModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        ReactiveFormsModule,
        SharedModule,
        ClickOutsideModule,
        AdminModule,
        ModelPagesModule,
        UserProfileModule,
        AppRoutingModule, //route order is important and AppRoutingModule must be imported last because of '**' route
    ],
    providers: [
        {
            provide: AuthConfig,
            useValue: authConfig,
        },
        {
            provide: OAuthModuleConfig,
            useValue: {
                resourceServer: {
                    allowedUrls: [],
                    sendAccessToken: true,
                    customUrlValidation,
                },
            },
        },
        {
            provide: ValidationHandler,
            useClass: JwksValidationHandler,
        },

        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingScreenInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
