import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { ModelDetailsViewModel } from 'src/app/models/model.model';
import { ModelCategory } from 'src/app/model-pages/model-category.enum';
import { ModelResearchItem } from 'src/app/models/model-research-item.model';

@Component({
    selector: 'app-model-description',
    templateUrl: './model-description.component.html',
    styleUrls: ['./model-description.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelDescriptionComponent implements OnInit {
    @Input() model: ModelDetailsViewModel;
    @Input() factSheetResearch: ModelResearchItem;

    @Output() researchDeleted = new EventEmitter<string>();

    ModelCategory = ModelCategory;

    constructor() { }

    ngOnInit() {
    }

    updateResearchWhenDeleted() {
        this.researchDeleted.emit(this.factSheetResearch.id);
    }
}
