import { Component, OnInit, Input } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: 'app-logout-dropdown',
    templateUrl: './logout-dropdown.component.html',
    styleUrls: ['./logout-dropdown.component.scss'],
})
export class LogoutDropdownComponent implements OnInit {
    showDropdown = false;
    localStorage: Storage;
    constructor(private authService: OAuthService) {}

    ngOnInit() {}

    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
    }

    handleLogOut() {
        this.toggleDropdown();
        this.authService.logOut();
        localStorage.removeItem('firmLogo');
    }

    handleClickOutside() {
        if (this.showDropdown) {
            this.showDropdown = false;
        }
    }

    @Input() user: string;
}
