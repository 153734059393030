import { Injectable } from '@angular/core';
import { FormArray, Validators, FormGroup, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Holding } from 'src/app/models/holding.model';
import { FixedIncomeDynamicHolding } from 'src/app/models/fixed-income-dynamic-holding.model';
import { EquityDynamicHolding } from 'src/app/models/equity-dynamic-holding.model';
import { ModelCategory } from '../model-category.enum';
import { ModelHoldingCategory } from '../model-holding-category-enum';
import { dynamicHoldingValidator } from './dynamic-holding.validator';
import { EquityDynamicHoldingFormGroup, FixedIncomeDynamicHoldingFormGroup } from '../model-detail/holding-form-group';

@Injectable({
    providedIn: 'root',
})
export class ModelHoldingsDataService {
    private uploadedHoldingsStore = new BehaviorSubject({ uploaded: false, holdings: null });
    uploadedHoldings = this.uploadedHoldingsStore.asObservable();

    constructor() {}

    storeUploadedHoldings(holdings: FormArray) {
        this.uploadedHoldingsStore.next({ uploaded: true, holdings });
    }

    clearUploadedHoldings() {
        this.uploadedHoldingsStore.next({ uploaded: false, holdings: null });
    }

    getHoldingForm(category: ModelCategory, holding?: Holding): FormGroup {
        switch (category) {
            case ModelCategory.Equity: {
                return this.getEquityHoldingForm(holding as EquityDynamicHolding);
            }

            case ModelCategory.FixedIncome: {
                return this.getFixedIncomeHoldingForm(holding as FixedIncomeDynamicHolding);
            }

            case ModelCategory.Other: {
                if (holding) {
                    if (holding.category === ModelHoldingCategory.Equity) {
                        return this.getEquityHoldingForm(holding as EquityDynamicHolding);
                    }
                    if (holding.category === ModelHoldingCategory.FixedIncome) {
                        return this.getFixedIncomeHoldingForm(holding as FixedIncomeDynamicHolding);
                    }
                }
            }

            default: {
                return null;
            }
        }
    }

    getFixedIncomeHoldingForm(holding?: FixedIncomeDynamicHolding): FormGroup {
        const { id, issuer, class: incomeClass, ticker, cusip, quantity, positionStatus, remarks } = holding || {
            id: null,
            issuer: '',
            class: null,
            ticker: '',
            cusip: '',
            quantity: null,
            positionStatus: null,
            remarks: '',
        };

        return new FixedIncomeDynamicHoldingFormGroup(
            {
                id: new FormControl(id),
                issuer: new FormControl(issuer, Validators.maxLength(100)),
                class: new FormControl(incomeClass),
                ticker: new FormControl(ticker),
                cusip: new FormControl(cusip, Validators.minLength(9)),
                quantity: new FormControl(quantity, [Validators.max(100), Validators.min(0.0001)]),
                positionStatus: new FormControl(positionStatus),
                remarks: new FormControl(remarks),
            },
            {
                updateOn: 'change',
                validators: dynamicHoldingValidator,
            },
        );
    }

    getEquityHoldingForm(holding?: EquityDynamicHolding): FormGroup {
        const { id, name, ticker, cusip, quantity, positionStatus, remarks } = holding || {
            id: null,
            name: '',
            ticker: '',
            cusip: '',
            quantity: null,
            positionStatus: null,
            remarks : '',
        };

        return new EquityDynamicHoldingFormGroup(
            {
                id: new FormControl(id),
                name: new FormControl(name, Validators.maxLength(100)),
                ticker: new FormControl(ticker),
                cusip: new FormControl(cusip, Validators.minLength(9)),
                quantity: new FormControl(quantity, [Validators.max(100), Validators.min(0.0001)]),
                positionStatus: new FormControl(positionStatus),
                remarks: new FormControl(remarks),
            },
            {
                updateOn: 'change',
                validators: dynamicHoldingValidator,
            },
        );
    }
}
