import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export class PasswordValidator {
    static areNotEqual(controlNameOfOld: string, controlNameOfNew: string): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const oldValue = group.get(controlNameOfOld).value;
            const newValue = group.get(controlNameOfNew).value;

            const isAnyEmpty =
                oldValue === undefined ||
                oldValue === null ||
                oldValue === '' ||
                newValue === undefined ||
                newValue === null ||
                newValue === '';

            const valid = !isAnyEmpty && oldValue !== newValue;

            if (valid) {
                return null;
            }

            return {
                areNotEqual: true,
            };
        };
    }
}
