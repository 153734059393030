import { FormGroup, ValidatorFn, AbstractControlOptions, AsyncValidatorFn, FormControl } from '@angular/forms';

export class EquityDynamicHoldingFormGroup extends FormGroup {
    constructor(
        controls: { [key: string]: FormControl },
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super(controls, validatorOrOpts, asyncValidator);
    }
}

export class FixedIncomeDynamicHoldingFormGroup extends FormGroup {
    constructor(
        controls: { [key: string]: FormControl },
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super(controls, validatorOrOpts, asyncValidator);
    }
}
