import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-model-submitted',
    templateUrl: './model-submitted.component.html',
    styleUrls: ['./model-submitted.component.scss'],
})
export class ModelSubmittedComponent implements OnInit {

    modelName: string;
    modelIsInternal: boolean;

    constructor(private router: Router) {  }

    ngOnInit() {
        if (history.state.data) {
            this.modelName = history.state.data.modelName;
            this.modelIsInternal = history.state.data.modelIsInternal;
        }
    }

    viewAllModels() {
        this.router.navigate(['/models'], { queryParamsHandling: 'merge' });
    }
}
