import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'primary-button',
    templateUrl: './primary-button.component.html',
    styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent implements OnInit {
    constructor() {}

    ngOnInit() {}

    @Input() label: string;
    @Input() isSubmit = false;
    @Input() isDisabled = false;
    @Input() isDarkOutlined = false;
    @Input() id: string = null;
    @Output() onClick = new EventEmitter();

    onClickButton(event) {
        this.onClick.emit(event);
    }
}
