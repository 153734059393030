import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit {
    ngOnInit() {}

    @Input() parentForm: FormGroup;
    @Input() label: string;
    @Input() placeholder = '';
    @Input() id: string;
    @Input() formValidationName: string;
    @Input() canBeShown = false;

    @Output() inputChange = new EventEmitter();

    showPassword = false;
    get input() {
        return this.parentForm.get(this.formValidationName);
    }

    onTogglePassword() {
        this.showPassword = !this.showPassword;
    }

    onChange() {
        this.inputChange.emit();
    }
}
