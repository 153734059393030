import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModelService } from './model.service';

export class ModelValidators {
    static nameUnique(
        modelService: ModelService,
        { value }: AbstractControl,
        originalName: string): Observable<ValidationErrors | null> {

        const normalizedValue = (value as string).toLowerCase().trim();
        const normalizedOriginalName = originalName.toLowerCase().trim();

        if (normalizedOriginalName === normalizedValue) {
            return of(null);
        }

        return modelService.exists(normalizedValue).pipe(
            map((exists: boolean) => {
                if (exists) {
                    return {
                        nameAlreadyExists: true,
                    };
                }
                return null;
            }),
        );
    }
}
