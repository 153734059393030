import { MODEL_HOLDING_CLASSES } from '../core/constants';
import { ModelHoldingClass } from './model-holding-class.enum';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export function getClassLabel(value: ModelHoldingClass) {
    const holdingClass = MODEL_HOLDING_CLASSES.find(x => x.value === value);
    if (holdingClass) {
        return holdingClass.label
    }

    return value;
}

export const PERCENTAGE_MASK = {
    mask: createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        allowDecimal: true,
        decimalSymbol: '.',
        decimalLimit: 3,
        integerLimit: 3,
        requireDecimal: false,
        allowNegative: false,
        allowLeadingZeroes: false,
    }),
    guide: false,
};
