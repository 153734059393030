export enum ModelRegion {
    Domestic = 'Domestic',
    Global = 'Global',
    International = 'International'
}

export const MODEL_REGIONS = [
    { label: 'Domestic', value: ModelRegion.Domestic },
    { label: 'Global', value: ModelRegion.Global },
    { label: 'International', value: ModelRegion.International },
];
