import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-model-filter-dropdown',
    templateUrl: './model-filter.dropdown.component.html',
    styleUrls: ['./model-filter.dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelFilterDropdownComponent {

    @Input() isApplied = false;
    @Input() label: string;
    @Input() labelSuperscript: string;

    isOpen = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    toggleDropdown() {
        this.isOpen = !this.isOpen;
        this.changeDetectorRef.markForCheck();
    }

    handleClickOutside() {
        if (this.isOpen) {
            this.isOpen = false;
            this.changeDetectorRef.markForCheck();
        }
    }
}
