const RegexConstants = {
    PhoneNumber: /\d{3}(?=\d{2,3})|\d+/g,
};

export function removeSymbolsFromPhone(phoneNumber: string): string {
    return phoneNumber && phoneNumber.replace(/-/g, '');
}

export function removeSymbolsFromCountryCode(phoneCountryCode: string): string {
    return phoneCountryCode && phoneCountryCode.replace('+', '');
}

export function addDashesToPhone(phoneNumber: string): string {
    return phoneNumber && phoneNumber.match(RegexConstants.PhoneNumber).join('-');
}
