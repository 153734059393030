import { Component, OnInit, Input } from '@angular/core';
import { ModelDetailsViewModel } from 'src/app/models/model.model';
import { ModelAuthorizationService } from 'src/app/model-pages/model-authorization.service';

@Component({
    selector: 'app-model-of-models-description',
    templateUrl: './model-of-models-description.component.html',
    styleUrls: ['./model-of-models-description.component.scss'],
})
export class ModelOfModelsDescriptionComponent implements OnInit {

    @Input() model: ModelDetailsViewModel;

    constructor(private modelAuthorizationService: ModelAuthorizationService) {  }

    ngOnInit() {  }

    isModelEditable(): boolean {
        return this.modelAuthorizationService.isModelEditable(this.model);
    }
}
