import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'button-link',
    templateUrl: './button-link.component.html',
    styleUrls: ['./button-link.component.scss'],
})
export class ButtonLinkComponent implements OnInit {
    constructor() {}

    ngOnInit() {}

    @Input() label: string;
    @Input() isInverted = false;
    @Input() isUnderlined = false;
    @Input() isBold = false;
    @Input() disabled = false;
    @Input() id: string = null;
    @Output() onClick = new EventEmitter();

    onClickButton(event) {
        this.onClick.emit(event);
    }
}
