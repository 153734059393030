import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { FIRM_TYPES, FirmType } from 'src/app/core/firm-type';
import { PortfolioManagementFirmListViewModel } from 'src/app/models/firm.model';
import { AppPermissions } from 'src/app/core/permissions';
import { PortfolioManagementFirmService } from '../portfoliomanagerfirm.service';
import { FIRM_BATCH_TRANSFER_TYPES, FirmBatchTransferType } from 'src/app/core/firm-batch-transfer-type'

@Component({
    selector: 'app-list-authorize-firms',
    templateUrl: './list-authorize-firms.component.html',
    styleUrls: ['./list-authorize-firms.component.scss'],
})
export class ListAuthorizeFirmComponent implements OnInit, OnDestroy {
    AppPermissions = AppPermissions;
    firms: PortfolioManagementFirmListViewModel[];

    private subscription = new Subscription();

    constructor(
        private portfolioManagementService: PortfolioManagementFirmService,
        private messageBoxService: MessageBoxService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.subscription.add(
            this.portfolioManagementService.getFirms().subscribe(
                firmList => {
                    this.firms = firmList;
                },
                () => this.messageBoxService.error('firm-not-exists-error'),
            ),
        );
    }

    onActivate(e) {
        if (e.type === 'click' && e.column.prop === 'firmName') {
            this.router.navigate(['admin/firms/list-authorize-models', e.row.id]);
        }
    }

    getNullableLabel(value: string) {
        return value ? value : '—';
    }

    getFirmTypeLabel(value: FirmType) {
        const firmType = FIRM_TYPES.find(x => x.value === value);
        if (firmType) {
            return firmType.label;
        }
        return '';
    }

    getFirmBatchTransferTypeLabel(value: FirmBatchTransferType) {
        const firmBatchTransferType = FIRM_BATCH_TRANSFER_TYPES.find(x => x.value === value);
        if (firmBatchTransferType) {
            return firmBatchTransferType.label;
        }
        return '';
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
