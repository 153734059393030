import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {
    ngOnInit() { }

    @Input() parentForm: FormGroup;
    @Input() label: string = null;
    @Input() placeholder = '';
    @Input() id: string;
    @Input() errorMessage: string;
    @Input() formValidationName: string;
    @Input() type: string;
    @Input() isNarrow: boolean;
    @Input() maxLength = 500;
    @Input() limitCharset = null;
    @Input() mask = null;
}
