import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-mmodel-pages',
    templateUrl: 'model-pages.component.html',
})
export class ModelPagesComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
