import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder  } from '@angular/forms';
import { ModelHoldingCategory } from '../../model-holding-category-enum';
import { Subscription } from 'rxjs';
import { ModelCategory } from '../../model-category.enum';
import { MODEL_CATEGORIES } from 'src/app/core/constants';
import { ModelHoldingsDataService } from '../model-holdings.data.service';
import { FixedIncomeDynamicHoldingFormGroup, EquityDynamicHoldingFormGroup }
    from '../../model-detail/holding-form-group';
import { sumQuantity } from '../../holdings-quantity.helper';

@Component({
    selector: 'app-other-blended-holdings',
    templateUrl: './other-blended-holdings.component.html',
    styleUrls: ['../column-sizes.edit.scss'],
})
export class OtherBlendedHoldingsComponent implements OnInit, OnDestroy {

    @Input() holdings: FormArray;
    @Input() initialRowCount: number;
    @Input() uploadedHoldings: boolean;

    ModelCategory = ModelCategory;
    ModelHoldingCategory = ModelHoldingCategory;

    equityHoldings: FormArray;
    fixedIncHoldings: FormArray;

    private subscription = new Subscription();

    constructor(private formBuilder: FormBuilder,
        private holdingsDataService: ModelHoldingsDataService ) {  }

    ngOnInit() {
        this.splitHoldings();

        if (this.uploadedHoldings) {
            this.holdings.controls.forEach(holdingControl => {
                holdingControl.markAsDirty();
                holdingControl.markAllAsTouched();
            });
        } else {
            this.addBlankRows();
            this.holdings.markAsPristine();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    removeHolding(i: number, type: ModelHoldingCategory) {
        if (type === ModelHoldingCategory.Equity) {
            this.equityHoldings.markAsDirty();
            this.equityHoldings.removeAt(i);
        }
        if (type === ModelHoldingCategory.FixedIncome) {
            this.fixedIncHoldings.markAsDirty();
            this.fixedIncHoldings.removeAt(i);
        }
    }

    addNewRow(type: ModelHoldingCategory) {
        if (type === ModelHoldingCategory.Equity) {
            this.addEmptyEquityHolding();
        }
        if (type === ModelHoldingCategory.FixedIncome) {
            this.addEmptyFixedIncomeHolding();
        }
    }

    getCategoryLabel(type: ModelCategory): string {
        const category = MODEL_CATEGORIES.find(x => x.value === type);
        if (category) {
            return category.label;
        }

        return type;
    }

    get totalEquityPercentage() {
        return sumQuantity(this.equityHoldings.value);
    }

    get totalFixedIncomePercentage() {
        return sumQuantity(this.fixedIncHoldings.value);
    }

    private splitHoldings() {
        this.equityHoldings = this.formBuilder.array([]);
        this.fixedIncHoldings = this.formBuilder.array([]);

        this.holdings.controls.forEach(holdingControl => {
            if (!holdingControl) {
                return;
            }

            if (holdingControl instanceof EquityDynamicHoldingFormGroup) {
                this.equityHoldings.push(holdingControl);
            }
            if (holdingControl instanceof FixedIncomeDynamicHoldingFormGroup) {
                this.fixedIncHoldings.push(holdingControl);
            }
        });

        this.subscription.add(this.equityHoldings.valueChanges.subscribe(
            () => this.shareHoldingsWithParent(),
        ));

        this.subscription.add(this.fixedIncHoldings.valueChanges.subscribe(
            () => this.shareHoldingsWithParent(),
        ));
    }

    private shareHoldingsWithParent() {
        this.holdings.controls = this.equityHoldings.controls.concat(this.fixedIncHoldings.controls);
        this.holdings.setValue(this.equityHoldings.value.concat(this.fixedIncHoldings.value));
        this.holdings.markAsDirty();
    }

    private addBlankRows() {
        for (let i = this.equityHoldings.length; i < this.initialRowCount; i++) {
            this.addEmptyEquityHolding();
        }

        for (let i = this.fixedIncHoldings.length; i < this.initialRowCount; i++) {
            this.addEmptyFixedIncomeHolding();
        }
    }

    private addEmptyEquityHolding() {
        this.equityHoldings.push(this.holdingsDataService.getEquityHoldingForm());
    }

    private addEmptyFixedIncomeHolding() {
        this.fixedIncHoldings.push(this.holdingsDataService.getFixedIncomeHoldingForm());
    }
}
