import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-model-statistic-placeholder',
    templateUrl: './model-statistic-placeholder.component.html',
    styleUrls: ['./model-statistic-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelStatisticPlaceholderComponent implements OnInit {
    @Input() title: string;
    @Input() content: string;

    constructor() { }

    ngOnInit() {
    }
}
