import { Component, OnDestroy, OnInit, Input, ElementRef } from '@angular/core';
import { MessageBoxService, MessageBox } from '../message-box.service';

@Component({
    selector: 'app-error-message',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy, MessageBox {
    @Input() id: string;
    @Input() title: string;

    private element: HTMLElement;

    constructor(private messageService: MessageBoxService, el: ElementRef<HTMLElement>) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        if (!this.id) {
            return;
        }

        this.messageService.add(this);
    }

    open(): void {
        this.element.style.display = 'block';
    }

    close(): void {
        this.element.style.display = 'none';
    }

    ngOnDestroy(): void {
        this.messageService.remove(this.id);
        this.element.remove();
    }
}
