import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { WarnOnLeaveGuard } from './../shared/guards/warn-on-leave.guard';
import { CreateFirmComponent } from './create-firm/create-firm.component';
import { ListFirmsComponent } from './list-firms/list-firms.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { AdminComponent } from './admin.component';
import { ListUsersComponent } from './list-users/list-users.component';
import { AppPermissions } from '../core/permissions';
import { EditUserComponent } from './edit-user/edit-user.component';
import { EditFirmComponent } from './edit-firm/edit-firm.component';
import { ListAuthorizeFirmComponent } from './list-authorize-firm/list-authorize-firms.component';
import { ModelBatchAuthorizeTableComponent } from './list-authorize-model/model-authorize-table.component'

const adminRoutes: Routes = [
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'firms',
            },
            {
                path: 'firms',
                component: ListFirmsComponent,
                data: {
                    permission: AppPermissions.ViewFirms,
                },
            },
            {
                path: 'firms/create',
                component: CreateFirmComponent,
                canDeactivate: [WarnOnLeaveGuard],
                data: {
                    permission: AppPermissions.CrudFirm,
                },
            },
            {
                path: 'firms/list-authorize-firms',
                component: ListAuthorizeFirmComponent,
                data: {
                    permission: AppPermissions.CrudFirm,
                },
            },
            {
                path: 'firms/list-authorize-models/:firmId',
                component: ModelBatchAuthorizeTableComponent,
                data: {
                    permission: AppPermissions.CrudFirm,
                },
            },
            {
                path: 'firms/firm-edit/:firmId',
                component: EditFirmComponent,
                canDeactivate: [WarnOnLeaveGuard],
                data: {
                    permission: AppPermissions.CrudFirm,
                },
            },
            {
                path: 'firms/:firmId',
                component: ListUsersComponent,
                data: {
                    permission: AppPermissions.ViewFirmDetails,
                },
            },
            {
                path: 'firms/:firmId/user-create',
                component: CreateUserComponent,
                canDeactivate: [WarnOnLeaveGuard],
                data: {
                    permission: AppPermissions.CreateUser,
                },
            },
            {
                path: 'firms/:firmId/user-edit/:userId',
                component: EditUserComponent,
                canDeactivate: [WarnOnLeaveGuard],
                data: {
                    permission: AppPermissions.EditUser,
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(adminRoutes)],
    exports: [RouterModule],
})
export class AdminRoutingModule { }
