import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MODEL_HOLDING_CLASSES } from 'src/app/core/constants';
import { getClassLabel } from 'src/app/model-pages/model-holding-shared';
import { ModelHoldingClass } from 'src/app/model-pages/model-holding-class.enum';

@Component({
    selector: 'app-fixed-dynamic-holding-qty-edit',
    templateUrl: './fixed-dynamic-holding-qty-edit.component.html',
    styleUrls: ['./fixed-dynamic-holding-qty-edit.component.scss',
        '../../../../model-holdings/column-sizes.edit.scss',
        '../model-detail-holdings-edit.component.scss'],
})
export class FixedDynamicHoldingQtyEditComponent implements OnInit {
    @Input() holdingForm: FormGroup;
    @Input() index: number = null;
    @Input() editWholeRow = false;
    @Output() onDelete = new EventEmitter();
    @Input() positions = [];

    modelClasses = MODEL_HOLDING_CLASSES;

    constructor() {
        this.positions = ['B', 'H'];
    }

    ngOnInit() {}

    fieldHasError(key: string, errorType: string) {
        const field = this.holdingForm.get(key);
        if (field) {
            return field.touched && field.hasError(errorType);
        }

        return false;
    }

    getClassLabel(value: ModelHoldingClass) {
        return getClassLabel(value);
    }

    deleteSelf() {
        this.onDelete.emit();
    }
}
