import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-radio-select',
    templateUrl: './radio-select.component.html',
    styleUrls: ['./radio-select.component.scss'],
})
export class RadioSelectComponent implements OnInit {

    @Input() formValidationName: string;
    @Input() parentForm: FormGroup;
    @Input() label: string;
    @Input() id: string;
    @Input() data: [{value: number; label: string}];
    @Input() disabled: boolean;

    constructor() {  }

    ngOnInit() {  }
}
