import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-holdings-total-bar',
    templateUrl: './holdings-total-bar.component.html',
    styleUrls: ['./holdings-total-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoldingsTotalBarComponent implements OnInit {

    @Input() quantity: number;
    @Input() isFixedHoldings = false;
    @Input() isInEditMode = false;
    @Input() isModelOfModels = false;

    constructor() {  }

    ngOnInit() {  }

}
