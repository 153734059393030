import { Component, OnInit, OnDestroy } from '@angular/core';
import { validationLabels } from './password-change-form/validations-labels';
import { Passwords } from './passwords';
import { ChangePasswordService } from 'src/app/user-profile/password-change/change-password.service';
import { CheckItem } from 'src/app/shared/lists/check-list/check-item';
import { ChangePasswordResponse } from 'src/app/user-profile/password-change/update-response';
import { MessageBoxService } from '../../shared/messages/message-box.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'change-password',
    templateUrl: './password-change.component.html',
    styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit, OnDestroy {
    validations: CheckItem[];
    passwords: Passwords;
    shownError: string = null;
    cleanPasswords = false;

    private subscription = new Subscription();

    constructor(
        private changePasswordService: ChangePasswordService,
        private messageService: MessageBoxService,
    ) { }

    ngOnInit() {
        this.validations = validationLabels.map(validation => {
            return { label: validation.message, checked: false };
        });
    }

    updateValidations(validations: CheckItem[]) {
        this.validations = validations;
    }

    passwordsChanged(passwords: Passwords) {
        this.passwords = passwords;
    }

    onSubmit() {
        this.subscription.add(
            this.changePasswordService.changePassword(this.passwords).subscribe(
                (response: ChangePasswordResponse) => {
                    if (response.successful) {
                        this.messageService.success('password-change-section-success');
                        this.cleanPasswords = true;
                    } else {
                        this.shownError = response.message;
                        this.messageService.error('password-change-section-error');
                    }
                },
                () => {
                    this.shownError = null;
                    this.messageService.error('password-change-section-error');
                },
            ));
    }

    get passwordsValid() {
        return this.validations.every(validation => validation.checked);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
