import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { apiUrl } from 'src/app/core/constants';
import { ModelHistory } from 'src/app/models/model.history';

@Injectable({
    providedIn: 'root',
})
export class ModelHistoryService {
    constructor(private http: HttpClient) {}

    public getHistory(modelId: string, fromDate: any, thruDate: any): Observable<ModelHistory[]> {
        return this.http.get<ModelHistory[]>(`${apiUrl}/api/v1/models/${modelId}/history?modelId=${modelId}&fromDate=${fromDate}&thruDate=${thruDate}`);
    }
}
