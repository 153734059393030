import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'centered-form-layout',
    templateUrl: './centered-form-layout.component.html',
    styleUrls: ['./centered-form-layout.component.scss'],
})
export class CenteredFormLayoutComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    @Input() containerWidthPixels = 1100;
    @Input() hasDecorator = true;
}
