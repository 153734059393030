import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ConfirmDialogService, Confirmable } from './confirm-dialog.service';
import { Observable, Subject } from 'rxjs';
import { tap, take } from 'rxjs/operators';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit, OnDestroy, Confirmable {
    @Input() id: string;
    @Input() title: string;
    @Input() confirmButtonLabel: string;
    @Input() cancelButtonLabel: string;
    @Input() closeAction: 'Confirm' | 'Cancel' = 'Cancel';

    isOpen = false;

    private confirmSubject = new Subject<boolean>();

    constructor(private confirmService: ConfirmDialogService) {}

    ngOnInit() {
        if (!this.id) {
            return
        }

        this.confirmService.add(this);
    }

    confirmAction() {
        this.confirmSubject.next(true);
    }

    cancelAction() {
        this.confirmSubject.next(false);
    }

    close() {
        if (this.closeAction === 'Cancel') {
            this.cancelAction();
        }
        if (this.closeAction === 'Confirm') {
            this.confirmAction();
        }
    }

    confirm(): Observable<boolean> {
        this.isOpen = true;
        return this.confirmSubject.asObservable().pipe(take(1), tap(() => {
            this.isOpen = false;
        }));
    }

    ngOnDestroy(): void {
        this.confirmService.remove(this);
        this.confirmSubject.complete();
        this.confirmSubject.unsubscribe();
    }
}
