import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { apiUrl } from '../core/constants';
import { User, UserList } from '../models/user.model';
import { UserContext } from './../core/user-context';
import { removeSymbolsFromCountryCode, removeSymbolsFromPhone, addDashesToPhone } from '../shared/phoneFormatter';
import { map } from 'rxjs/operators';
import { FirmLogo } from '../core/firmLogo';


@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private http: HttpClient) { }

    public createUser(user: User, firmId: string): Observable<void> {
        return this.http.post<void>(`${apiUrl}/api/v1/firm/${firmId}/user`, this.fixPhoneNumber(user));
    }

    public editUser(user: User, firmId: string, userId: string): Observable<void> {
        return this.http.put<void>(`${apiUrl}/api/v1/firm/${firmId}/user/${userId}`, this.fixPhoneNumber(user));
    }

    public getUser(firmId: string, userId: string): Observable<User> {
        return this.http.get<User>(`${apiUrl}/api/v1/firm/${firmId}/user/${userId}`)
            .pipe(
                map(response => ({
                    ...response,
                    workPhone: addDashesToPhone(response.workPhone),
                })),
            );
    }

    public lockUser(firmId: string, userId: string){
        return this.http.put<void>(`${apiUrl}/api/v1/firm/${firmId}/user/${userId}/lock-unlock`,
            { 'Id': userId, 'isLock': true });
    }

    public unLockUser(firmId: string, userId: string){
        return this.http.put<void>(`${apiUrl}/api/v1/firm/${firmId}/user/${userId}/lock-unlock`,
            { 'Id': userId, 'isLock': false });
    }

    public enable2F(firmId: string): Observable<void> {
        return this.http.put<void>(`${apiUrl}/api/v1/firm/${firmId}/user/enable2F`, { 'firmId': firmId });
    }

    public disable2F(firmId: string): Observable<void> {
        return this.http.put<void>(`${apiUrl}/api/v1/firm/${firmId}/user/disable2F`, { 'firmId': firmId });
    }

    public getUsers(firmId: string): Observable<UserList> {
        return this.http.get<UserList>(`${apiUrl}/api/v1/firm/${firmId}/user`);
    }

    public getCurrentUserContext(): Observable<UserContext> {
        return this.http.get<UserContext>(`${apiUrl}/api/v1/user-context`);
    }

    public deactivateUser(userId: string, firmId: string) {
        return this.http.post(`${apiUrl}/api/v1/firm/${firmId}/user/${userId}/deactivate`, {});
    }

    public getFirmLogoByFirmId(userId: string, firmId: string): Observable<FirmLogo>{
        return this.http.get<FirmLogo>(`${apiUrl}/api/v1/firm/${firmId}/user/${userId}/firmLogo`);
    }


    private fixPhoneNumber(user: User): User {
        return {
            ...user,
            workPhoneCountryCode: removeSymbolsFromCountryCode(user.workPhoneCountryCode),
            workPhone: removeSymbolsFromPhone(user.workPhone),
        };
    }


}
