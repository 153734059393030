import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ModelListFilterService } from '../../model-list.filter.service';
import { FILTER_INPUT_DEBOUNCE } from '../model-filters.constants';

@Component({
    selector: 'app-model-filter-sharpe-ratio',
    templateUrl: './model-filter.sharpe-ratio.component.html',
    styleUrls: ['./model-filter.sharpe-ratio.component.scss'],

})
export class ModelFilterSharpeRatioComponent implements OnInit, OnDestroy {
    value: string;
    valueChange = new Subject<string>();
    private subscription = new Subscription();

    constructor(private modelListFilterService: ModelListFilterService) { }

    ngOnInit() {
        this.subscription.add(
            this.modelListFilterService.filtersObservable.subscribe(filters => {
                this.value = filters.sharpeRatio;
            }),
        );

        this.subscription.add(
            this.valueChange
                .pipe(
                    debounceTime(FILTER_INPUT_DEBOUNCE),
                )
                .subscribe(value => {
                    this.modelListFilterService.filterBySharpeRatio(value);
                }),
        );

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
