import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    Model, CreateModelRequest, ModelListViewModel, ModelStatistics, UpdateHoldingsModel,
    ModelDetailsEditModel, ModelDetailsCreateModel, SleeveModel, UpdateModelResearchModel,
} from '../models/model.model';
import { apiUrl } from '../core/constants';
import { ModelSleeveViewModel } from '../models/model-sleeve.model';

@Injectable({
    providedIn: 'root',
})
export class ModelService {
    constructor(private http: HttpClient) {}

    public createModel(model: CreateModelRequest): Observable<string> {
        return this.http.post<string>(`${apiUrl}/api/v1/models`, model);
    }

    public getModels(includeDraft: string,
        includeDeactivated: string): Observable<ModelListViewModel[]> {
        const params = new HttpParams({
            fromObject: {
                includeDraft: includeDraft,
                includeDeactivated: includeDeactivated,
            },
        });
        return this.http.get<ModelListViewModel[]>(`${apiUrl}/api/v1/models`, { params: params });
    }

    public getBatchTransferModelsByFirm(firmId: string): Observable<ModelListViewModel[]> {
        const params = new HttpParams({
            fromObject: {
                firmId: firmId,
            },
        });
        return this.http.get<ModelListViewModel[]>(`${apiUrl}/api/v1/models/batchTransfer/`, { params: params });
    }

    public getSleeveModels(): Observable<SleeveModel[]> {
        return this.http.get<SleeveModel[]>(`${apiUrl}/api/v1/sleeveModels`);
    }

    public getSleeveModelsForModel(modelId: string): Observable<ModelSleeveViewModel[]> {
        return this.http.get<ModelSleeveViewModel[]>(`${apiUrl}/api/v1/sleeveModels/${modelId}`);
    }

    public getModel(id: string): Observable<Model> {
        return this.http.get<Model>(`${apiUrl}/api/v1/models/${id}`);
    }

    public exportModelHoldings(id: string): Observable<Blob> {
        return this.http.get<Blob>(
            `${apiUrl}/api/v1/models/${id}/holdings/download`,
            { responseType: 'blob' as 'json' },
        );
    }

    public updateModelDetailsInCreationFlow(model: ModelDetailsCreateModel): Observable<void> {
        return this.http.put<void>(`${apiUrl}/api/v1/models/${model.id}/create`, model);
    }

    public updateModelDetails(model: ModelDetailsEditModel): Observable<void> {
        return this.http.put<void>(`${apiUrl}/api/v1/models/${model.id}/edit`, model);
    }

    public updateModelHoldings(model: UpdateHoldingsModel): Observable<void> {
        return this.http.put<void>(`${apiUrl}/api/v1/models/${model.modelId}/holdings`, model);
    }

    public submit(modelId: string): Observable<void> {
        return this.http.put<void>(`${apiUrl}/api/v1/models/${modelId}/submit`, null);
    }

    public approve(modelId: string): Observable<void> {
        return this.http.put<void>(`${apiUrl}/api/v1/models/${modelId}/approve`, null);
    }

    public deactivate(modelId: string): Observable<void> {
        return this.http.put<void>(`${apiUrl}/api/v1/models/${modelId}/de-activate`, null);
    }

    public pendingAuthorize(modelId: string): Observable<void> {
        return this.http.put<void>(`${apiUrl}/api/v1/models/${modelId}/pending/authorize`, null);
    }

    public authorize(firmId: string, modelId: string): Observable<void> {
        const model = {
            firmId: firmId,
            modelId: modelId,
        };
        return this.http.put<void>(`${apiUrl}/api/v1/models/authorize`,  model);
    }

    public deauthorize(firmId: string, modelId: string): Observable<void> {
        const model = {
            firmId: firmId,
            modelId: modelId,
        };
        return this.http.put<void>(`${apiUrl}/api/v1/models/deauthorize`,  model);
    }

    public getModelStatistics(modelId: string): Observable<ModelStatistics> {
        return this.http.get<ModelStatistics>(`${apiUrl}/api/v1/models/${modelId}/statistics`);
    }

    public subscribe(modelId: string): Observable<void> {
        return this.http.put<void>(`${apiUrl}/api/v1/models/${modelId}/subscribe`, null);
    }

    public unsubscribe(modelId: string): Observable<void> {
        return this.http.put<void>(`${apiUrl}/api/v1/models/${modelId}/unsubscribe`, null);
    }

    public uploadModelHoldingsFile(modelId: string, file: File): Observable<Blob> {
        const formData: FormData = new FormData();
        formData.append('formFile', file);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return this.http.post<Blob>(`${apiUrl}/api/v1/models/upload-holdings/${modelId}`,
            formData, {
                headers,
                responseType: 'blob' as 'json',
            });
    }

    public uploadModelStatisticsFile(file: File): Observable<Blob> {
        const formData: FormData = new FormData();
        formData.append('formFile', file);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return this.http.post<Blob>(`${apiUrl}/api/v1/models/upload-statistics`, formData, {
            headers,
            responseType: 'blob' as 'json',
        });
    }

    public exists(name: string): Observable<boolean> {
        const params = new HttpParams({
            fromObject: {
                name: name,
            },
        });
        return this.http.get<boolean>(`${apiUrl}/api/v1/models/exists`, { params: params });
    }

    public updateResearched(model: UpdateModelResearchModel): Observable<boolean> {
        return this.http.put<boolean>(`${apiUrl}/api/v1/models/${model.id}/researched`, model);
    }
}
