import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { addDashesToPhone } from 'src/app/shared/phoneFormatter';
import { FIRM_TYPES, FirmType } from 'src/app/core/firm-type';
import { FirmListItemViewModel } from 'src/app/models/firm.model';
import { AppPermissions } from 'src/app/core/permissions';
import { FirmService } from '../firm.service';

const FIRM_TYPES_SORT = {
    [FirmType.Client]: 1,
    [FirmType.AssetManager]: 2,
    [FirmType.IcrPartners]: 3,
};

@Component({
    selector: 'app-list-firms',
    templateUrl: './list-firms.component.html',
    styleUrls: ['./list-firms.component.scss'],
})
export class ListFirmsComponent implements OnInit, OnDestroy {
    AppPermissions = AppPermissions;
    firms: FirmListItemViewModel[];

    private subscription = new Subscription();

    constructor(
        private firmService: FirmService,
        private messageBoxService: MessageBoxService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.firmService.getFirms().subscribe(
                firmList => {
                    this.firms = firmList;
                    this.firms.forEach(firm => {
                        firm.businessPhone = this.getFullPhone(firm);
                    });
                },
                () => this.messageBoxService.error('firm-not-exists-error'),
            ),
        );
    }

    onActivate(e) {
        if (e.type === 'click' && e.column.prop === 'firmName') {
            this.router.navigate(['admin/firms/', e.row.id]);
        }
    }

    getBooleanLabel(value: boolean){
        return value ? 'YES' : 'NO';
    }

    getNullableLabel(value: string) {
        return value ? value : '—';
    }

    getFirmTypeLabel(value: FirmType) {
        const firmType = FIRM_TYPES.find(x => x.value === value);
        if (firmType) {
            return firmType.label;
        }
        return '';
    }

    getFullPhone(firm: FirmListItemViewModel) {
        if (!firm || !firm.businessPhone) {
            return '';
        }

        let fullPhone = addDashesToPhone(firm.businessPhone);

        if (firm.businessPhoneCountryCode && firm.businessPhoneCountryCode.length > 0) {
            fullPhone = `+${firm.businessPhoneCountryCode} ${fullPhone}`;
        }

        return fullPhone;
    }

    redirectToWebsite(website: string) {
        window.open(website, '_blank');
    }

    firmTypeComparator(valueA: FirmType, valueB: FirmType) {
        if (valueA === valueB) {
            return 0;
        }

        if (FIRM_TYPES_SORT[valueA] > FIRM_TYPES_SORT[valueB]) {
            return 1;
        }

        if (FIRM_TYPES_SORT[valueA] < FIRM_TYPES_SORT[valueB]) {
            return -1;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
