import { Component, OnInit, Input } from '@angular/core';
import { FixedIncomeDynamicHolding } from 'src/app/models/fixed-income-dynamic-holding.model';
import { sumQuantity } from '../../holdings-quantity.helper';

@Component({
    selector: 'app-fixed-income-dynamic-review',
    templateUrl: './fixed-income-dynamic-review.component.html',
    styleUrls: ['./fixed-income-dynamic-review.component.scss', '../review-model.component.scss'],
})
export class FixedIncomeDynamicReviewComponent implements OnInit {
    @Input() holdings: FixedIncomeDynamicHolding[];
    constructor() {}

    ngOnInit() {}

    get totalQuantity() {
        return sumQuantity(this.holdings)
    }
}
