import { FirmType } from 'src/app/core/firm-type';
import { Roles } from 'src/app/core/roles';

export const AVAILABLE_ROLES_BY_FIRM = [
    {
        firmType: FirmType.IcrPartners,
        roles: [Roles.Research, Roles.ICRAdmin],
    },
    {
        firmType: FirmType.AssetManager,
        roles: [Roles.AssetManager, Roles.FirmAdmin],
    },
    {
        firmType: FirmType.Client,
        roles: [Roles.PortfolioManager, Roles.SeniorPortfolioManager, Roles.Compliance, Roles.FirmAdmin],
    },
];

export const INCOMPATIBLE_ROLES = [
    {
        affectedRoles: [Roles.PortfolioManager],
        disabledBy: [Roles.SeniorPortfolioManager, Roles.Compliance, Roles.FirmAdmin],
    },
    {
        affectedRoles: [Roles.SeniorPortfolioManager, Roles.Compliance, Roles.FirmAdmin],
        disabledBy: [Roles.PortfolioManager],
    },
];
