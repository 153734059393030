import { ModelRegion } from 'src/app/core/model-region';
import { ModelStyleClass } from 'src/app/core/model-style-class';
import { ModelCategory } from '../model-category.enum';
import { filterLabel } from './model-list.labels';

export const FILTER_ASSET_CLASS = {
    1: ModelCategory.Equity,
    2: ModelCategory.FixedIncome,
    3: ModelCategory.Other,
};

export const FILTER_REGION = {
    1: ModelRegion.Domestic,
    2: ModelRegion.Global,
    3: ModelRegion.International,
};

export const FILTER_STYLE_CLASS = {
    '1': ModelStyleClass.Municipal,
    '2': ModelStyleClass.Taxable,
    '3': ModelStyleClass.LargeCapValue,
    '4': ModelStyleClass.LargeCapBlended,
    '5': ModelStyleClass.LargeCapGrowth,
    '6': ModelStyleClass.MediumCapValue,
    '7': ModelStyleClass.MediumCapBlended,
    '8': ModelStyleClass.MediumCapGrowth,
    '9': ModelStyleClass.SmallCapValue,
    '10': ModelStyleClass.SmallCapBlended,
    '11': ModelStyleClass.SmallCapGrowth,
    '12': ModelStyleClass.Value,
    '13': ModelStyleClass.Blended,
    '14': ModelStyleClass.Growth,
};

export class ModelFilters {
    firmName: string;
    assetClass = 0;
    assetClassValue: ModelCategory;
    region = 0;
    regionValue: ModelRegion;
    styleClasses: string;
    styleClassValues: ModelStyleClass[];
    managerFees: string;
    managerFeesValue: number;
    alpha: string;
    alphaValue: number;
    sharpeRatio: string;
    sharpeRatioValue: number;
    betaFrom: string;
    betaFromValue: number;
    betaTo: string;
    betaToValue: number;
    rSquaredFrom: string;
    rSquaredFromValue: number;
    rSquaredTo: string;
    rSquaredToValue: number;
    subscribedOnly: boolean;

    private allRegions = Object.keys(FILTER_REGION).map(key => {
        return { value: Number(key), label: filterLabel.region(FILTER_REGION[key]) };
    });
    availableRegions = [];

    setName(value: string) {
        this.firmName = value ? decodeURI(value) : '';
    }

    setAssetClass(value: string) {
        this.assetClass = this.getNumeric(value, 0);
        this.assetClassValue = FILTER_ASSET_CLASS[this.assetClass];

        this.availableRegions =
            this.assetClassValue === ModelCategory.Equity || this.assetClassValue === ModelCategory.FixedIncome
                ? this.allRegions
                : undefined;

        // reset selected Region if it's not applicable anymore
        if (this.region && (!this.availableRegions || !this.availableRegions.find(r => r.value === this.region))) {
            this.setRegion(undefined);
        }

        // reset selected Style Classes
        if (this.styleClasses) {
            this.setStyleClass(undefined);
        }
    }

    setRegion(value: string) {
        if (this.assetClassValue === undefined) {
            this.region = undefined;
            this.regionValue = undefined;
            return;
        }

        this.region = this.getNumeric(value, 0);
        this.regionValue = FILTER_REGION[this.region];

        // reset selected Style Classes
        if (this.styleClasses) {
            this.setStyleClass(undefined);
        }
    }

    setStyleClass(value: string) {
        if (this.assetClassValue === undefined || this.regionValue === undefined) {
            this.styleClasses = undefined;
            this.styleClassValues = undefined;
            return;
        }

        this.styleClasses = value;
        if (value) {
            const styleClasses = value.split(',').filter(v => v);
            this.styleClassValues = styleClasses.map(styleClass => FILTER_STYLE_CLASS[styleClass]).filter(sc => sc);
        } else {
            this.styleClassValues = undefined;
        }
    }

    setManagerFees(value: string) {
        this.managerFees = value;
        this.managerFeesValue = this.getNumeric(value);
    }

    setAlpha(value: string) {
        this.alpha = value;
        this.alphaValue = this.getNumeric(value);
    }

    setSharpeRatio(value: string) {
        this.sharpeRatio = value;
        this.sharpeRatioValue = this.getNumeric(value);
    }

    setBetaFrom(value: string) {
        this.betaFrom = value;
        this.betaFromValue = this.getNumeric(value);

        if (
            this.betaToValue !== undefined &&
            this.betaFromValue !== undefined &&
            this.betaFromValue > this.betaToValue
        ) {
            this.betaFromValue = this.betaToValue;
            this.betaFrom = this.betaToValue.toString();
        }
    }

    setBetaTo(value: string) {
        this.betaTo = value;
        this.betaToValue = this.getNumeric(value);

        if (
            this.betaToValue !== undefined &&
            this.betaFromValue !== undefined &&
            this.betaFromValue > this.betaToValue
        ) {
            this.betaToValue = this.betaFromValue;
            this.betaTo = this.betaFromValue.toString();
        }
    }

    setRSquaredFrom(value: string) {
        this.rSquaredFrom = value;
        this.rSquaredFromValue = this.getNumeric(value);

        if (
            this.rSquaredToValue !== undefined &&
            this.rSquaredFromValue !== undefined &&
            this.rSquaredFromValue > this.rSquaredToValue
        ) {
            this.rSquaredFromValue = this.rSquaredToValue;
            this.rSquaredFrom = this.rSquaredToValue.toString();
        }
    }

    setRSquaredTo(value: string) {
        this.rSquaredToValue = this.getNumeric(value);
        this.rSquaredTo = value;

        if (
            this.rSquaredToValue !== undefined &&
            this.rSquaredFromValue !== undefined &&
            this.rSquaredFromValue > this.rSquaredToValue
        ) {
            this.rSquaredToValue = this.rSquaredFromValue;
            this.rSquaredTo = this.rSquaredFromValue.toString();
        }
    }

    setSubscribedOnly(value: string) {
        this.subscribedOnly = value === 'true';
    }

    private getNumeric(value: string, defaultValue: number = undefined): number {
        const valueNumeric = Number(value);
        return !value || Number.isNaN(valueNumeric) ? defaultValue : valueNumeric;
    }
}
