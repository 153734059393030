export enum ResearchCategory {
    AnalystCommentary = 'AnalystCommentary',
    FactSheet = 'FactSheet',
    ManagerQuestionnaire = 'ManagerQuestionnaire',
    Marketing = 'Marketing',
    MarketCommentary = 'MarketCommentary',
    Other = 'Other'
}

export const RESEARCH_CATEGORIES: { label: string; value: string }[] = [
    { label: 'Analyst Commentary', value: ResearchCategory.AnalystCommentary },
    { label: 'Fact Sheet', value: ResearchCategory.FactSheet },
    { label: 'Manager Questionnaire', value: ResearchCategory.ManagerQuestionnaire },
    { label: 'Marketing', value: ResearchCategory.Marketing },
    { label: 'Market Commentary', value: ResearchCategory.MarketCommentary },
    { label: 'Other', value: ResearchCategory.Other },
];
