import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModelResearchItem } from 'src/app/models/model-research-item.model';
import { ModelResearchFile } from 'src/app/models/model-research-file.model';

@Injectable({
    providedIn: 'root',
})
export class ResearchStateManagementService {

    private researchUpdateSource = new Subject<ModelResearchItem>();
    researchUpdated = this.researchUpdateSource.asObservable();

    private filesUpdateSource = new Subject<ModelResearchFile[]>();
    filesUpdated = this.filesUpdateSource.asObservable();

    private filesRefreshSource = new Subject<string>();
    filesNeedRefresh = this.filesRefreshSource.asObservable();

    constructor() { }

    researchDetailsUpdated(research: ModelResearchItem) {
        this.researchUpdateSource.next(research);
    }

    // it should be uses when files are currently shown in component
    // to reduce api calls
    researchFilesUpdated(files: ModelResearchFile[]) {
        this.filesUpdateSource.next(files);
    }

    // it should be used when files are not shown in component
    // so api call should happen in receiver
    researchFilesNeedRefresh(researchId: string) {
        this.filesRefreshSource.next(researchId);
    }
}
