import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { assetClassLabel } from '../sleeve-model.labels';
import { ModelSleeveViewModel } from 'src/app/models/model-sleeve.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-sleeve-models-table-view',
    templateUrl: 'sleeve-models-table-view.component.html',
    styleUrls: ['sleeve-models-table-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SleeveModelsTableViewComponent {
    @Input() sleeveModels: Observable<ModelSleeveViewModel[]>;
    @Input() hasSleeveDetailsLinks = false;

    AssetClassLabel = assetClassLabel;

    constructor() { }
}
