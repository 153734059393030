import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ConfirmDialogService {
    private components: Confirmable[] = [];

    public add(component: Confirmable) {
        this.components.push(component);
    }

    public remove(component: Confirmable) {
        this.components = this.components.filter(x => x.id !== component.id);
    }

    public confirm(id: string): Observable<boolean> {
        const component = this.components.find(x => x.id === id);

        if (component) {
            return component.confirm();
        }

        return of(null);
    }
}

export interface Confirmable {
    id: string;
    confirm(): Observable<boolean>;
}
