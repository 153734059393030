import { Component, OnInit, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from '../user.service';
import { MessageBoxService } from '../../shared/messages/message-box.service';
import { WarnOnLeaveFormComponent } from '../../shared/warn-on-leave/warn-on-leave-form.component';
import { FirmService } from '../firm.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subscription } from 'rxjs';
import { Firm } from 'src/app/models/firm.model';
import { catchError } from 'rxjs/operators';
import { UserFormNotificationService } from '../user-form/user-form-notification.service';
import { ResetFormService } from 'src/app/shared/guards/form-reset.service';

@Component({
    selector: 'app-create-user',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.scss', '../admin.layout.scss'],
})

export class CreateUserComponent extends WarnOnLeaveFormComponent implements OnInit, OnDestroy {
    @ViewChild('form', { static: false }) form: NgForm;

    userForm: FormGroup;

    firmId: string;
    firmObservable: Observable<Firm>;
    error: string;

    private subscription = new Subscription();

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private messageBoxService: MessageBoxService,
        private router: Router,
        private route: ActivatedRoute,
        private firmService: FirmService,
        private userFormService: UserFormNotificationService,
        private ngZone: NgZone,
        private resetFormService: ResetFormService,
    ) {
        super();
    }

    ngOnInit() {
        this.userForm = this.fb.group({
            userInformation: [{  }],
        });

        this.firmId = this.route.snapshot.paramMap.get('firmId');

        this.firmObservable = this.firmService.getFirm(this.firmId).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 404) {
                    this.ngZone.run(() => this.router.navigate(['admin/firms']));
                    setTimeout(() => {
                        this.messageBoxService.error('firm-not-exists-error');
                    }, 0);
                }
                return of(null);
            }),
        );

        this.subscription.add(
            this.resetFormService.shouldResetSelfObservable.subscribe(
                () => this.userForm.markAsPristine(),
            ),
        );
    }

    onSubmit() {
        this.userForm.markAllAsTouched();
        this.userFormService.notifyOnFormSubmission();

        if (this.userForm.valid && this.userForm.dirty) {
            this.subscription.add(
                this.userService.createUser(this.userForm.value.userInformation, this.firmId)
                    .subscribe(() => {
                        this.userForm.markAsPristine();
                        this.ngZone.run(() => this.router.navigate(['../'], { relativeTo: this.route }));
                        this.messageBoxService.success('admin-create-user-success');
                    },
                    response => {
                        if (typeof response.error === 'object') {
                            this.error = null;
                        } else {
                            this.error = response.error;
                        }
                        this.messageBoxService.error('create-user-error');
                    }),
            );
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
