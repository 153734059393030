import { ModelHoldingClass } from '../model-pages/model-holding-class.enum';

export const apiUrl = document.querySelector('meta[name="apiurl"]')
    ? document.querySelector('meta[name="apiurl"]').getAttribute('content')
    : 'https://localhost:5000';

export const indentityServiceUrl = document.querySelector('meta[name="identityurl"]')
    ? document.querySelector('meta[name="identityurl"]').getAttribute('content')
    : 'https://localhost:9000';

export const COUNTRIES = [
    {
        label: 'United States',
        value: 'US',
    },
];

export const RESEARCH_FILE_SIZE_LIMIT_IN_BYTES = 20 * 1024 * 1024;

export const INTERCEPTED_REQUEST_LOADER_DEBOUNCE_TIME_IN_MS = 200;

export const CONTACT_LABELS = [
    { label: 'Billing', value: 'Billing' },
    { label: 'Compliance', value: 'Compliance' },
    { label: 'Product Manager', value: 'ProductManager' },
    { label: 'Other', value: 'Other' },
];

export const MODEL_CATEGORIES = [
    { value: 'Equity', label: 'Equity' },
    { value: 'FixedIncome', label: 'Fixed Income' },
    { value: 'Other', label: 'Other' },
];

export const MODEL_HOLDING_CLASSES = [
    { value: ModelHoldingClass.Taxable, label: 'Taxable' },
    { value: ModelHoldingClass.Municipal, label: 'Municipal' },
    { value: ModelHoldingClass.MutualFund, label: 'Mutual Fund' },
    { value: ModelHoldingClass.Etf, label: 'ETF' },
];

export const US_STATES = [
    {
        name: 'Alabama',
        abbreviation: 'AL',
    },
    {
        name: 'Alaska',
        abbreviation: 'AK',
    },
    {
        name: 'Arizona',
        abbreviation: 'AZ',
    },
    {
        name: 'Arkansas',
        abbreviation: 'AR',
    },
    {
        name: 'California',
        abbreviation: 'CA',
    },
    {
        name: 'Colorado',
        abbreviation: 'CO',
    },
    {
        name: 'Connecticut',
        abbreviation: 'CT',
    },
    {
        name: 'Delaware',
        abbreviation: 'DE',
    },
    {
        name: 'District Of Columbia',
        abbreviation: 'DC',
    },
    {
        name: 'Florida',
        abbreviation: 'FL',
    },
    {
        name: 'Georgia',
        abbreviation: 'GA',
    },
    {
        name: 'Hawaii',
        abbreviation: 'HI',
    },
    {
        name: 'Idaho',
        abbreviation: 'ID',
    },
    {
        name: 'Illinois',
        abbreviation: 'IL',
    },
    {
        name: 'Indiana',
        abbreviation: 'IN',
    },
    {
        name: 'Iowa',
        abbreviation: 'IA',
    },
    {
        name: 'Kansas',
        abbreviation: 'KS',
    },
    {
        name: 'Kentucky',
        abbreviation: 'KY',
    },
    {
        name: 'Louisiana',
        abbreviation: 'LA',
    },
    {
        name: 'Maine',
        abbreviation: 'ME',
    },
    {
        name: 'Maryland',
        abbreviation: 'MD',
    },
    {
        name: 'Massachusetts',
        abbreviation: 'MA',
    },
    {
        name: 'Michigan',
        abbreviation: 'MI',
    },
    {
        name: 'Minnesota',
        abbreviation: 'MN',
    },
    {
        name: 'Mississippi',
        abbreviation: 'MS',
    },
    {
        name: 'Missouri',
        abbreviation: 'MO',
    },
    {
        name: 'Montana',
        abbreviation: 'MT',
    },
    {
        name: 'Nebraska',
        abbreviation: 'NE',
    },
    {
        name: 'Nevada',
        abbreviation: 'NV',
    },
    {
        name: 'New Hampshire',
        abbreviation: 'NH',
    },
    {
        name: 'New Jersey',
        abbreviation: 'NJ',
    },
    {
        name: 'New Mexico',
        abbreviation: 'NM',
    },
    {
        name: 'New York',
        abbreviation: 'NY',
    },
    {
        name: 'North Carolina',
        abbreviation: 'NC',
    },
    {
        name: 'North Dakota',
        abbreviation: 'ND',
    },
    {
        name: 'Ohio',
        abbreviation: 'OH',
    },
    {
        name: 'Oklahoma',
        abbreviation: 'OK',
    },
    {
        name: 'Oregon',
        abbreviation: 'OR',
    },
    {
        name: 'Pennsylvania',
        abbreviation: 'PA',
    },
    {
        name: 'Rhode Island',
        abbreviation: 'RI',
    },
    {
        name: 'South Carolina',
        abbreviation: 'SC',
    },
    {
        name: 'South Dakota',
        abbreviation: 'SD',
    },
    {
        name: 'Tennessee',
        abbreviation: 'TN',
    },
    {
        name: 'Texas',
        abbreviation: 'TX',
    },
    {
        name: 'Utah',
        abbreviation: 'UT',
    },
    {
        name: 'Vermont',
        abbreviation: 'VT',
    },
    {
        name: 'Virginia',
        abbreviation: 'VA',
    },
    {
        name: 'Washington',
        abbreviation: 'WA',
    },
    {
        name: 'West Virginia',
        abbreviation: 'WV',
    },
    {
        name: 'Wisconsin',
        abbreviation: 'WI',
    },
    {
        name: 'Wyoming',
        abbreviation: 'WY',
    },
    {
        name: 'American Samoa',
        abbreviation: 'AS',
    },
    {
        name: 'District of Columbia',
        abbreviation: 'DC',
    },
    {
        name: 'Federated States of Micronesia',
        abbreviation: 'FM',
    },
    {
        name: 'Guam',
        abbreviation: 'GU',
    },
    {
        name: 'Marshall Islands',
        abbreviation: 'MH',
    },
    {
        name: 'Northern Mariana Islands',
        abbreviation: 'MP',
    },
    {
        name: 'Palau',
        abbreviation: 'PW',
    },
    {
        name: 'Puerto Rico',
        abbreviation: 'PR',
    },
    {
        name: 'Virgin Islands',
        abbreviation: 'VI',
    },
];
