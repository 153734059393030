import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
    selector: 'app-multi-select-box',
    templateUrl: './multi-select-box.component.html',
    styleUrls: ['./multi-select-box.component.scss'],
})
export class MultiSelectBoxComponent {
    @Input() parentForm: FormGroup;
    @Input() items: { label: string; value: string }[];
    @Input() label: string;
    @Input() formValidationName: string;
    @Input() id: string = null;
    @ViewChild(NgSelectComponent, { static: false }) ngSelectComponent: NgSelectComponent;

    toggleMarked(event: KeyboardEvent) {
        event.preventDefault();
        const markedItem = this.ngSelectComponent.itemsList.markedItem
        if (markedItem) {
            this.ngSelectComponent.toggleItem(markedItem);
        }
    }
}
