import { FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { IcrValidators, isEmptyOrWhitespace } from 'src/app/shared/validators/icr.validators';

function validateSleeve(form: FormGroup) {
    const sleeveModelId = form.get('sleeveModelId');
    if (sleeveModelId && isEmptyOrWhitespace(sleeveModelId.value)) {
        sleeveModelId.setErrors(Validators.required(sleeveModelId));
    }
}

function validateQuantity(form) {
    const quantity = form.get('quantity');
    if (isEmptyOrWhitespace(quantity.value)) {
        quantity.setErrors(IcrValidators.requiredNoWhitespace(quantity));
    }
}

export function modelSleevesValidator(form: FormGroup): ValidationErrors | null {

    const editableControls = Object.keys(form.controls).map(x => form.get(x));

    const allFieldsEmpty = editableControls.every(control => {
        return isEmptyOrWhitespace(control.value)
    });

    if (allFieldsEmpty) {
        editableControls.forEach(control => control.setErrors(null));
        form.markAsPristine();
    } else {
        validateSleeve(form);
        validateQuantity(form);
    }

    return null;
}
