import { Injectable } from '@angular/core';
import { Model } from '../models/model.model';
import { AppPermissions } from 'src/app/core/permissions';
import { permissions } from 'src/app/permissions.config';
import { ModelStatus } from 'src/app/core/model-status';
import { AuthorizationService } from '../core/auth/authorization.service';
import { Roles } from '../core/roles';
import { UserService } from '../admin/user.service';
import { FirmType } from '../core/firm-type';
import { map } from 'rxjs/operators';
import { UserContext } from '../core/user-context';

@Injectable({
    providedIn: 'root',
})
export class ModelAuthorizationService {

    constructor(
        private authService: AuthorizationService,
        private userService: UserService,
    ) {}

    public hasAssetManagerRole(): boolean {
        const userRoles = this.authService.roles;
        const alwaysAllowedRoles = [Roles.AssetManager];
        if (userRoles && userRoles.some(role => alwaysAllowedRoles.includes(role))) {
            return true;
        }

        return false;
    }

    public isModelEditable(model: Model): boolean {
        const userRoles = this.authService.roles;
        const alwaysAllowedRoles = permissions.find(
            permission => permission.permission === AppPermissions.EditAnyFirmModel,
        ).roles;

        if (userRoles && userRoles.some(role => alwaysAllowedRoles.includes(role))) {
            return true;
        }

        const canEditOwnFirmModelRoles = permissions.find(
            permission => permission.permission === AppPermissions.EditOwnFirmModel,
        ).roles;

        const modelStatusesForCanEditOwnFirmModelRoles = [
            ModelStatus.Approved,
            ModelStatus.Authorized,
            ModelStatus.Internal,
        ];

        return (
            model.isCreatedByCurrentUserFirm &&
            modelStatusesForCanEditOwnFirmModelRoles.includes(model.status) &&
            userRoles &&
            userRoles.some(role => canEditOwnFirmModelRoles.includes(role))
        );
    }

    public canViewHistory(model: Model): boolean {
        const userRoles = this.authService.roles;

        const alwaysAllowedRoles = [Roles.ICRAdmin, Roles.Research];
        if (userRoles && userRoles.some(role => alwaysAllowedRoles.includes(role))) {
            return true;
        }

        if (model.isCreatedByCurrentUserFirm && model.status === ModelStatus.Internal) {
            return true;
        }

        const allowedAssetManagerRoles = [Roles.AssetManager, Roles.FirmAdmin];
        if (
            model.isCreatedByCurrentUserFirm &&
            userRoles &&
            userRoles.some(role => allowedAssetManagerRoles.includes(role))
        ) {
            return true;
        }

        return false;
    }

    public canAddResearch(): boolean {
        const userRoles = this.authService.roles;

        const alwaysAllowedRoles = [Roles.ICRAdmin, Roles.Research];
        if (userRoles && userRoles.some(role => alwaysAllowedRoles.includes(role))) {
            return true;
        }

        return false;
    }

    public canCreateModelOfModels(): Promise<boolean> {
        const permittedByRole = this.authService.isRolePermitted(AppPermissions.CreateModelOfModels);

        return this.userService.getCurrentUserContext().pipe(
            map((userContext: UserContext) => {
                return permittedByRole && userContext.firmType === FirmType.Client;
            }))
            .toPromise();
    }
}
