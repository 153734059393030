import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModelListFilterService } from '../model-list.filter.service';
import { ModelFilters } from '../model-list.filter.model';

@Component({
    selector: 'app-model-filters',
    templateUrl: './model-filters.component.html',
    styleUrls: ['./model-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelFiltersComponent implements OnInit, OnDestroy {
    filters = new ModelFilters();

    private subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private modelListFilterService: ModelListFilterService,
        private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.subscription.add(
            this.modelListFilterService.filtersObservable.subscribe(filters => {
                this.filters = filters;
                this.changeDetectorRef.markForCheck();
            }),
        );
        this.subscription.add(
            this.route.queryParams.subscribe(params => {
                this.modelListFilterService.applyFiltersFromUrl(params);
                this.changeDetectorRef.markForCheck();
            }),
        );
    }

    clearAllFilters() {
        this.modelListFilterService.clearAllFilters();
        this.changeDetectorRef.markForCheck();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
