import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FixedIncomeDynamicHolding } from 'src/app/models/fixed-income-dynamic-holding.model';
import { EquityDynamicHolding } from 'src/app/models/equity-dynamic-holding.model';
import { ModelHoldingsDataService } from '../model-holdings/model-holdings.data.service';
import { ModelCategory } from '../model-category.enum';
import { ModelHoldingCategory } from '../model-holding-category-enum';
import { ModelHoldingClass } from '../model-holding-class.enum';
import { CSVRecord } from './csv-record';

const CLASS_NAME_TO_ENUM = {
    ETF: ModelHoldingClass.Etf,
    MUNICIPAL: ModelHoldingClass.Municipal,
    MUTUAL: ModelHoldingClass.MutualFund,
    TAXABLE: ModelHoldingClass.Taxable,
};

@Injectable({
    providedIn: 'root',
})
export class UploadModelHoldingsConverterService {
    constructor(private modelHoldingsDataService: ModelHoldingsDataService) {}

    csvRecordToFormGroup(recordAssetClass: ModelCategory, record: CSVRecord): FormGroup {
        if (recordAssetClass === ModelCategory.Equity) {
            return this.csvRecordToEquityHolding(record);
        } else if (recordAssetClass === ModelCategory.FixedIncome) {
            return this.csvRecordToFixedIncomeHolding(record);
        }
    }

    private csvRecordToEquityHolding(record: CSVRecord): FormGroup {
        const equity = {
            ticker: record.ticker,
            cusip: record.cusip,
            quantity: record.quantity,
            name: record.issuerName,
            category: ModelHoldingCategory.Equity,
        } as EquityDynamicHolding;

        return this.modelHoldingsDataService.getEquityHoldingForm(equity);
    }

    private csvRecordToFixedIncomeHolding(record: CSVRecord): FormGroup {
        const fixed = {
            ticker: record.ticker,
            cusip: record.cusip,
            quantity: record.quantity,
            class: this.holdingClassToEnum(record.class),
            issuer: record.issuerName,
            category: ModelHoldingCategory.FixedIncome,
        } as FixedIncomeDynamicHolding;

        return this.modelHoldingsDataService.getFixedIncomeHoldingForm(fixed);
    }

    private holdingClassToEnum(holdingClass: string): ModelHoldingClass {
        return CLASS_NAME_TO_ENUM[holdingClass ? holdingClass.trim().toUpperCase() : ''];
    }
}
