import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { ProfileService } from 'src/app/user-profile/profile.service';
import { US_STATES } from 'src/app/core/constants';
import { WarnOnLeaveFormComponent } from '../../shared/warn-on-leave/warn-on-leave-form.component';
import { MessageBoxService } from '../../shared/messages/message-box.service';
import { IcrValidators } from 'src/app/shared/validators/icr.validators';
import { Subscription } from 'rxjs';
import { USER_ROLES } from 'src/app/core/roles';
import { ResetFormService } from 'src/app/shared/guards/form-reset.service';

@Component({
    selector: 'app-account-form',
    templateUrl: './account-form.component.html',
    styleUrls: ['./account-form.component.scss'],
})
export class AccountFormComponent extends WarnOnLeaveFormComponent implements OnInit, OnDestroy {
    @ViewChild('form', { static: false }) form: NgForm;

    error: string;
    profileForm: FormGroup;
    salutations = ['Mrs.', 'Ms.', 'Mr.', 'Dr.', 'Mx.'];
    countries = ['United States'];
    states = US_STATES.map(state => ({
        value: state.abbreviation,
        label: state.name,
    }));

    private subscription = new Subscription();

    constructor(
        private formBuilder: FormBuilder,
        private profileService: ProfileService,
        private messageBoxService: MessageBoxService,
        private resetFormService: ResetFormService,
    ) {
        super();
    }

    ngOnInit() {
        this.InitializeForm();
        this.subscription.add(
            this.profileService.getProfile().subscribe(profile => {
                if (profile.workPhoneCountryCode === null || profile.workPhoneCountryCode === '') {
                    profile.workPhoneCountryCode = '1';
                }
                if (profile.cellPhoneCountryCode === null || profile.cellPhoneCountryCode === '') {
                    profile.cellPhoneCountryCode = '1';
                }

                const roles = profile.accountType.map(
                    accountType => USER_ROLES.find(role => role.value === accountType).label,
                );

                this.profileForm.patchValue(profile);
                this.profileForm.get('accountType').patchValue(roles.join(', '));
            }));

        this.subscription.add(
            this.resetFormService.shouldResetSelfObservable.subscribe(
                () => this.profileForm.reset(),
            ),
        );
    }

    onSubmit() {
        this.profileForm.markAllAsTouched();

        if (this.profileForm.valid && this.profileForm.dirty) {
            this.subscription.add(
                this.profileService.saveProfile(this.profileForm.value).subscribe(
                    () => {
                    // reset form state so it becomes pristine and unsubmitted keeping the saved input values
                        this.form.resetForm(this.profileForm.getRawValue());
                        this.messageBoxService.success('profile-section-success');
                    },
                    response => {
                        if (typeof response.error === 'object') {
                            this.error = null;
                        } else {
                            this.error = response.error;
                        }
                        this.messageBoxService.error('profile-section-error');
                    },
                ));
        }
    }

    fieldHasError(key: string, errorType: string) {
        const field = this.profileForm.get(key);

        if (field) {
            return field.touched && field.hasError(errorType);
        }

        return false;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private InitializeForm() {
        this.profileForm = this.formBuilder.group({
            salutation: '',
            firstName: ['', [IcrValidators.requiredNoWhitespace]],
            preferredName: '',
            middleName: '',
            lastName: ['', [IcrValidators.requiredNoWhitespace]],
            suffix: '',
            email: { value: '', disabled: true },
            accountType: { value: '', disabled: true },
            company: { value: '', disabled: true },
            workPhoneCountryCode: '1',
            workPhone: ['', [Validators.minLength(12)]],
            workPhoneExtension: ['', [Validators.maxLength(6)]],
            cellPhoneCountryCode: '1',
            cellPhone: ['', [Validators.required, Validators.minLength(12)]],
            country: '',
            workAddress: '',
            workAddress2: '',
            city: '',
            state: '',
            zipCode: ['', [Validators.minLength(5)]],
            linkedInUrl: '',
            twitterHandle: '',
            slackHandle: '',
            personalBio: '',
        }, {
            updateOn: 'blur',
        });
    }
}
