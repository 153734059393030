import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModelListFilterService } from '../../model-list.filter.service';
import { ModelStyleClass, EQUITY_STYLE_CLASSES } from 'src/app/core/model-style-class';
import { ModelRegion } from 'src/app/core/model-region';
import { ModelCategory } from 'src/app/model-pages/model-category.enum';

@Component({
    selector: 'app-model-filter-style-class-multi',
    templateUrl: './model-filter.style-class-multi.component.html',
    styleUrls: ['./model-filter.style-class-multi.component.scss'],
})
export class ModelFilterStyleClassMultiComponent implements OnInit, OnDestroy {

    @Input() assetClass: ModelCategory = undefined;
    @Input() region: ModelRegion = undefined;
    @Input() styleClassValues: ModelStyleClass[] = undefined;

    ModelStyleClass = ModelStyleClass;
    selected = {
        [ModelStyleClass.LargeCapValue]: false,
        [ModelStyleClass.LargeCapBlended]: false,
        [ModelStyleClass.LargeCapGrowth]: false,
        [ModelStyleClass.MediumCapValue]: false,
        [ModelStyleClass.MediumCapBlended]: false,
        [ModelStyleClass.MediumCapGrowth]: false,
        [ModelStyleClass.SmallCapValue]: false,
        [ModelStyleClass.SmallCapBlended]: false,
        [ModelStyleClass.SmallCapGrowth]: false,
    };

    private subscription = new Subscription();

    constructor(private modelListFilterService: ModelListFilterService) {}

    ngOnInit() {
        this.applyStyleClassValues(this.styleClassValues);

        this.subscription.add(
            this.modelListFilterService.filtersObservable.subscribe(filters =>
                this.applyStyleClassValues(filters.styleClassValues),
            ),
        );
    }

    get isVisible(): boolean {
        return this.assetClass === ModelCategory.Equity && this.region === ModelRegion.Domestic;
    }

    toggle(styleClass: ModelStyleClass): void {
        this.modelListFilterService.toggleFilterByStyleClass(styleClass);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private applyStyleClassValues(styleClassValues: ModelStyleClass[] = null) {
        EQUITY_STYLE_CLASSES.Domestic.forEach(styleClass => {
            this.selected[styleClass.value] = styleClassValues && styleClassValues.includes(styleClass.value);
        });
    }
}
