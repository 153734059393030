import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl,
} from '@angular/forms';
import { PasswordValidator } from 'src/app/validators/password.validator';
import { RegexpValidations } from 'src/app/validators/regexp.validator';
import { validationLabels } from './validations-labels';

@Component({
    selector: 'password-change-form',
    templateUrl: './password-change-form.component.html',
    styleUrls: ['./password-change-form.component.scss'],
})
export class PasswordChangeFormComponent implements OnInit {
    passwordChangeForm: FormGroup;
    get newPassword() {
        return this.passwordChangeForm.get('newPassword');
    }

    @Input()
    set cleanFields(cleanFields: boolean) {
        if (cleanFields === true) {
            this.passwordChangeForm.patchValue({ currentPassword: '', newPassword: '' });
            this.passwordChangeForm.markAsUntouched();

            this.onChange();
        }
    }

    @Output() validationsChange = new EventEmitter();
    @Output() passwordsChange = new EventEmitter();

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.createForms();
    }

    createForms() {
        this.passwordChangeForm = this.fb.group(
            {
                currentPassword: new FormControl('', Validators.required),
                newPassword: new FormControl(
                    '',
                    Validators.compose([
                        Validators.required,
                        RegexpValidations.singleDigit(),
                        RegexpValidations.singleLetter(),
                        RegexpValidations.singleSpecialChar(),
                        Validators.minLength(10),
                        RegexpValidations.moreThan2IdenticalChars(),
                    ]),
                ),
            },
            {
                validators: PasswordValidator.areNotEqual(
                    'currentPassword',
                    'newPassword',
                ),
            },
        );
    }

    onChange() {
        this.passwordsChange.emit(this.passwordChangeForm.value);

        const empty = this.newPassword.hasError('required');

        const checkedValidations = validationLabels.map(
            validation => {
                const isValid =
                    !empty &&
                    !(
                        this.passwordChangeForm.hasError(validation.type) ||
                        this.newPassword.hasError(validation.type)
                    );
                return { label: validation.message, checked: isValid };
            },
        );

        this.validationsChange.emit(checkedValidations);
    }
}
