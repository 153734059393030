import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-model-list',
    templateUrl: './model-list.component.html',
    styleUrls: ['./model-list.component.scss'],
})
export class ModelListComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
