export const validationLabels = [
    { type: 'singleLetter', message: 'At least one letter' },
    { type: 'singleDigit', message: 'At least 1 digit (0-9)' },
    {
        type: 'singleSpecialChar',
        message: 'At least one special character',
    },
    {
        type: 'minlength',
        message: 'At least 10 characters',
    },
    {
        type: 'moreThan2IdenticalChars',
        message:
            'No more than 2 identical characters in a row (e.g. 111 not allowed)',
    },
    {
        type: 'areNotEqual',
        message: 'New password should not match old password',
    },
];
