import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-equity-dynamic-holding-qty-edit',
    templateUrl: './equity-dynamic-holding-qty-edit.component.html',
    styleUrls: ['./equity-dynamic-holding-qty-edit.component.scss',
        '../../../../model-holdings/column-sizes.edit.scss',
        '../model-detail-holdings-edit.component.scss'],
})
export class EquityDynamicHoldingQtyEditComponent implements OnInit {
    @Input() holdingForm: FormGroup;
    @Input() index: number = null;
    @Input() editWholeRow = false;
    @Output() onDelete = new EventEmitter();
    @Input() positions = [];

    constructor() { }

    ngOnInit() {
        this.positions = ['B', 'H'];
    }

    fieldHasError(key: string, errorType: string) {
        const field = this.holdingForm.get(key);
        if (field) {
            return field.touched && field.hasError(errorType);
        }

        return false;
    }

    deleteSelf() {
        this.onDelete.emit();
    }
}
