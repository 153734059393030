import { Component, Input, Output, EventEmitter, HostListener, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-switch-button',
    templateUrl: './switch-button.component.html',
    styleUrls: ['./switch-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchButtonComponent  {
    @Input() on: boolean;
    @Output() toggled = new EventEmitter<boolean>();

    @HostListener('click')
    toggle() {
        this.on = !this.on;
        this.toggled.emit(this.on);
    }
}
