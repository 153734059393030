import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { ModelListFilterService } from '../../model-list.filter.service';
import { ModelStyleClass } from 'src/app/core/model-style-class';
import { FILTER_STYLE_CLASS } from '../../model-list.filter.model';
import { ModelRegion } from 'src/app/core/model-region';
import { ModelCategory } from 'src/app/model-pages/model-category.enum';

@Component({
    selector: 'app-model-filter-style-class',
    templateUrl: './model-filter.style-class.component.html',
    styleUrls: ['./model-filter.style-class.component.scss'],
})
export class ModelFilterStyleClassComponent implements OnInit, OnDestroy {

    @Input() assetClass: ModelCategory = undefined;
    @Input() region: ModelRegion = undefined;

    selectedMunicipal = false;
    selectedTaxable = false;
    selectedValue = false;
    selectedBlended = false;
    selectedGrowth = false;

    private subscription = new Subscription();
    private styleClasses = _.invert(FILTER_STYLE_CLASS);

    constructor(private modelListFilterService: ModelListFilterService) {}

    ngOnInit() {
        this.subscription.add(
            this.modelListFilterService.filtersObservable.subscribe(filters => {
                this.selectedMunicipal =
                    filters.styleClassValues && filters.styleClassValues.includes(ModelStyleClass.Municipal);
                this.selectedTaxable =
                    filters.styleClassValues && filters.styleClassValues.includes(ModelStyleClass.Taxable);

                this.selectedValue =
                    filters.styleClassValues && filters.styleClassValues.includes(ModelStyleClass.Value);
                this.selectedBlended =
                    filters.styleClassValues && filters.styleClassValues.includes(ModelStyleClass.Blended);
                this.selectedGrowth =
                    filters.styleClassValues && filters.styleClassValues.includes(ModelStyleClass.Growth);
            }),
        );
    }

    get isFixedIncomeDomestic(): boolean {
        return this.assetClass === ModelCategory.FixedIncome && this.region === ModelRegion.Domestic;
    }

    get isEquityGlobalOrInternational(): boolean {
        return (
            this.assetClass === ModelCategory.Equity &&
            (this.region === ModelRegion.Global || this.region === ModelRegion.International)
        );
    }

    toggleMunicipal() {
        this.modelListFilterService.filterByStyleClass(
            this.selectedMunicipal ? undefined : this.styleClasses[ModelStyleClass.Municipal],
        );
    }

    toggleTaxable() {
        this.modelListFilterService.filterByStyleClass(
            this.selectedTaxable ? undefined : this.styleClasses[ModelStyleClass.Taxable],
        );
    }

    toggleValue() {
        this.modelListFilterService.filterByStyleClass(
            this.selectedValue ? undefined : this.styleClasses[ModelStyleClass.Value],
        );
    }

    toggleBlended() {
        this.modelListFilterService.filterByStyleClass(
            this.selectedBlended ? undefined : this.styleClasses[ModelStyleClass.Blended],
        );
    }

    toggleGrowth() {
        this.modelListFilterService.filterByStyleClass(
            this.selectedGrowth ? undefined : this.styleClasses[ModelStyleClass.Growth],
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
