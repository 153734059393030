import { FormControlName } from '@angular/forms';

/* this is needed to be able to access native HTML element from FormControl
we use it to focus invalid FormControl input
implemented according to https://github.com/angular/angular/issues/13158
there is a feature request for Angular to support it out of the box https://github.com/angular/angular/issues/31133
*/
const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function (...args) {
    const result = originFormControlNameNgOnChanges.apply(this, args);
    if (this.control) {
        if (this.valueAccessor._elementRef) {
            this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
        } else if (this.valueAccessor.element && this.valueAccessor.element.tagName === 'NG-SELECT') {
            // NG-SELECT has a different valueAccessor than default inputs so we need to find its nested input
            this.control.nativeElement = this.valueAccessor.element.querySelector('.ng-input > input');
        }
    }
    return result;
};
