import { HttpErrorResponse } from '@angular/common/http';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { ModelService } from 'src/app/model-pages/model.service';
import { AppPermissions } from 'src/app/core/permissions';
import { Component, Input, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FileSaverService } from 'ngx-filesaver';

@Component({
    selector: 'app-upload-model-statistics-button',
    templateUrl: './upload-model-statistics-button.component.html',
})
export class UploadModelStatisticsButtonComponent implements OnDestroy {
    @Input() label: string;
    @Input() class: string;
    @Output() onSuccess = new EventEmitter<void>();
    @Output() onPartialUpload = new EventEmitter<void>();
    AppPermissions = AppPermissions;
    errorMessage: string;

    private subscription = new Subscription();

    constructor(
        private modelService: ModelService,
        private fileSaverService: FileSaverService,
        private messageBoxService: MessageBoxService,
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onStatisticsFileSelect(event) {
        const files: FileList = event.target.files;
        const file: File = files.item(0);

        this.subscription.add(
            this.modelService.uploadModelStatisticsFile(file).subscribe(downloadFile => {
                if (downloadFile.size) {
                    const fileName = this.getCorrectionFileName(file.name);
                    this.fileSaverService.save(downloadFile, fileName);
                    this.showErrorMessage(
                        `Some records have errors. Look at the downloaded file ${fileName} for details.`,
                    );
                    this.onPartialUpload.emit();
                } else {
                    this.messageBoxService.success('upload-model-statistics-success');
                    this.onSuccess.emit();
                }
            }, (error: HttpErrorResponse) => {
                let errorMessage = 'There has been an issue uploading model statistics.';

                if (error.status === 400) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        errorMessage = reader.result as string;
                        this.showErrorMessage(errorMessage);
                    };
                    reader.readAsText(error.error);
                } else {
                    this.showErrorMessage(errorMessage);
                }
            }),
        );
    }

    private showErrorMessage(message: string) {
        this.errorMessage = message;
        this.messageBoxService.error('upload-model-statistics-error');
        this.changeDetectorRef.detectChanges();
    }

    private getCorrectionFileName(fileName: string): string {
        const dotIndex = fileName.lastIndexOf('.');
        return dotIndex === -1
            ? `${fileName}_correction`
            : `${fileName.substring(0, dotIndex)}_correction${fileName.substring(dotIndex)}`;
    }
}
