import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Profile } from '../models/profile.model';
import { map, tap } from 'rxjs/operators';
import { apiUrl } from '../core/constants';
import { removeSymbolsFromCountryCode, removeSymbolsFromPhone, addDashesToPhone } from '../shared/phoneFormatter';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    profileUpdated = new Subject<Profile>();

    constructor(private http: HttpClient) { }

    public getProfile(): Observable<Profile> {
        return this.http.get<Profile>(`${apiUrl}/api/v1/profile`).pipe(
            map(response => ({
                ...response,
                workPhone: addDashesToPhone(response.workPhone),
                cellPhone: addDashesToPhone(response.cellPhone),
            })),
        );
    }

    public saveProfile(profile: Profile): Observable<void> {
        return this.http.post<void>(`${apiUrl}/api/v1/profile`, this.phoneFieldsRemoveSymbols(profile))
            .pipe(tap(() => this.profileUpdated.next(profile)));
    }

    private phoneFieldsRemoveSymbols(profile: Profile): Profile {
        return {
            ...profile,
            workPhoneCountryCode: removeSymbolsFromCountryCode(profile.workPhoneCountryCode),
            cellPhoneCountryCode: removeSymbolsFromCountryCode(profile.cellPhoneCountryCode),
            workPhone: removeSymbolsFromPhone(profile.workPhone),
            cellPhone: removeSymbolsFromPhone(profile.cellPhone),
        };
    }
}
