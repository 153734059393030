import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnInit {
    @Input() iconName: string;
    @Output() onClick = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    onClickButton(event) {
        this.onClick.emit(event);
    }
    getIconClassName() {
        return `icon-${this.iconName}`;
    }
    getIconPath() {
        return `assets/icons/${this.iconName}.svg`;
    }
}
