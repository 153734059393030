import { round } from '../core/utils';

export const sumQuantity = (holdings: {quantity: number} []) => {
    if (!holdings) {
        return 0;
    }

    return holdings.reduce(
        (total, holding) => round(total + Number(holding ? holding.quantity : 0), 4),
        0,
    );
}
