import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-text-area',
    templateUrl: './text-area.component.html',
    styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent implements OnInit {
    ngOnInit() {}

    @Input() parentForm: FormGroup;
    @Input() label: string;
    @Input() placeholder = '';
    @Input() id: string;
    @Input() errorMessage: string;
    @Input() formValidationName: string;
    @Input() isNarrow: boolean;
    @Input() isDisabled: boolean;
    @Input() rows: number;
    @Input() maxLength = 500;
}
