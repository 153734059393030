import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateFirmComponent } from './create-firm/create-firm.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AdminRoutingModule } from './admin-routing.module';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ListFirmsComponent } from './list-firms/list-firms.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { AdminComponent } from './admin.component';
import { ListUsersComponent } from './list-users/list-users.component';
import { SelectRolesComponent } from './user-form/select-roles/select-roles.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EditUserComponent } from './edit-user/edit-user.component';
import { UserFormComponent } from './user-form/user-form.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FirmFormComponent } from './firm-form/firm-form.component';
import { EditFirmComponent } from './edit-firm/edit-firm.component';
import { ListAuthorizeFirmComponent } from './list-authorize-firm/list-authorize-firms.component';
import { ModelBatchAuthorizeTableComponent } from './list-authorize-model/model-authorize-table.component'

@NgModule({
    declarations: [
        AdminComponent,
        ContactFormComponent,
        CreateFirmComponent,
        CreateUserComponent,
        ListFirmsComponent,
        ListUsersComponent,
        SelectRolesComponent,
        EditUserComponent,
        UserFormComponent,
        FirmFormComponent,
        EditFirmComponent,
        ListAuthorizeFirmComponent,
        ModelBatchAuthorizeTableComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule,
        AdminRoutingModule,
        NgxDatatableModule,
        AngularSvgIconModule,
    ],
})
export class AdminModule { }
