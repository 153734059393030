import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { HttpErrorResponseHandler } from './../../core/http-error-response.handler';
import { ModelService } from '../model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, Subject } from 'rxjs';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { Model, UpdateModelResearchModel } from 'src/app/models/model.model';
import { AppPermissions } from 'src/app/core/permissions';
import { ConfirmDialogService } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.service';
import { ModelStatus } from 'src/app/core/model-status';
import { ModelStateManagementService } from './model-state-management.service';
import { AuthorizationService } from 'src/app/core/auth/authorization.service';
import { ModelAuthorizationService } from '../model-authorization.service';
import { ModelType } from 'src/app/core/model-type';
import { switchMap } from 'rxjs/operators';

import { UserService } from 'src/app/admin/user.service';

import { Roles } from 'src/app/core/roles';


@Component({
    selector: 'app-model-detail',
    templateUrl: './model-detail.component.html',
    styleUrls: ['./model-detail.component.scss'],
})
export class ModelDetailComponent implements OnInit, OnDestroy {
    AppPermissions = AppPermissions;

    modelId: string;
    model: Model;
    confirmDeActivateId = 'confirm-deactivate-model';
    confirmApprovalId = 'confirm-approve-model';
    confirmAuthorizeId = 'confirm-authorize-model';
    confirmPendingBatchTransferAuthorizeId = 'confirm-authorize-batch-transfer-model';
    confirmUnsubscribeId = 'confirm-unsubscribe-model';
    confirmSubscribeId = 'confirm-subscribe-model';

    private inEditMode = false;
    private subscription = new Subscription();
    private parentModelOfModels: string;
    isResearched = new Subject<boolean>();
    icrRole: string;
    isResearchedModel: string;
    updateModelResearchModel: UpdateModelResearchModel;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private modelService: ModelService,
        private modelAuthorizationService: ModelAuthorizationService,
        private route: ActivatedRoute,
        private messageBoxService: MessageBoxService,
        private router: Router,
        private confirmDialog: ConfirmDialogService,
        private stateManagementService: ModelStateManagementService,
        private authorizationService: AuthorizationService,
        private httpErrorResponseHandler: HttpErrorResponseHandler,
        private userService: UserService,
    ) {
        this.modelId = this.route.snapshot.params['id'];
    };

    ngOnInit() {
        this.subscription.add(
            this.route.params.pipe(
                switchMap((params) => {
                    this.modelId = params['id'];
                    return this.modelService.getModel(params['id']);
                }))
                .subscribe(
                    (model) => {
                        const adminRole = this.authorizationService.roles.find(r => r === Roles.ICRAdmin);
                        if (model.status === ModelStatus.Draft && !adminRole) {
                            this.messageBoxService.error('forbidden-route');
                            this.router.navigate(['/models']);
                            return;
                        }
                        this.model = model;
                        if (model.type === ModelType.ModelOfModels) {
                            this.parentModelOfModels = model.id;
                        }
                    },
                    (error: HttpErrorResponse) => this.showModelNotFoundError(error),
                ),
        );

        this.subscription.add(
            this.stateManagementService.modelDetailsUpdated.subscribe(
                (model) => (this.model = model),
            ),
        );

        this.subscription.add(
            this.stateManagementService.modelInEditModeUpdated.subscribe((inEditMode) => {
                this.inEditMode = inEditMode;
                this.changeDetectorRef.detectChanges();
            }),
        );

        this.subscription.add(
            this.isResearched
                .subscribe((value: boolean) => {
                    this.model.isResearched = value;
                    this.updateResearched();
                }),
        )

        this.subscription.add(
            this.userService.getCurrentUserContext().subscribe(
                (context) => {
                    this.icrRole = context.roles[0];
                },
            ),
        );


    }

    async deactivate() {
        const confirmed = await this.confirmDialog.confirm(this.confirmDeActivateId).toPromise();
        if (confirmed) {
            this.subscription.add(
                this.modelService.deactivate(this.modelId).subscribe(
                    () => {
                        this.router.navigate(['/models'], { queryParamsHandling: 'merge' });
                        this.messageBoxService.success('model-deactivate-success');
                    },
                    (err) => {
                        if (err.status === 400) {
                            this.messageBoxService.error('model-deactivate-batchtransfer-error');
                        } else {
                            this.messageBoxService.error('model-deactivate-error');
                        }
                    },
                ),
            );
        }
    }

    async approve() {
        const confirmed = await this.confirmDialog.confirm(this.confirmApprovalId).toPromise();

        if (confirmed) {
            this.subscription.add(
                this.modelService.approve(this.modelId).subscribe(
                    () => {
                        this.router.navigate(['/models'], { queryParamsHandling: 'merge' });
                        this.messageBoxService.success('model-approve-success');
                    },
                    () => this.messageBoxService.error('model-approve-error'),
                ),
            );
        }
    }

    async subscribe() {
        const confirmed = await this.confirmDialog.confirm(this.confirmSubscribeId).toPromise();

        if (confirmed) {
            this.subscription.add(
                this.modelService.subscribe(this.modelId).subscribe(
                    () => {
                        this.model.isSubscribedByCurrentUser = true;
                        this.messageBoxService.success('model-subscribe-success');
                    },
                    ((error: HttpErrorResponse) => {
                        if(error.error.indexOf('Authorized Status') > -1) {
                            this.messageBoxService.error('model-subscribe-permission-error');
                        } else {
                            this.messageBoxService.error('model-subscribe-error');
                        }
                    }),
                ),
            );
        }
    }

    async unsubscribe() {
        const confirmed = await this.confirmDialog.confirm(this.confirmUnsubscribeId).toPromise();

        if (confirmed) {
            this.subscription.add(
                this.modelService.unsubscribe(this.modelId).subscribe(
                    () => {
                        this.model.isSubscribedByCurrentUser = false;
                        this.messageBoxService.success('model-unsubscribe-success');
                    },
                    () => this.messageBoxService.error('model-unsubscribe-error'),
                ),
            );
        }
    }

    deactivateButtonVisible() {
        const adminRole = this.authorizationService.roles.find(r => r === Roles.ICRAdmin);
        if(!adminRole) {
            return false;
        }

        return this.model && this.model.status !== ModelStatus.Deactivated &&
            !this.inEditMode;
    }

    approveButtonVisible() {
        return this.model && this.model.status === ModelStatus.PendingApproval && !this.inEditMode &&
            this.authorizationService.isRolePermitted(AppPermissions.ApproveModel);
    }

    isAuthorizeButtonVisible() {
        return this.model && this.model.status === ModelStatus.Approved
            && !this.inEditMode
            && this.authorizationService.isRolePermitted(AppPermissions.AuthorizeModel);
    }

    isSubscribeButtonVisible() {
        return this.model && !this.model.isSubscribedByCurrentUser && !this.inEditMode;
    }

    isUnsubscribeButtonVisible() {
        return this.model && this.model.isSubscribedByCurrentUser && !this.inEditMode;
    }

    isModelEditable(): boolean {
        return this.modelAuthorizationService.isModelEditable(this.model);
    }

    canViewHistory(): boolean {
        return this.modelAuthorizationService.canViewHistory(this.model);
    }

    getModelSubtitle() {
        if (this.model.type === ModelType.Holdings) {
            return this.model.statisticsId
                ? `${this.model.firmName} | Statistics ID #${this.model.statisticsId}`
                : this.model.firmName;
        }

        if (this.model.type === ModelType.ModelOfModels) {
            return `${this.model.firmName} | Created by
                    ${this.model.createdByFirstName}
                    ${this.model.createdByLastName}`;
        }
    }

    updateResearched(){
        const model = {
            id: this.modelId,
            isResearched: this.model.isResearched.toString(),
        };
        this.subscription.add(
            this.modelService.updateResearched(model).subscribe(
                () => {
                    this.messageBoxService.success('model-details-updated-success');
                    this.model.isResearched = this.model.isResearched;
                },
                () => this.messageBoxService.error('model-details-updated-failed'),
            ),
        );
    }

    async authorize() {
        const confirmed = await this.confirmDialog.confirm(this.confirmAuthorizeId).toPromise();

        if (confirmed) {
            this.subscription.add(
                this.modelService.pendingAuthorize(this.modelId).subscribe(
                    () => {
                        this.router.navigate(['/models'], { queryParamsHandling: 'merge' });
                        this.messageBoxService.success('model-authorize-success');
                    },
                    () => this.messageBoxService.error('model-authorize-error'),
                ),
            );
        }
    }

    get previousPageLocation() {
        let prevLocation = '/models';

        if (this.model.type === ModelType.Holdings
            && this.parentModelOfModels
            && this.parentModelOfModels !== this.model.id) {
            prevLocation += `/${this.parentModelOfModels}/holdings`;
        }

        return prevLocation;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private showModelNotFoundError(error: HttpErrorResponse) {
        this.httpErrorResponseHandler.handleError(error, {
            notFoundErrorMessageId: 'model-not-found',
            genericErrorMessageId: 'model-details-fetch-failed',
        })
    }
}
