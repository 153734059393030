import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({
    selector: 'input[app-numbers-only]',
})
export class InputNumbersOnlyDirective {
    constructor(private _el: ElementRef, private control: NgControl) { }

    @Input() allowNegative: boolean;
    @Input() allowFractions: boolean;
    @Input() fractions: number;

    @HostListener('input', ['$event']) onInputChange(event: Event) {

        const initialValue = this.fixDecimalInputStartedWithDot(this._el.nativeElement.value);

        const regexp = this.declareRegexForValidNumbers();

        const sanitizedValue = this.removeNonValidSymbols(initialValue, regexp);

        this.setValue(sanitizedValue);


        if (this._el.nativeElement.value &&
            this.allowFractions &&
            this.fractions &&
            (!this.allowNegative || this._el.nativeElement.value !== '-')
        ) {
            const limitedValue = this.toFixed(Number(this._el.nativeElement.value), this.fractions);

            if (Number(limitedValue) !== Number(this._el.nativeElement.value)) {
                this.setValue(limitedValue);

            } else if (this.getFractions(this._el.nativeElement.value).length > this.fractions) {
                this.setValue(limitedValue);

            }
        }

        if (!this.isInputValid(initialValue, this._el.nativeElement.value)) {
            event.stopPropagation();
        }
    }

    private setValue(newValue: string) {
        this._el.nativeElement.value = newValue;
        if (this.control.control) {
            this.control.control.setValue(newValue);
        }
    }

    private removeNonValidSymbols(initalValue: string, regexp: RegExp) {
        return initalValue.replace(regexp, '');
    }

    private isInputValid(initialValue: string, currentValue: string) {
        return initialValue === currentValue;
    }

    private declareRegexForValidNumbers() {
        return this.allowNegative
            ? this.allowFractions
                ? /[^0-9.-]*/g //numbers, dot, minus
                : /[^0-9-]*/g //numbers and minus
            : this.allowFractions
                ? /[^0-9.]*/g //numbers (non-negative), dot
                : /[^0-9]*/g; //only numbers
    }

    private fixDecimalInputStartedWithDot(initalValue: string) {
        if (initalValue === '-.') {
            this._el.nativeElement.value = initalValue = '-0.';
        }

        if (initalValue === '.') {
            this._el.nativeElement.value = initalValue = '0.';
        }
        return initalValue;
    }

    private getFractions(value: string): string {
        if (!value) {
            return '';
        }

        const groups = value.split('.');

        return groups.length > 1
            ? groups[1]
            : '';
    }

    private toFixed(num: number, fixed: number): string {
        const regex = new RegExp('^-?\[-\\d\]+(?:\.\\d{0,' + (fixed || -1) + '})?');
        const matchGroup = num.toFixed(fixed + 1).match(regex);

        return matchGroup ? matchGroup[0] : '';
    }
}
