import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChild,
    UrlTree,
} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AppPermissions } from 'src/app/core/permissions';
import { MessageBoxService } from '../messages/message-box.service';
import { AuthorizationService } from 'src/app/core/auth/authorization.service';
import { ResetFormService } from './form-reset.service';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';
import { filter, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private oauthService: OAuthService,
        private authorizationService: AuthorizationService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private messageBoxService: MessageBoxService,
        private resetFormService: ResetFormService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.ensureLoggedIn(state);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree>  {
        return this.ensureLoggedIn(state)
            .pipe(map(loggedIn => {
                if (!loggedIn) {
                    return false;
                }

                const permission = childRoute.data['permission'] as AppPermissions;
                if (permission === undefined) {
                    throw Error(`ERROR: route ${childRoute.url} has no permissions set`);
                }
                const isPermitted = this.authorizationService.isRolePermitted(permission);

                if (!isPermitted) {
                    setTimeout(() => this.messageBoxService.open('forbidden-route'));
                    return this.router.parseUrl('models');
                }

                return isPermitted;
            }));
    }

    private ensureLoggedIn(state: RouterStateSnapshot): Observable<boolean> {
        return this.authenticationService.isDoneLoading$
            .pipe(filter(isDone => isDone))
            .pipe(tap(_ => {
                if (!this.authenticationService.isLoggedIn()) {
                    this.resetFormService.resetDirtyForms();
                    this.oauthService.initLoginFlow(state.url);
                }
            }))
            .pipe(map(_ => this.authenticationService.isLoggedIn()))
    }
}
