import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent implements OnInit {
    ngOnInit() { }

    @Input() parentForm: FormGroup;
    @Input() label: string = null;
    @Input() placeholder = '';
    @Input() id: string;
    @Input() errorMessage: string;
    @Input() formValidationName: string;
    @Input() type: string;
    @Input() isNarrow: boolean;
    @Input() maxLength = 500;
    @Input() fractions = 2;
    @Input() allowFractions = true;
    @Input() allowNegative = false;
}
