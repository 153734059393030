import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MultiSelectBoxComponent } from 'src/app/shared/inputs/multi-select-box/multi-select-box.component';
import { FirmType } from 'src/app/core/firm-type';
import { Roles, USER_ROLES } from 'src/app/core/roles';
import { MultiSelectOption, MultiSelectValue } from 'src/app/shared/inputs/multi-select-box/multi-select-option';
import { AVAILABLE_ROLES_BY_FIRM, INCOMPATIBLE_ROLES } from './select-role-rules.config';
import { Subscription } from 'rxjs';
import { AuthorizationService } from 'src/app/core/auth/authorization.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-select-roles',
    templateUrl: './select-roles.component.html',
})
export class SelectRolesComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() userForm: FormGroup;
    @Input() firmType: FirmType;
    @ViewChild(MultiSelectBoxComponent, { static: false }) selectComponent: MultiSelectBoxComponent;

    roles = [];

    private subscription = new Subscription();

    constructor(private authService: AuthorizationService) { }

    ngOnInit() {
        this.roles = this.GetRolesByFirmType(this.firmType);
    }

    ngAfterViewInit(): void {
        this.ApplyRolesRules();

        this.subscription.add(
            this.selectComponent.ngSelectComponent.openEvent.pipe(take(1)).subscribe(
                () => {
                    this.applyIncompatibleRoleRules(this.selectedRoles.map((e: MultiSelectOption) => e.value.value));
                },
            ),
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    get selectedRoles() {
        return this.selectComponent.ngSelectComponent.itemsList.items.filter(e => e.selected === true);
    }

    private GetRolesByFirmType(firmType: FirmType) {
        let allowedRoles: MultiSelectValue[] = [];

        const availableRoles = AVAILABLE_ROLES_BY_FIRM.find(r => r.firmType === firmType);
        if (availableRoles) {
            allowedRoles = USER_ROLES.filter(role => availableRoles.roles.includes(role.value));
        }
        return allowedRoles;
    }

    private ApplyRolesRules() {
        if (this.firmType !== FirmType.Client) {
            this.disableFirmAdminForNonICRAdmin();
            return;
        }

        this.applyIncompatibleRoleRules(this.selectedRoles.map((e: MultiSelectOption) => e.value.value));

        this.subscription.add(this.selectComponent.ngSelectComponent.changeEvent
            .subscribe((selectedRoles: MultiSelectValue[]) => {
                this.applyIncompatibleRoleRules(selectedRoles.map(role => role.value));
            }),
        );
    }

    private applyIncompatibleRoleRules(selectedRoles: string[]) {
        this.selectComponent.ngSelectComponent.itemsList.items.forEach((option: MultiSelectOption) => {
            const applicableRule = INCOMPATIBLE_ROLES
                .find(rule => rule.affectedRoles.includes(option.value.value as Roles));

            if (applicableRule) {
                option.disabled = applicableRule.disabledBy.some(role => selectedRoles.includes(role));
            }

        });
        this.disableFirmAdminForNonICRAdmin();
    }

    private disableFirmAdminForNonICRAdmin() {
        const firmAdminOption = this.selectComponent.ngSelectComponent.itemsList.items
            .find((option: MultiSelectOption) => option.value.value === Roles.FirmAdmin)
        if (firmAdminOption) {
            const isAdmin = this.authService.roles && this.authService.roles.includes(Roles.ICRAdmin);
            if (!isAdmin) {
                firmAdminOption.disabled = true;
            }
        }
    }
}
