import { Subscription, merge } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Directive, OnInit, OnDestroy, Input } from '@angular/core';

interface SortItem {
    prop: string;
    dir: 'asc' | 'desc';
}

@Directive({
    selector: '[pagedDataTable]',
})
export class PagedDataTableDirective implements OnInit, OnDestroy {
    @Input('pagedDataTable') pageSize: number;

    private subscription = new Subscription();

    constructor(
        private datatableComponent: DatatableComponent,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        const datatableComponent = this.datatableComponent;

        datatableComponent.limit = this.pageSize;

        this.subscription.add(
            this.route.queryParams.subscribe((params) => {
                const page: number = +params['page'] || 1;
                const sort: string = params['sort'] || undefined;

                if (this.getPageNumber(datatableComponent) !== page) {
                    this.setPageNumber(datatableComponent, page)
                }

                const newSorts = [];
                if (sort) {
                    const sortParts = sort.split(':');
                    newSorts.push({
                        dir: sortParts.length === 2 && sortParts[1] === 'desc' ? 'desc' : 'asc',
                        prop: sortParts[0],
                    });
                }

                const sortString = this.getSortString(datatableComponent.sorts);
                const sortStringFromUrl = this.getSortString(newSorts);

                if (sortString !== sortStringFromUrl) {
                    datatableComponent.sorts = newSorts;
                }
            }),
        );

        this.subscription.add(
            merge(datatableComponent.page, datatableComponent.sort)
                .subscribe(() => {
                    const pageInfo = {
                        page: this.getPageNumber(datatableComponent),
                        sort: this.getSortString(datatableComponent.sorts),
                    }

                    this.router.navigate([], {
                        queryParams: {
                            page: pageInfo.page > 1 ? pageInfo.page : undefined,
                            sort: pageInfo.sort,
                        },
                        queryParamsHandling: 'merge',
                    });
                }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private getPageNumber(datatableComponent: DatatableComponent) {
        return datatableComponent.offset + 1;
    }

    private setPageNumber(datatableComponent: DatatableComponent, pageNumber: number) {
        datatableComponent.offset = pageNumber - 1;
    }

    private getSortString(sorts: SortItem[]) {
        if (sorts.length) {
            const sortInfo = sorts[0];
            return sortInfo.prop + (sortInfo.dir === 'desc' ? ':desc' : '');
        }

        return undefined;
    }
}
