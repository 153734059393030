import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Model } from 'src/app/models/model.model';
import { ModelCategory } from '../model-category.enum';

import { ModelService } from '../model.service';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { sumQuantity } from '../holdings-quantity.helper';
import { ModelType } from 'src/app/core/model-type';
import { ModelSleeveViewModel } from 'src/app/models/model-sleeve.model';
import { UserService } from 'src/app/admin/user.service';
import { FirmType } from 'src/app/core/firm-type';

@Component({
    selector: 'app-review-model-component',
    templateUrl: './review-model.component.html',
    styleUrls: ['./review-model.component.scss', '../model-pages.layout.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewModelComponent implements OnInit, OnDestroy {
    ModelCategory = ModelCategory;
    ModelType = ModelType;

    modelId: string;
    modelObservable: Observable<Model>;
    modelSleeveModels$: Observable<ModelSleeveViewModel[]>;

    private userFirmType;
    private model: Model;
    private subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private modelService: ModelService,
        private messageBoxService: MessageBoxService,
        private userService: UserService,
    ) {}

    ngOnInit() {
        this.modelId = this.route.snapshot.params['id'];

        this.modelObservable = this.modelService.getModel(this.modelId).pipe(
            tap(model => {
                this.model = model
                if (model.type === ModelType.ModelOfModels) {
                    this.modelSleeveModels$ = this.modelService.getSleeveModelsForModel(model.id).pipe(
                        catchError(() => {
                            this.messageBoxService.error('model-fetch-error');
                            return of(null);
                        }));
                }

            }),
            catchError(() => {
                this.messageBoxService.error('model-fetch-error');
                return of(null);
            }),
        );

        this.subscription.add(
            this.userService.getCurrentUserContext().subscribe(
                (context) => {
                    this.userFirmType = context.firmType;
                },
            ),
        );
    }

    submit() {
        if (!this.validHoldingsQuantity(this.model)) {
            this.messageBoxService.error('model-holdings-quantity-error');
            return;
        }

        this.subscription.add(
            this.modelService.submit(this.modelId).subscribe(
                () => {
                    this.router.navigate(['../models/submitted'], {
                        state: {
                            data: {
                                modelName: this.model.name,
                                modelIsInternal: this.userFirmType === FirmType.Client,
                            },
                        },
                        queryParamsHandling: 'merge',
                    });
                },
                () => this.messageBoxService.error('model-submit-error'),
            ),
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private validHoldingsQuantity(model: Model) {
        return sumQuantity(model.holdings) === 100;
    }
}
