import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { sumQuantity } from '../../holdings-quantity.helper';
import { ModelSleeveViewModel } from 'src/app/models/model-sleeve.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-sleeve-models-review',
    templateUrl: './sleeve-models-review.component.html',
    styleUrls: ['./sleeve-models-review.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SleeveModelsReviewComponent implements OnInit {
    @Input() sleeveModels: Observable<ModelSleeveViewModel[]>;

    totalQuantity: number;

    constructor() {}

    ngOnInit() {
        this.sleeveModels = this.sleeveModels.pipe(tap((sleeves) => {
            this.totalQuantity = sumQuantity(sleeves);
        }));
    }
}
