import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Model } from 'src/app/models/model.model';
import { NgForm, FormBuilder, FormArray, Validators, AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WarnOnLeaveFormComponent } from 'src/app/shared/warn-on-leave/warn-on-leave-form.component';
import { ModelService } from '../../../model.service';
import { MessageBoxService } from 'src/app/shared/messages/message-box.service';
import { Holding } from 'src/app/models/holding.model';
import { EquityDynamicHolding } from 'src/app/models/equity-dynamic-holding.model';
import { FixedIncomeDynamicHolding } from 'src/app/models/fixed-income-dynamic-holding.model';
import { EquityDynamicHoldingFormGroup, FixedIncomeDynamicHoldingFormGroup }
    from 'src/app/model-pages/model-detail/holding-form-group';
import { ModelCategory } from '../../../model-category.enum';
import { MODEL_CATEGORIES } from 'src/app/core/constants';
import { ModelStateManagementService } from '../../model-state-management.service';
import { dynamicHoldingValidator } from 'src/app/model-pages/model-holdings/dynamic-holding.validator';
import { ModelStatus } from 'src/app/core/model-status';
import { Roles } from 'src/app/core/roles';
import { AuthorizationService } from 'src/app/core/auth/authorization.service';
import { HttpErrorResponseHandler } from 'src/app/core/http-error-response.handler';
import { sumQuantity } from 'src/app/model-pages/holdings-quantity.helper';
import { ResetFormService } from 'src/app/shared/guards/form-reset.service';
import { ModelType } from 'src/app/core/model-type';
import { ModelHoldingPosition } from 'src/app/model-pages/model-holding-position-enum';

@Component({
    selector: 'app-model-detail-holdings-edit',
    templateUrl: './model-detail-holdings-edit.component.html',
    styleUrls: ['./model-detail-holdings-edit.component.scss', '../../../model-holdings/column-sizes.edit.scss'],
})
export class ModelDetailHoldingsEditComponent extends WarnOnLeaveFormComponent implements OnInit, OnDestroy {
    @ViewChild('form', { static: false }) form: NgForm;

    userRoles: Roles[];
    modelId: string;
    model: Model;
    holdings: FormArray;
    ModelCategory = ModelCategory;
    ModelType = ModelType;

    private subscription = new Subscription();
    private initialHoldingscount = 0;
    private newRowCreated = false;

    constructor(
        private modelService: ModelService,
        private messageBoxService: MessageBoxService,
        private fb: FormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private stateManagementService: ModelStateManagementService,
        private authorizationService: AuthorizationService,
        private httpErrorResponseHandler: HttpErrorResponseHandler,
        private resetFormService: ResetFormService,
    ) {
        super();
    }

    ngOnInit() {
        this.stateManagementService.turnOnEditMode();
        this.modelId = this.activatedRoute.parent.snapshot.params['id'];
        this.holdings = this.fb.array([]);

        this.userRoles = this.authorizationService.roles;

        this.subscription.add(
            this.modelService.getModel(this.modelId).subscribe(
                (model: Model) => {
                    this.model = model;

                    if (model.type === ModelType.Holdings) {
                        this.initialHoldingscount = this.model.holdings.length;
                        this.initializeHoldings();
                        this.newRowCreated = true;
                    }
                },
                (error: HttpErrorResponse) =>
                    this.httpErrorResponseHandler.handleError(error, {
                        notFoundErrorMessageId: 'model-not-found',
                        genericErrorMessageId: 'model-details-fetch-failed',
                    }),
            ),
        );

        this.canEditWholeRow = this.canEditWholeRow.bind(this);
        this.addNewHolding = this.addNewHolding.bind(this);
        this.removeHolding = this.removeHolding.bind(this);

        this.subscription.add(
            this.resetFormService.shouldResetSelfObservable.subscribe(
                () => this.holdings.reset(),
            ),
        );
    }

    canEditWholeRow(index: number) {
        if (this.model.status === ModelStatus.PendingApproval
            && this.userRoles.some(r => r === Roles.ICRAdmin)) {
            return true;
        }

        if (index > this.initialHoldingscount - 1) {
            return true
        }

        return false;
    }

    saveHoldingsEdits() {
        const nonEmptyHoldings = this.nonEmptyHoldings;

        if (!this.validateModelHoldings(nonEmptyHoldings)) {
            return;
        }

        const holdings = nonEmptyHoldings.map((holdingControl: FormGroup) => {
            let category: ModelCategory = null;

            if (holdingControl instanceof EquityDynamicHoldingFormGroup) {
                category = ModelCategory.Equity;
            } else if (holdingControl instanceof FixedIncomeDynamicHoldingFormGroup) {
                category = ModelCategory.FixedIncome;
            }

            return {
                ...holdingControl.value,
                category,
            }
        });

        const model = {
            modelId: this.model.id,
            holdings,
        };

        this.subscription.add(
            this.modelService.updateModelHoldings(model).subscribe(
                () => {
                    // need to mark to prevent "warn on leave" modal
                    this.holdings.markAsPristine();
                    this.router.navigate(['../'], { relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
                    this.messageBoxService.success('model-details-save-success');
                },
                () => this.messageBoxService.error('model-details-save-failed'),
            ),
        );
    }

    addNewHolding(modelCategory: ModelCategory) {
        this.holdings.push(this.getHoldingForm(modelCategory));
    }

    removeHolding(i: number) {
        this.holdings.markAsDirty();
        this.holdings.removeAt(i);
    }

    getHoldingForm(modelCategory: ModelCategory, holding?: Holding) {
        const category = holding && holding.category || modelCategory;

        switch (category) {
            case ModelCategory.Equity: {
                return this.getEquityHoldingForm(holding as EquityDynamicHolding);
            }

            case ModelCategory.FixedIncome: {
                return this.getFixedIncomeHoldingForm(holding as FixedIncomeDynamicHolding);
            }

            default: {
                return null;
            }
        }
    }

    get modelCategoryLabel() {
        const category = MODEL_CATEGORIES.find(x => x.value === this.model.category)
        if (category) {
            return category.label;
        }

        return '';
    }

    get totalQuantity() {
        return sumQuantity(this.holdings.value);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private initializeHoldings() {
        this.model.holdings.forEach(holding => this.holdings.push(this.getHoldingForm(this.model.category, holding)));

        for (let i = this.holdings.length; i < this.initialHoldingscount; i++) {
            this.holdings.push(this.getHoldingForm(this.model.category))
        }
    }

    private getFixedIncomeHoldingForm(holding?: FixedIncomeDynamicHolding): AbstractControl {
        const {
            id,
            issuer,
            class: incomeClass,
            ticker,
            cusip,
            quantity,
            positionStatus,
            remarks,
        } = holding || {
            id: null, issuer: '', class: null, ticker: '', cusip: '',
            quantity: null, positionStatus: ModelHoldingPosition.Buy, remarks: '',
        } as FixedIncomeDynamicHolding;

        return new FixedIncomeDynamicHoldingFormGroup(
            {
                id: new FormControl(id),
                issuer: new FormControl(issuer, Validators.maxLength(100)),
                class: new FormControl(incomeClass),
                ticker: new FormControl(ticker, Validators.maxLength(10)),
                cusip: new FormControl(cusip, Validators.minLength(9)),
                quantity: new FormControl(quantity, [Validators.max(100), Validators.min(this.qtyMinValue)]),
                positionStatus: new FormControl(positionStatus),
                remarks: new FormControl(remarks),
            },
            {
                updateOn: 'change',
                validators: dynamicHoldingValidator,
            },
        );
    }

    private getEquityHoldingForm(holding?: EquityDynamicHolding): AbstractControl {
        const {
            id,
            name,
            ticker,
            cusip,
            quantity,
            positionStatus,
            remarks,
        } = holding || {
            id: null, name: '', ticker: '',
            cusip: '', quantity: null, positionStatus: ModelHoldingPosition.Buy,
            remarks: '',
        } as EquityDynamicHolding;

        return new EquityDynamicHoldingFormGroup(
            {
                id: new FormControl(id),
                name: new FormControl(name, Validators.maxLength(100)),
                ticker: new FormControl(ticker, Validators.maxLength(10)),
                cusip: new FormControl(cusip, Validators.minLength(9)),
                quantity: new FormControl(quantity, [Validators.max(100), Validators.min(this.qtyMinValue)]),
                positionStatus: new FormControl(positionStatus),
                remarks: new FormControl(remarks),
            },
            {
                updateOn: 'change',
                validators: dynamicHoldingValidator,
            },
        );
    }

    private get qtyMinValue() {
        if (this.newRowCreated) {
            return 0.0001;
        }
        return 0;
    }

    private get nonEmptyHoldings() {
        return this.holdings.controls.filter(holding =>
            !holding.pristine
            || !holding.valid
            || holding.value && holding.value.id);
    }

    private validateModelHoldings(nonEmptyHoldings: AbstractControl[]): boolean {
        nonEmptyHoldings.forEach(holding => holding.markAllAsTouched());

        if (this.totalQuantity !== 100) {
            this.messageBoxService.error('holding-sum-invalid');
            return false;
        }

        if (nonEmptyHoldings.some(holding => !holding.valid)) {
            return false;
        }

        return true;
    }
}
