import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-filter-label',
    templateUrl: './filter-label.component.html',
    styleUrls: ['./filter-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterLabelComponent {
    @Input() value: string;
    @Output() clear = new EventEmitter();

    constructor() {}

    removeFilter() {
        this.clear.emit();
    }
}
