import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';

import { ModelListFilterService } from '../../model-list.filter.service';
import { ModelCategory } from '../../../model-category.enum';
import { ModelRegion } from 'src/app/core/model-region';
import { ModelStyleClass } from 'src/app/core/model-style-class';

@Component({
    selector: 'app-model-filter-asset-class',
    templateUrl: './model-filter.asset-class.component.html',
    styleUrls: ['./model-filter.asset-class.component.scss'],
})
export class ModelFilterAssetClassComponent implements OnInit, OnDestroy {
    @ViewChild('regionDropdown', { static: false }) regionDropdown: NgSelectComponent;

    selectedEquity = false;
    selectedFixedIncome = false;
    selectedOther = false;
    region: number = undefined;
    regions = undefined;
    regionValue: ModelRegion = undefined;
    assetClassValue: ModelCategory = undefined;
    styleClassValues: ModelStyleClass[] = undefined;
    private availableRegions = [];
    private subscription = new Subscription();
    private regionDeselect = [{ value: undefined, label: 'Select' }];

    constructor(private modelListFilterService: ModelListFilterService) {}

    ngOnInit() {
        this.subscription.add(
            this.modelListFilterService.filtersObservable.subscribe(filters => {
                this.selectedEquity = filters.assetClassValue === ModelCategory.Equity;
                this.selectedFixedIncome = filters.assetClassValue === ModelCategory.FixedIncome;
                this.selectedOther = filters.assetClassValue === ModelCategory.Other;
                this.region = filters.region ? filters.region : undefined;
                this.regionValue = filters.regionValue;
                this.assetClassValue = filters.assetClassValue;
                this.styleClassValues = filters.styleClassValues;

                this.availableRegions = filters.availableRegions;
                this.setAvailableRegions();
            }),
        );
    }

    toggleEquity() {
        this.modelListFilterService.filterByAssetClassEquity(this.selectedEquity);
    }

    toggleFixedIncome() {
        this.modelListFilterService.filterByAssetClassFixedIncome(this.selectedFixedIncome);
    }

    toggleOther() {
        this.modelListFilterService.filterByAssetClassOther(this.selectedOther);
    }

    handleRegionClick(event: Event) {
        // Prevent propagation of click event on region dropdown, so that it does not
        // trigger click-outside event for Asset Class dropdown (parent of Region dropdown)
        event.stopPropagation();
    }

    handleRegionChange(model) {
        this.region = model.value;
        this.modelListFilterService.filterByRegion(this.region ? this.region.toString() : undefined);
        // Close region dropdown manually on selection change
        // This is needed due to disabling of click event propagation
        if (this.regionDropdown) {
            this.regionDropdown.close();
        }
    }

    private setAvailableRegions() {
        if (!this.availableRegions || this.availableRegions.length === 0) {
            this.regions = undefined;
            return;
        }

        this.regions = this.region ? this.regionDeselect.concat(this.availableRegions) : this.availableRegions;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
