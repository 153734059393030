import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ResearchUploadModalService {
    private components: ResearchUploadModal[] = [];

    private uploadStatus = new Subject<boolean>();
    uploadStatusObservable = this.uploadStatus.asObservable();

    public fileUploadSuccessful () {
        this.uploadStatus.next(true);
    }

    public fileUploadDeclined () {
        this.uploadStatus.next(false);
    }

    public add(component: ResearchUploadModal) {
        this.components.push(component);
    }

    public remove(component: ResearchUploadModal) {
        this.components = this.components.filter(x => x.modelId !== component.modelId);
    }

    public showModal(id: string, researchCategories: { label: string; value: string }[]) {
        const component = this.components.find(x => x.modelId === id);

        if (component) {
            return component.showUploadResearchModal(researchCategories);
        }

        return of(null);
    }
}

export interface ResearchUploadModal {
    modelId: string;
    showUploadResearchModal(researchCategories: { label: string; value: string }[]): void;
}
