import { Component, OnInit, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { FirmService } from '../firm.service';
import { MessageBoxService } from '../../shared/messages/message-box.service';
import { WarnOnLeaveFormComponent } from 'src/app/shared/warn-on-leave/warn-on-leave-form.component';
import { Firm } from 'src/app/models/firm.model';
import { Router, ActivatedRoute } from '@angular/router';
import { isEmptyOrWhitespace } from 'src/app/shared/validators/icr.validators';
import { Subscription } from 'rxjs';
import { ResetFormService } from 'src/app/shared/guards/form-reset.service';
import { FirmFormNotificationService } from '../firm-form/firm-form-notification.service';

@Component({
    selector: 'app-create-firm',
    templateUrl: './create-firm.component.html',
    styleUrls: ['./create-firm.component.scss', '../admin.layout.scss'],
})
export class CreateFirmComponent extends WarnOnLeaveFormComponent implements OnInit, OnDestroy {
    @ViewChild('form', { static: false }) form: NgForm;

    firmForm: FormGroup;

    private subscription = new Subscription();

    constructor(
        private fb: FormBuilder,
        private firmService: FirmService,
        private messageBoxService: MessageBoxService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private ngZone: NgZone,
        private resetFormService: ResetFormService,
        private firmFormService: FirmFormNotificationService,
    ) {
        super();
    }

    ngOnInit() {
        this.firmForm = this.fb.group({
            firmInformation: [{  }],
        });

        this.subscription.add(
            this.resetFormService.shouldResetSelfObservable.subscribe(
                () => this.firmForm.markAsPristine(),
            ),
        );
    }

    onSubmit() {
        this.firmForm.markAllAsTouched();
        this.firmFormService.notifyOnFormSubmission();

        if (this.firmForm.valid && this.firmForm.dirty) {
            this.subscription.add(
                this.firmService.createFirm(this.removeEmptyContacts(this.firmForm.value.firmInformation))
                    .subscribe(
                        () => {
                            this.firmForm.markAsPristine();
                            this.ngZone.run(() => this.router.navigate(['../'], { relativeTo: this.activatedRoute }));
                            this.messageBoxService.success('admin-create-success');
                        },
                        () => {
                            this.messageBoxService.error('create-firm-error');
                        },
                    ));
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private removeEmptyContacts(firm: Firm): Firm {
        if (firm && firm.contacts && firm.contacts.length) {
            return {
                ...firm,
                contacts: firm.contacts.filter(contact => {
                    return Object.keys(contact)
                        .filter(x => x !== 'phoneCountryCode')
                        .some(field => !isEmptyOrWhitespace(contact[field]));
                }),
            };
        }
        return firm;
    }
}
