import { Injectable } from '@angular/core';
import { WarnOnLeaveComponent } from '../warn-on-leave/warn-on-leave.component';
import { CanDeactivate } from '@angular/router';
import { ConfirmDialogService } from '../messages/confirm-dialog/confirm-dialog.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class WarnOnLeaveGuard implements CanDeactivate<WarnOnLeaveComponent> {
    constructor(private confirmService: ConfirmDialogService) {}

    canDeactivate(component: WarnOnLeaveComponent): Observable<boolean> {
        if (component.shouldWarn()) {
            return this.confirmService.confirm('confirm-stay-on-page')
                .pipe(map((stayConfirmed => !stayConfirmed)));
        }
        return of(true);
    }
}
